.market-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar{
        height: 0px;
    }

    &--header {
        position: relative;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
    }

    &--title {
        text-align: center;
        font-weight: bold;
        background: #0a4a4a;
        font-size: 15px;
    }

    &--search-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0;
        width: 33px;
        font-size: 13px;
        background: none;
        color: #afddde;
        opacity: 0.7;

        &:hover, &.active {
            opacity: 1.0;
        }

        >svg {
            margin-top: 5px;
        }
    }

    &--search-input {
        position: relative;
        display: block;
        width: calc(100% - 18px);
        padding: 0px 9px;
        background: #053a39;
        height: 29px;
        min-height: 29px;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--tabs {
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
        height: 29px;
        min-height: 29px;

        &--button {
            height: 100%;
            white-space: nowrap;
            background: #012e2e;
            font-size: 12px;
            color: #eee;
            font-weight: bold;
            border-left: 1px solid #267f80;
            padding: 0px 11px;
            width: 33.333%;

            &:hover {
                color: white;
            }

            &.active {
                background: #267f80;
                color: white;
            }

            &:first-child {
                border-left: none;
            }
        }
    }

    &--body {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.basis-container {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;

    &--quote-types {
        position: sticky;
        top: 0px;
        z-index: 1;
        background: #003c3b;
        padding: 7px 5px;
        border-bottom: 1px solid #002e2d;

        &--buttons {
            position: relative;
            border: 1px solid #82b8b9;
            border-radius: 2px;
        }

        &--button {
            position: relative;
            float: left;
            height: 21px;
            padding: 0px 0px;
            width: 50%;
            background: none;
            color: #ddd;
            font-size: 12px;
            font-weight: bold;

            &:hover {
                color: white;
            }

            &.active {
                background: #82b7b9;
                color: #204244;
            }

            &:nth-child(n+2) {
                border-left: 1px solid #82b8b9;
            }
        }
    }

    &--body {
        position: absolute;
        top: 38px;
        bottom: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.basis-list {
    position: relative;

    &--item {
        position: relative;
        padding: 7px 9px;
        border-bottom: 1px solid #012b29;

        &--header {
            line-height: 21px;
        }

        &--instrument-name {
            font-size: 16px;
            font-weight: bold;
            color: white;
            float: left;
        }

        &--timestamp {
            float: right;
            font-size: 12px;
            color: #ddd;
        }

        &--price-row {
            display: flex;
            line-height: 17px;
            white-space: nowrap;
            overflow: hidden;
            padding: 7px 0px;

            &--name {
                font-size: 12px;
                padding: 0px 4px;
                background: #267f80;
                border-radius: 1px;
                color: #ddd;
                font-weight: bold;

                &.OKEX {
                    background: #5d8cb5;
                    color: #214869;
                }

                &.BitMEX {
                    background: #b98fd4;
                    color: #45245a;
                }
            }

            &--value {
                font-size: 14px;
                margin-left: 9px;
                color: #eee;
                font-weight: bold;

                >span {
                    font-size: 13px;
                    color: #d0e6e6;
                    font-weight: normal;
                }
            }
        }

        &--derivative-table {
            font-size: 13px;
            white-space: nowrap;
            width: 100%;
            position: relative;
            border-collapse: collapse;
            text-align: center;
            font-weight: bold;
            font-weight: bold;

            thead {
                background: #012b2a;
                color: #bddedd;
                font-size: 12px;
                font-weight: normal;

                td {
                    padding: 3px 0px;
                }
            }

            tbody {
                td {
                    padding: 3px 0px;

                    &.positive-number {
                        color: #90eaea;
                    } 

                    &.negative-number {
                        color: #fd818a;
                    }

                    &:first-child {
                        text-align: left;
                        color: #d0e6e6;
                        font-size: 12px;
                        font-weight: normal;
                    }
                }
            }

            &--exchange-name {
                &.BitMEX {
                    color: #c586c5; 
                }
            }
        }
    }
}

.future-spread-table {
    &--title {
        font-size: 14px;
        font-weight: bold;
        line-height: 27px;
        text-indent: 5px;
        color: #aad7d8;
    }

    &--main {
        position: relative;
        width: 100%;
        text-align: center;
        border-collapse: collapse;

        thead {
            background: #012b2a;
            color: #bddedd;
            font-size: 12px;
            font-weight: normal;

            td {
                padding: 3px 2px;
            }
        }

        tbody {
            font-size: 13px;
            font-weight: bold;

            tr {
                border-bottom: 1px solid #012d2d;

                &.no-border {
                    border: none;
                }
            }
            td {
                padding: 5px 0px;
                white-space: nowrap;

                &:first-child {
                    color: #d0e6e6;
                    font-size: 12px;
                }
            }
        }
    }

    &--data {
        white-space: nowrap;
        >div {
            line-height: 17px;

            &.positive-number {
                color: #90eaea;
            } 

            &.negative-number {
                color: #fd818a;
            }

            &:nth-child(2) {
                color: #ddd;
            }
        }
    }
}

.future-spread-container {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;

    &--formats {
        position: sticky;
        top: 0px;
        z-index: 1;
        background: #003c3b;
        padding: 7px 5px;
        border-bottom: 1px solid #002e2d;

        &--buttons {
            position: relative;
            border: 1px solid #82b8b9;
            border-radius: 2px;
        }

        &--button {
            position: relative;
            float: left;
            height: 21px;
            padding: 0px 0px;
            width: 33.333%;
            background: none;
            color: #ddd;
            font-size: 12px;
            font-weight: bold;

            &:hover {
                color: white;
            }

            &.active {
                background: #82b7b9;
                color: #204244;
            }

            &:nth-child(n+2) {
                border-left: 1px solid #82b8b9;
            }
        }
    }

    &--body {
        position: absolute;
        top: 38px;
        bottom: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--item {
        position: relative;
        margin-top: 7px;

        &--name {
            font-size: 15px;
            font-weight: bold;
            text-indent: 5px;
        }

        &--last-updated-time {
            position: absolute;
            font-size: 12px;
            top: 2px;
            right: 5px;
            color: #ddd;
        }
    }

    &--table {
        position: relative;
        margin: 7px 5px;
    }
}

.swaps {

    &--item {
        position: relative;
        margin-top: 11px;

        &--name {
            font-size: 15px;
            font-weight: bold;
            margin-left: 5px;
        }

        &--last-updated-time {
            position: absolute;
            font-size: 12px;
            top: 2px;
            right: 5px;
            color: #ddd;
        }

        &--table {
            position: relative;
            width: calc(100% - 10px);
            text-align: center;
            border-collapse: collapse;
            margin: 7px 5px 0px;

            thead {
                background: #012b2a;
                color: #bddedd;
                font-size: 12px;
                font-weight: normal;

                td {
                    padding: 3px 2px;
                }
            }

            tbody {
                font-size: 13px;
                font-weight: bold;

                td {
                    padding: 5px 0px;
                    white-space: nowrap;
                    color: #eee;

                    &.positive-number {
                        color: #90eaea;
                    } 

                    &.negative-number {
                        color: #fd818a;
                    }

                    &:first-child {
                        font-size: 12px;
                        color: #d0e6e6;
                    }
                }
            }
        }
    }
}