.content-index {
    background: #022e2ff5;
    box-shadow: 0px 0px 3px #000202;
    border-radius: 2px;
    display: none;
    padding: 7px 9px;

    &--title {
        font-size: 15px;
        font-weight: bold;
        color: #a9d7da;
    }

    &--body {
        position: relative;
        margin-top: 7px;
        border-left: 1px solid #87adaf;
    }

    &--content {
        padding: 2px 7px;
        font-size: 13px;
        border-left: 2px solid transparent;
        color: #ddd;
        transform: translateX(-1px);
        cursor: pointer;
        
        &:hover {
            font-weight: bold;
        }

        &.active {
            font-weight: bold;
            color: white;
            border-left: 2px solid white;
        }
    }
}