.checkbox {
    position: relative;
    width: 25px;
    height: 25px;
    background: #3f7575;
    border-radius: 2px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
    }

    &:hover {
        box-shadow: inset 0px 0px 3px #032f2f;
    }

    >svg {
        color: #eee;
    }
}