.save-button {
    display: block;
    font-weight: bold;
    background: #5d8cb5;
    color: #ddd;

    &:hover {
        color: white;
    }

    &:disabled {
        cursor: not-allowed;
        color: #ddd !important;
    }

    &.is-saved {
        background: #359898;
    }

    >svg {
        margin-right: 3px;
        transform: translateY(2px) scale(0.9);
    }
}