.support-request-editor {
    font-weight: bold;
    &--main {
        >textarea {
            width: 320px;
            margin-top: 10px;
            background: #0a5252;
            color: white;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
            resize: none;
            cursor: text;
            padding: 10px 15px;
            border-radius: 3px;
        }
        >button {
            width: 200px;
            border-radius: 3px;
            height: 34px;
            font-size: 14px;
            font-weight: bold;
            margin-top: 10px;
            background: #5d8cb5;
            color: #071d2f;

            &:hover {
                background: #82aace;
                color: white;
            }
        }
    }

    &--sent-message {
        font-size: 14px;
        margin-top: 10px;
        text-align: left;
        line-height: 20px;
    }
}

.alert-management-popup {
    background: #012b2bdb;
    margin-left: 0px;
    box-shadow: 0px 0px 3px black;
    border-radius: 3px;
    overflow: hidden;

    &--trigger {
        position: relative;
        font-size: 25px;
        background: none;
        color: #92c9ca;
        opacity: 0.8;
        padding: 0px;
        height: 30px;

        &:hover {
            opacity: 1;
        }
    }

    &--section {
        position: relative;
        padding-bottom: 10px;

        &--title {
            font-size: 16px;
            font-weight: bold;
            line-height: 31px;
            border-bottom: 1px solid #86a3a4;
            text-indent: 15px;
            color: white;
        }

        &--main {
            position: relative;
            padding: 5px 15px;
        }
    }

    &--portfolio-table {
        table {
            position: relative;
            border-collapse: collapse;
            font-weight: bold;
            line-height: 24px;

            th {
                font-size: 14px;
                color: #acc5c5;
                text-align: left;
                &:not(:last-child) {
                    padding-right: 10px;
                }
            }

            td {
                font-size: 14px;
                &:not(:last-child) {
                    padding-right: 10px;
                }
            }
        }
    }

    &--is-fetching-details {
        float: right;
        padding-right: 15px;
        font-size: 14px;
        color: #acc5c5;
    }

    &--duration-input {
        color: white;
        cursor: text;
        width: 100%;
        font-size: 14px;
        background: #095252;
        font-weight: bold;
        text-indent: 6px;
        height: 24px;
        border: none;
        border-radius: 3px;
        width: 85px;
    }

    &--mute-button {
        height: 24px;
        border: none;
        font-size: 14px;
        font-weight: bold;
        border-radius: 2px;
        width: 85px;
        color: #651b1b;
        background: #d8b9b9;

        &:hover {
            background: #c9626a;
            color: white;
        }

        &:disabled {
            background: #ddd !important;
            color: lightslategray !important;
        }
    }

    &--notify-traders-button {
        width: 200px;
        border-radius: 3px;
        height: 34px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
        background: #e1b23b;
        color: #342602;

        &:hover {
            background: #e5bd59;
            color: white;
        }

        >svg {
            margin-right: 8px;
            transform: scale(1.4) translateY(1.5px);
        }
    }

    &--notify-traders-message {
        font-size: 14px;
        font-weight: bold;
        margin-top: 10px;
    }

    &--state {
        display: flex;
        align-items: center;

        >span {
            width: 9px;
            height: 9px;
            background: #67b7b7;
            border-radius: 50px;
            margin-right: 6px;
            display: block;
            box-shadow: 0px 0px 10px black;

            &.unkown {
                background: #dfc278;
            }

            &.muted {
                background: #dd858c;
            }
        }
    }
}