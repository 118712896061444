.currency-pair-chart-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
    }

    &--title {
        text-align: center;
        font-weight: bold;
        background: #0a4a4a;
        font-size: 15px;
    }

    &--list {

        &--empty-message {
            font-size: 13px;
            text-align: center;
            font-weight: bold;
            padding: 3px 0px 9px;
            color: #afc5c5;
        }

        &--header {
            &--title {
                background: #021f1d;
                font-size: 12px;
                font-weight: bold;
                padding: 0px 9px;
                color: #92c8ca;
                line-height: 22px;          
            }

            &--add-chart-button {
                position: absolute;
                right: 0px;
                top: 0;
                height: 22px;
                width: 22px;
                padding: 0;
                font-size: 14px;
                background: #083834;
                color: #92c8ca;

                &:hover {
                    color: white;
                }

                >svg {
                    margin-top: 3px;
                }
            }
        }
    }

    &--color-group {
        position: relative;
        display: inline-flex;
        width: calc(100% - 18px);
        white-space: nowrap;
        margin: 9px 9px 3px;
        font-weight: bold;
        border-radius: 2px;
        height: 24px;
        overflow: hidden;

        &--button {
            width: 50%;
            background: #002e2d;
            color: #ddd;
            font-weight: bold;
            font-size: 12px;

            &.active {
                background: #267f80;
                color: white;
            }

            &:hover {
                color: white;
            }
        }
    }

    &--search-input {
        position: relative;
        display: block;
        width: calc(100% - 18px);
        padding: 0px 9px;
        background: #032f2e;
        height: 29px;
        min-height: 29px;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--show-modal-button {
        position: absolute;
        right: 3px;
        top: 0;
        height: 100%;
        padding: 0;
        width: 33px;
        font-size: 18px;
        background: none;
        color: #afddde;
        opacity: 0.7;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            margin-top: 5px;
        }
    }

    &--y-axis-types {
        position: relative;
        display: flex;
        width: 100%;
        height: 29px;
        min-height: 29px;

        &--button {
            position: relative;
            width: 100%;
            padding: 0;
            background: #012e2e;
            font-size: 12px;
            font-weight: bold;
            color: #eee;
            border-left: 1px solid #267f80;

            &.active {
                background: #267f80;
                color: white;
            }

            &:hover {
                color: white;
            }

            &:first-child {
                border-left: none;
            }
        }
    }

    &--main {
        position: relative;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &--item {
        position: relative;
        border-bottom: 1px solid #001514;
        padding: 7px 0px;
        z-index: 99;
        background: #003c3b;

        &:hover {
            .currency-pair-chart-container--item--fullscreen-button {
                visibility: visible;
            }
        }

        &--fullscreen-button {
            position: absolute;
            right: 9px;
            background: none;
            color: #57a8a9;
            opacity: 0.7;
            padding: 0;
            font-size: 14px;
            top: 10px;
            z-index: 9;
            visibility: hidden;

            &:hover {
                opacity: 1.0;
            }

            &.shift-left {
                right: 29px;
            }
        }
    }

    &--modal {
        position: absolute;
        width: 100%;
        height: 100%;

        .currency-pair-chart-container {

            &--item {
                width: calc(25% - 21px);
                display: inline-block;
                padding: 13px 10px 10px;
                border-right: 1px solid #001514;
                background: #002c2a;

                &--fullscreen-button {
                    top: 15px;
                    right: 21px;

                    &.shift-left {
                        right: 40px;
                    }
                }
            }

            &--list {
                position: relative;
                margin-top: 11px;
    
                &--empty-message {
                    text-align: left;
                    text-indent: 17px;
                    padding-top: 7px;
                }
    
                &--header {
                    position: relative;
                    border-bottom: 1px solid #76a8a9;
                    line-height: 27px;
                    padding-bottom: 7px;
    
                    &--title {
                        background: none;
                        float: left;
                        font-size: 13px;
                        text-indent: 8px;
                        line-height: 27px;
                    }
    
                    &--add-chart-button {
                        float: left;
                        width: 27px;
                        height: 27px;
                        position: relative;
                        background: #0c4845;
                        border-radius: 2px;
                        font-size: 17px;
    
                        >svg {
                            margin-top: 5px;
                        }
                    }
                }
    
                &.customizable {
                    .currency-pair-chart--header {
                        position: absolute;
                        opacity: 0.7;
                        top: 0px;
                        left: 40px;
                        color: #d8f7f6;
    
                        &--title-item {
                            &--pair-name {
                                font-size: 13px;
                            } 
                        }
                    }
                }
            }

            &--color-group {
                float: left;
                width: 250px;
                margin: 0px 5px;
                height: 27px;
    
                &--button {
                    background: #0c4846;
    
                    &.active {
                        background: #267f80;
                    }
                }
            }

            &--search-input {
                width: 110px;
                background: #104847;
                padding: 0px 9px;
                border-radius: 2px;
                min-height: auto;
                height: 27px;
                line-height: 27px;
                float: left;
                margin-left: 7px;
            }

            &--y-axis-types {
                float: left;
                width: auto;
                margin-left: 27px;
                top: 6px;
                height: 27px;
                min-height: 27px;
                border-radius: 2px;
                overflow: hidden;

                &--button {
                    background: #0e4846;
                    width: 85px;

                    &.active {
                        background: #267f80;
                    }
                }
            }
        }

        .customizable-currency-pair-chart {
            &--header {
                max-width: 285px;
                right: 0px;
                background: #001b1ad1;
                box-shadow: -2px 2px 7px #041918;
            }
        }

        .currency-pair-chart {
            &--header {
                margin-top: 5px;
                &--title-item {
                    text-indent: 15px;
        
                    &--pair-name {
                        font-size: 17px;
                    }
        
                    &--index-name {
                        font-size: 13px;
                        margin-left: 7px;
                    }
                }
            }

            &--tooltip {
                top: 15px;

                &.left {
                    left: 55px;
                }

                &.right {
                    right: 19px;
                }

                &--category {
                    font-size: 13px;
                }
        
                &--dot, &--exchange, &--value {
                    font-size: 12px;
                }

                &--dot {
                    width: 7px;
                    height: 7px;
                }
            }
        }
    }

    &--modal-title {
        font-size: 17px;
        line-height: 39px;
        font-weight: bold;
        text-indent: 17px;
        float: left;
    }

    &--modal-header {
        position: absolute;
        height: 39px;
        width: 100%;
        background: #002c2b;
        border-bottom: 2px solid #76a8a9;
    }

    &--modal-main {
        position: absolute;
        width: 100%;
        top: 41px;
        bottom: 0px;
        overflow-y: auto;
        background: #002c2b;
    }

    &--close-modal-button {
        position: absolute;
        top: 0;
        height: 100%;
        right: 16px;
        font-size: 19px;
        background: none;
        color: #ddd;

        >svg {
            margin-top: 6px;
        }

        &:hover {
            color: white;
        }
    }

    &--fullscreen-chart {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        min-width: 800px;
        top: 0;
        left: 0;

        &--header {
            position: relative;
            height: 39px;
            min-height: 39px;
            width: 100%;
            background: #001d1c;
            z-index: 2;

            &--title {
                font-size: 17px;
                line-height: 39px;
                font-weight: 700;
                text-indent: 17px;
                float: left;
            }

            .currency-pair-chart-container {

                &--y-axis-types, &--color-group {
                    float: left;
                    width: auto;
                    margin: 0px;
                    top: 6px;
                    height: 27px;
                    min-height: 27px;
                    border-radius: 2px;
                    overflow: hidden;

                    &--button {
                        background: #0e4846;
    
                        &.active {
                            background: #267f80;
                        }
                    }
                }

                &--y-axis-types {
                    margin-left: 27px;

                    &--button {
                        width: 85px;
                    }
                }

                &--color-group {
                    margin-left: 6px;

                    &--button {
                        width: 120px;
                    }
                }
                
            }
        }

        &--wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            background: #033f40;
            box-shadow: 0px 0px 3px black;

            .currency-pair-chart {
                position: absolute;
                width: 100%;
                height: 100%;

                &--header {
                    position: absolute;
                    left: 65px;
                    top: 11px;
                    color: white;

                    &--title-item {
                        text-indent: 0px;
                        margin-top: 3px;

                        &--pair-name {
                            font-size: 17px;
                        } 
                        &--index-name {
                            font-size: 13px;
                            margin-left: 7px;
                        }
                    }
                }

                &--tooltip {
                    top: 30px;
                    &.left {
                        left: 65px;
                    }
                }

                &--canvas-wrapper {
                    position: absolute;
                    left: 12px;
                    right: 12px;
                    top: 66px;
                    bottom: 15px;
                }
            }

            .customizable-currency-pair-chart {
                position: absolute;
                width: 100%;
                height: 100%;

                &--header {
                    max-width: 285px;
                    right: 15px;
                    top: 10px;
                    background: #001b1ad1;
                    box-shadow: -2px 2px 7px #041918;
                }

                &--main {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

                &--setting-icon {
                    top: 5px;
                    font-size: 16px;
                }

                .currency-pair-chart {
                    &--header {
                        position: absolute;
                        left: 65px;
                        top: 11px;
                        color: #d8f7f6;
    
                        &--title-item {
                            opacity: 0.7;
                            &--pair-name {
                                font-size: 14px;
                            } 
                        }
                    }
                }
            }
        }

        &--close-button {
            position: absolute;
            top: 0;
            height: 100%;
            right: 16px;
            font-size: 19px;
            background: none;
            color: #ddd;

            &:hover {
                color: white;
            }

            >svg {
                margin-top: 6px;
            }
        }
    }
}

.currency-pair-chart {

    &--header {
        position: relative;

        &--title-item {
            white-space: nowrap;
            text-indent: 9px;
            line-height: 17px;

            &--pair-name {
                font-size: 13px;
                font-weight: bold;
            }

            &--index-name {
                font-size: 12px;
                margin-left: 7px;
            }
        }
    }

    &--exchange-filters {
        position: relative;
        margin-right: 60px;

        &--button {
            position: relative;
            float: left;
            margin-right: 5px;
            margin-bottom: 5px;
            font-size: 13px;
            background: transparent;
            color: #a5cccb;
            font-weight: bold;
            padding: 2px 13px;
            border: 1px solid #a6cccc;
            border-radius: 2px;

            &.is-filtered {
                background: #267f80;
                color: #eee;
            }

            &:hover {
                color: white;
            }
        }
    }

    &--canvas-wrapper {
        position: relative;
    }

    &--tooltip {
        position: absolute;
        display: inline-table;
        top: 12px;
        background: #03161794;
        box-shadow: 0px 0px 3px black;
        border-radius: 1px;
        padding: 0px 5px;
        z-index: 2;

        &.left {
            left: 47px;
        }

        &.right {
            right: 10px;
        }

        &--category {
            font-size: 12px;
            font-weight: bold;
        }

        &--data-table {
            display: table;
        }

        &--data {
            display: table-row;
        }

        &--dot, &--exchange, &--value {
            font-size: 12px;
            display: table-cell;
            padding: 0px 4px;
        }

        &--dot {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            display: block;
            padding: 0;
        }

        &--value {
            font-weight: bold;
        }
    }
}

.customizable-currency-pair-chart {
    position: relative;

    &--header {
        position: absolute;
        z-index: 10;
        width: 100%;
        background: #002e2ccc;
        box-shadow: 0px 1px 1px #001b1a;
    }

    &--setting-icon {
        position: absolute;
        right: 9px;
        top: 3px;
        font-size: 14px;
        background: none;
        color: #57a8a9;
        opacity: 0.7;
        padding: 0;

        &:hover {
            opacity: 1.0;
        }
    }

    &--editor {
        &--title {
            font-size: 13px;
            border-bottom: 1px solid #65a2a5;
            padding: 4px 9px 2px;
            font-weight: bold;
            color: #bfd9da;
        }

        &--pairs {
            margin: 0px 0px;
            position: relative;
            padding: 9px;

            &--search {
                z-index: 10;
                position: relative;
                float: left;

                &--input {
                    font-size: 12px;
                    background: transparent;
                    color: white;
                    font-weight: bold;
                    cursor: text;
                }

                &--results {
                    position: absolute;
                    background: #0c504f;
                    font-size: 12px;
                    font-weight: bold;
                    margin-top: 4px;
                    box-shadow: 0px 0px 3px black;
                    border-radius: 1px;
                    max-height: 191px;
                    overflow: auto;
                }

                &--result-item {
                    border-bottom: 1px solid #063836;
                    padding: 4px 9px;
                    cursor: pointer;

                    &:hover {
                        background: #65a2a5;
                        color: #043836;
                    }
                }

                &--empty-result {
                    padding: 6px 9px;
                    white-space: nowrap;
                }
            }
        }

        &--pair {
            position: relative;
            float: left;
            background: transparent;
            font-size: 12px;
            font-weight: bold;
            margin-right: 5px;
            margin-bottom: 5px;
            border: 1px solid #64a4a5;

            &--name {
                display: block;
                float: left;
                line-height: 19px;
                padding: 0px 5px;
            }

            &--remove-button {
                text-align: 12px;
                padding: 0px;
                height: 19px;
                width: 19px;
                background: #65a3a5;
                color: #002e2c;
                font-size: 12px;

                &:hover {
                    color: white;
                }

                >svg {
                    transform: translate(0.5px, 2px);
                }
            }
        }

        &--buttons {
            position: relative;
            padding: 5px 9px 9px;
        }

        &--button {
            font-size: 12px;
            font-weight: bold;
            background: none;
            color: white;
            width: 85px;
            padding: 3px 0px;
            border-radius: 2px;

            &.delete-button {
                border: 1px solid #d6626a;
                &:hover {
                    background: #d6626a;
                }
            }

            &.done-button {
                float: right;
                border: 1px solid #267f80;

                &:hover {
                    background: #267f80;
                }
            }
        }
    }
}