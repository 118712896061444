.setting-modal {
    &--body {
        position: relative;
        width: 360px;
        color: white;
        background: #074040;
        box-shadow: 0px 0px 7px #021313;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &--header {
        position: relative;
        padding: 9px 20px;
        border-bottom: 1px solid #90b3b3;    

        &--title {
            position: relative;
            float: left;
            font-size: 19px;
            font-weight: bold;
            color: #eee;
        }
    
        &--close-button {
            background: none;
            font-size: 18px;
            position: absolute;
            right: 13px;
            color: #ddd;
            top: 11px;
    
            &:hover {
                color: white;
            }
        }
    }

    &--main {
        position: relative;
        padding: 11px 23px 20px 20px;    
    }
}

.sound-effect-setting {
    margin-top: 3px;

    &--title {
        font-size: 15px;
        font-weight: bold;
        color: #90b3b3;
    }

    &--main {
        margin-top: 0px;
        display: table;
        width: 100%;
    }

    &--row {
        display: table-row;

        &--name {
            font-size: 14px;
            display: table-cell;
            padding-top: 13px;
            padding-right: 11px;
            white-space: nowrap;
            color: #ddd;
        }

        &--value {
            display: table-cell;
            width: 100%;
            font-size: 14px;

            .search-select {
                border-bottom: 1px solid #90b3b3;

                &--current-option {
                    line-height: 23px;
                    font-weight: bold;
                }

                &--input-icon {
                    right: 0px;
                }

                &--clear-button {
                    right: 0px;
                    background: transparent;
                    font-size: 14px;
                    margin-top: -1px;
                }
            }
        }
    }

    &--switch-off-including-nac {
        margin-top: 6px;
        display: flex;

        .checkbox {
            width: 21px;
            height: 21px;
        }
        >span {
            line-height: 21px;
            font-size: 13px;
            margin-left: 6px;
            color: #eee;
        }
    }
}