.auth-form {
    position: relative;
    width: 100%;
    color: white;
    min-height: 500px;
    
    &--title {
        font-family: 'Oswald', sans-serif;
        font-size: 41px;
        font-weight: bold;
        text-align: center;
        text-shadow: 0px 0px 8px #616161;
        white-space: nowrap;
    }

    &--caption {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #eee;
        margin-top: 9px;
    }

    &--main {
        position: relative;
        margin-top: 51px;
        width: 100%;
    }

    &--input-block {
        position: relative;
        width: 100%;
        margin-top: 20px;
    }

    &--input-label {
        position: relative;
        display: block;
        color: #ddd;
        font-size: 14px;
        font-weight: bold;
    }

    &--input {
        display: block;
        width: 100%;
        background: none;
        border-bottom: 2px solid white;
        color: white;
        font-size: 16px;
        padding: 7px 0px;
        font-weight: bold;

        &[type="email"] {
            width: calc(100% - 200px);
            padding-right: 200px;
        }
    }

    &--login-button, &--reset-password-button {
        width: 100%;
        margin-top: 27px;
        background: #1da59d;
        color: #ddd;
        font-size: 15px;
        padding: 10px 0px;
        font-weight: bold;
        border-radius: 2px;


        &:hover {
            color: white;
        }

        &:disabled {
            color: #ddd !important;
        }
    }

    &--message {
        background: #00000066;
        text-align: center;
        margin-top: 19px;
        padding: 7px;
        border-radius: 2px;
        font-size: 14px;
        margin-bottom: -11px;
    }

    &--reset-password-button {
        background: #e09657;
    }

    &--change-form-button {
        float: right;
        background: none;
        color: #eee;
        font-size: 13px;
        margin-top: 70px;
        &:hover {
            color: white;
        }
    }
}