.web-socket-client {
    display: flex;
    align-items: center;
    line-height: 28px;
    font-size: 13px;
    font-weight: bold;
    color: #bccfcf;

    &--reconnect-countdown, &--delay {
        margin-right: 5px;
    }

    &--mark {
        width: 9px;
        height: 9px;
        background: #c7767c;
        border-radius: 50%;
        margin-left: 7px;
        box-shadow: 0px 0px 2px #001515;

        &.CONNECTING {
            background: #c7bd71;
        }

        &.OPEN {
            background: #67b7b7;
        }
    }
}