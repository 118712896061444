.trading-container {
    position: relative;
    width: 100%;
    height: 100%;

    &--content-block {
        position: absolute;
        width: 100%;

        &.transactions {
            top: 0;
            height: 50%;
        }

        &.notifications {
            top: 50%;
            bottom: 0;
        }
    }

    &--draggable-line {
        position: absolute;
        width: 100%;
        height: 6px;
        top: 50%;
        transform: translateY(-3px);
        z-index: 2;
        background: transparent;
        cursor: ns-resize;
    }
}

.transaction-container {
    position: relative;
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;

    &--title {
        text-align: center;
        font-weight: bold;
        min-height: 32px;
        line-height: 32px;
        background: #0a4a4a;
        font-size: 15px;
    }

    &--list {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--clear-button {
        position: absolute;
        right: 33px;
        top: 0;
        height: 32px;
        width: 16px;
        font-size: 12px;
        background: none;
        color: #afddde;
        opacity: 0.7;
        padding: 0px;

        &:hover, &.active {
            opacity: 1.0;
        }

        >svg {
            margin-top: 3px;
        }
    }

    &--search-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 32px;
        padding: 0;
        width: 33px;
        font-size: 13px;
        background: none;
        color: #afddde;
        opacity: 0.7;

        &:hover, &.active {
            opacity: 1.0;
        }

        >svg {
            margin-top: 5px;
        }
    }

    &--search-input {
        position: relative;
        display: block;
        width: calc(100% - 16px);
        padding: 0px 8px;
        background: #002e2d;
        border-bottom: 1px solid #012d2d;
        height: 27px;
        min-height: 27px;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--item {
        border-bottom: 1px solid #002928;
        position: relative;
        padding: 6px 9px;

        @keyframes showNewItem {
            0% {
                background: #457f81;
            }
            100% {
                background: #043b3c;
            }
        }
        animation: 1.0s showNewItem;

        &--tag {
            position: absolute;
            font-size: 12px;
            right: 9px;
            padding: 1px 3px;
            background: #bfb462;
            color: #4f4816;
            font-weight: bold;
            top: 7px;
            border-radius: 1px;

            &.HEDGE {
                background: #3ca7a9;
                color: #0a3738;
            }
        }

        &--symbol-name {
            font-weight: bold;
            font-size: 14px;
        }

        &--profile-name {
            font-size: 12px;
            margin-top: 2px;
            color: #a5c7c7;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        &--main {
            display: table;
            margin-top: 8px;
            color: #ddd;
        }

        &--data {
            display: table-row;
        }

        &--data-name, &--data-value {
            display: table-cell;
            width: 100%;
            font-size: 13px;
            padding: 0.5px 0px;
            vertical-align: middle;
            white-space: nowrap;
        }

        &--data-name {
            color: #ddd;
        }

        &--data-value {
            float: right;
            text-align: right;
            color: #eee;
            font-weight: bold;

            &.positive {
                color: #84fbf9;
            }

            &.negative {
                color: #fd818a;
            }
        }

        &--account {
            font-size: 13px;
            font-weight: bold;
            color: #ccc;
            margin-top: 1px;
        }
    }

    &--account-popup {
        margin-right: 5px !important;
    }
}

.notification-container {
    position: relative;
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;

    &--audio-suspended {
        position: absolute;
        left: 0px;
        height: 32px;
        top: 0;
        width: 33px;
        z-index: 2;
        color: #ce757c;

        >svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--title {
        position: relative;
        text-align: center;
        font-weight: bold;
        min-height: 32px;
        line-height: 32px;
        background: #0a4a4a;
        font-size: 15px;
    }

    &--list {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--clear-button {
        position: absolute;
        right: 33px;
        top: 0;
        height: 32px;
        width: 16px;
        font-size: 12px;
        background: none;
        color: #afddde;
        opacity: 0.7;
        padding: 0px;

        &:hover, &.active {
            opacity: 1.0;
        }

        >svg {
            margin-top: 3px;
        }
    }

    &--search-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 32px;
        padding: 0;
        width: 33px;
        font-size: 13px;
        background: none;
        color: #afddde;
        opacity: 0.7;

        &:hover, &.active {
            opacity: 1.0;
        }

        >svg {
            margin-top: 5px;
        }
    }

    &--search-input {
        position: relative;
        display: block;
        width: calc(100% - 16px);
        padding: 0px 8px;
        background: #023534;
        border-bottom: 1px solid #012d2d;
        height: 27px;
        min-height: 27px;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--item {
        border-bottom: 1px solid #002928;
        position: relative;
        padding: 6px 9px;

        @keyframes showNewItem {
            0% {
                background: #457f81;
            }
            100% {
                background: #043b3c;
            }
        }
        animation: 1.0s showNewItem;

        &--user-server-info {
            font-size: 12px;
            color: #a1c4c3;
            float: left;
            max-width: 120px;
            margin-bottom: 3px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &--timestamp {
            font-size: 12px;
            float: right;
            color: #eee;
        }

        &--profile-name {
            font-size: 13px;
            font-weight: bold;
            color: white;
            margin-bottom: 4px;
            width: 100%;
            float: left;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        &--type {
            float: left;
            font-size: 12px;
            position: relative;
            background: #267f80;
            padding: 2px 6px;
            border-radius: 1px;
            font-weight: bold;
            color: #063d3e;

            &.OPERATION, &.SAVE_PROFILE_GROUPS {
                background: #5d8cb5;
                color: #224869;
            }

            &.PAUSED, &.ORDER_DISCARD, &.QTY_CAPPED, &.MAX_EXP_CAP, &.SMART_POS_ACCT_EXCEPTION {
                background: #bfb462;
                color: #4c4513;
            }

            &.WARNING, &.RESTART_TIMEOUT, &.STOP_TIMEOUT, &.RESUME_TIMEOUT, &.PAUSE_TIMEOUT, &.SWITCH_OFF, &.REDUCE_ONLY_SWITCH_OFF, &.PROFILE_GROUPS_SAVE_FAIL, &.SERVICE_UNAVAILABLE {
                background: #dc7479;
                color: #672225;
            }

            &.RESUMED, &.SWITCH_ON, &.REDUCE_ONLY_SWITCH_ON, &.GW_CONNECT {
                background: #37a7a9;
                color: #084748;
            }

            &.STOPPED, &.CRASH, &.START_FAIL, &.PAUSE_FAIL, &.RESUME_FAIL, &.PARA_LOAD_FAIL, &.CANCEL_FAIL, &.SWITCH_ON_FAIL, &.SWITCH_OFF_FAIL, &.UPDATE_PARAMS_FAIL, &.STOP_FAIL, &.CANCEL_AND_PAUSE_FAIL {
                background: #c5656e;
                color: #5a0b12;
            }

            &.PARA_UPDATED, &.LOG_LEVEL_UPDATED {
                background: #3ca7a9;
                color: #0a3738;
            }

            &.STARTED {
                background: #31a2bb;
                color: #053f4c;
            }

            &.FUND_TRANSFER, &.BORROW_COIN {
                background: #d8aa55;
                color: #5f4008;
            }

            &.REPAY_COIN {
                background: #94d094;
                color: #0b2d0b;
            }
        }

        &--message {
            position: relative;
            font-size: 13px;
            margin-top: 9px;
            line-height: 17px;
            color: #c7d8d7;
            user-select: text;
            word-break: break-word;
        }

        &--username {
            color: #c4f7f5;
        }
    }

    &--filters {
        position: relative;
        width: 100%;
        height: 27px;
        min-height: 27px;
    }

    &--filter {
        position: relative;
        height: 100%;
        width: 33.3333%;
        padding: 0;
        font-size: 12px;
        background: #002e2d;
        color: #eee;
        font-weight: bold;
        border-right: 1px solid #0a4a4a;

        &:last-child {
            border-right: none;
        }

        &.active {
            background: #267f80;
            color: white;
        }
    }

    &--empty-message {
        text-align: center;
        padding: 7px 0px;
        font-size: 14px;
        font-weight: bold;
        color: #c4d4d5;
    }
}

.risk-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar{
        height: 0px;
    }

    &--header {
        position: relative;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
    }

    &--menu {
        position: relative;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
    }

    &--toggle-detail-button, &--toggle-bulk-transfer-selector-button {
        position: relative;
        height: 100%;
        border: none;
        right: unset;
        background: #375c5c;
        border-radius: 0px;
        vertical-align: top;
        color: #ddd;
        font-size: 12px;
        font-weight: bold;
        padding: 0px 10px;

        &:hover {
            color: white;
        }
    }

    &--toggle-bulk-transfer-selector-button {
        background: #21757e;

        &.enabled {
            background: #478087;
            box-shadow: inset 0px 0px 3px black;
        }
    }

    &--title {
        text-align: center;
        font-weight: bold;
        background: #0a4a4a;
        font-size: 15px;
        text-indent: 9px;
    }

    &--sort-order-button {
        position: absolute;
        right: 3px;
        top: 0;
        height: 100%;
        padding: 0;
        width: 33px;
        font-size: 18px;
        background: none;
        color: #afddde;
        opacity: 0.7;

        &:hover, &.active {
            opacity: 1.0;
        }

        >svg {
            margin-top: 5px;
        }
    }

    &--search-input {
        position: relative;
        display: block;
        width: calc(100% - 18px);
        padding: 0px 9px;
        background: #032d2c;
        height: 29px;
        min-height: 29px;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--body {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 3px;
    }

    &--item {
        position: relative;
        cursor: pointer;
        padding-bottom: 11px;

        .position-item, .margin-account-balance-item {
            padding: 2px 0px 5px;
        }

        .margin-account-balance-item {
            &--header {
                padding: 4px 9px;
            }
            &--pair-table {
                margin: 5px 9px 0px;
            }
            &--pricing-wrapper {
                margin: 0px 9px;
            }
        }

        .position-item {
            padding-top: 4px;
        }

        .cross-margin-account-balance-item {
            padding: 2px 4px 5px;
        }

        &.clickable {
            .position-item, .margin-account-balance-item, .cross-margin-account-balance-item, .cross-account-balance-item {
                &:hover {
                    background: #012f2f;
                }
            }

            .margin-account-balance-item {
                &:hover {
                    .margin-account-balance-item--pair-table thead {
                        background: #033c3b;
                    }
                }
            }
        }

        &.not-allowed {
            opacity: 0.4;
            cursor: not-allowed;
        }

        &--bulk-transfer-select-mark {
            position: absolute; 
            background: #375c5c; 
            width: 21px; 
            height: 21px; 
            right: 10px; 
            top: 7px; 
            border-radius: 50%;
            z-index: 2;
            font-size: 14px;

            &.marked {
                background: #62b0b1;
                color: #023c3c;
            }

            >svg {
                position: relative;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &--bulk-transfer-summary {
        background: #478087;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        color: #012b2b;
        font-weight: bold;
        font-size: 14px;
        box-shadow: 0px 0px 3px #012b2b;

        >span {
            margin-left: 9px;
        }

        &--confirm-button {
            height: 100%;
            vertical-align: top;
            float: right;
            padding: 0px 11px;
            background: #012b2b;
            color: #ddd;
            font-weight: bold;

            &:hover {
                color: white;
            }
            
            &:not(:last-child) {
                border-left: 1px solid #478087;
            }
        }
    }

    &--table-modal {
        background: #023c3c;
    }

    &--portfolio {
        display: flex;
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        padding: 0px 9px;
        background: #0a4a4a;

        >label {
            width: 100%;
        }

        .search-select {
            &--options-wrapper {
                right: 0px;
            }
            &--current-option {
                text-align: right;
                &--name {
                    margin-right: 23px;
                }
            }
            &--input-icon {
                opacity: 0.75;
                right: 0px;
                font-size: 16px;
                transform: translateY(-8px);
            }
        }
    }
}

.position-item {
    position: relative;

    &:hover {
        .position-item--token-transfer-popup--trigger-button {
            opacity: 1.0 !important;
        }
    }

    &--header {
        position: relative;
        border-bottom: 1px solid #277e80;
        padding: 4px 9px;
        font-weight: bold;
        color: white;

        &--info {
            display: flex;
            white-space: nowrap;
            font-size: 13px;
            margin-bottom: 3px;
        }

        &--tag {
            margin-right: 5px;
            background: #4dbbbb;
            padding: 0px 6px;
            color: #134e4e;
            border-radius: 1px;
        }

        &--timestamp {
            color: #95b0b1;
        }

        &--title {
            font-size: 14px;
            line-height: 17px;

            &--account-name {
                &.clickable {
                    text-decoration: underline dotted;
                    &:hover {
                        text-decoration: underline solid;
                    }
                }
            }
        }
    }

    &--direction-table {
        position: relative;
        width: calc(100% - 18px);
        border-collapse: collapse;
        text-align: center;
        margin: 7px 9px 0px;
        font-size: 12px;

        thead {
            background: #012e2e;
            th {
                padding: 3px 0px;

                &:nth-child(2) {
                    color: #051a1b;
                    background: #277e80;
                }

                &:nth-child(3) {
                    color: #150b0c;
                    background: #8c5f66;
                }
            }
        }
        tbody {
            th {
                font-weight: normal;
                color: #d0e6e6;
                text-align: left;
                white-space: nowrap;
                padding: 0px 9px;
            }
            td {
                font-size: 13px;
                font-weight: bold;
                padding: 0px;
                padding-bottom: 1px;
                width: 50%;

                &:nth-child(2) {
                    background: #034848;
                }

                &:nth-child(3) {
                    background: #524043;
                }
            }
            tr {
                &:first-child td {
                    padding-top: 2px;
                }
            }
        }
    }

    &--data {
        display: table;
        position: relative;
        width: calc(100% - 18px);
        margin: 7px 9px 0px;
        font-size: 13px;
        border-top: 1px dashed #025050;
        padding: 3px 0px 1px;
    }

    &--data-row {
        display: table-row;

        &--name, &--value {
            display: table-cell;
        }

        &--name {
            text-align: left;
        }

        &--value {
            text-align: right;
            font-weight: bold;
            padding-top: 1px;

            >span {
                &.positive {
                    color: #84fbf9;
                }
    
                &.negative {
                    color: #fd818a;
                }
            }
        }
    }

    &--pricing-wrapper {
        position: relative;
        display: flex;
        width: calc(100% - 18px);
        margin: 0px 9px;

        &--ratio-description {
            max-width: 308px;
            box-shadow: 0px 0px 3px #012b2b;
            background: #87a2a2ed;
            color: #072a2b;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
        }
    }

    &--pricing-section {
        position: relative;
        width: 33.333%;
        text-align: center;

        &.ratio {
            .position-item--pricing-section--label {
                border-bottom: 1px dashed;
            }
        }

        &--label {
            font-size: 12px;
            color: #a2bfbf;
            line-height: 17px;
        }
        
        &--value {
            font-size: 13px;
            font-weight: bold;
            line-height: 16px;
            color: #c7dada;
            line-height: 16px;

            &.highlight {
                color: #e2cb6a;
            }
        }
    }

    &--token-transfer-popup {

        &--trigger-button {
            position: absolute;
            right: 5px;
            background: none;
            color: #7eddde;
            font-size: 15px;
            opacity: 0;

            &:hover {
                color: white;
            }
        }

        &--main {
            margin-left: 5px;
            position: relative;
            padding: 8px 14px 15px;
            max-width: 270px;
            background: #032d2c;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
        }
    }
}

.position-table-container {

    ::-webkit-scrollbar {
        height: 7px;
        width: 7px;
        background-color: #053333;
        border-radius: 5px;
        overflow: hidden;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #368686;
    }

    &--header {
        position: absolute;
        display: flex;
        flex-direction: column;
        height: 81px;
        width: 100%;
        background: #023c3c;
        z-index: 3;


        &--row-1 {
            position: relative;
            height: 29px;
            line-height: 29px;
            padding: 5px 0px 0px;
        }

        &--title {
            float: left;
            height: 100%;
            margin-left: 21px;
            font-weight: bold;
            font-size: 17px;
        }

        &--select {
            position: relative;
            display: flex;
            float: left;

            &--title {
                white-space: nowrap;
                margin-right: 7px;
                font-size: 13px;
                font-weight: bold;
                color: #a3c5c5;
            }

            .search-select {
                &--current-option {
                    padding-right: 29px;
                    font-size: 14px;
                    font-weight: bold;
                }
                &--input-icon {
                    opacity: 0.8;
                }
                &--options-wrapper {
                    margin: 0px;
                    background: #0c6161;
                    box-shadow: 0px 0px 3px black;
                }
                &--option {
                    line-height: 25px;
                    &.focused-option {
                        background: #4d8c8c;
                    }
                }
            }
        }

        &--expiry-select, &--portfolio-select {
            margin-left: 7px;
        }

        &--portfolio-select {
            margin-left: 25px;
        }

        &--stats {
            margin-left: 21px;
        }

        &--reset-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: transparent;
            border: 1px solid #9bbcbc;
            color: #9bbcbc;
            font-weight: bold;
            padding: 0px 12px;
            margin-left: 11px;
            line-height: 19px;

            &:hover {
                background: #9bbcbc;
                color: #023c3c;
            }
        }

        &--close-button {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-right: 15px;
            height: 100%;
            font-size: 19px;
            background: none;
            color: #ddd;

            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(1px);
            }
        }
    }

    &--table-wrapper {
        position: absolute;
        top: 81px;
        bottom: 0;
        width: 100%;
        background: #023c3c;
        overflow: auto;
    }

    &--table {
        position: relative;
        width: 100%;
        border-collapse: collapse;

        tr {
            &:nth-child(even) {
                background: #023333;
            }

            &:hover {
                td {
                    background: #044444;
                }
            }
        }

        td {
            font-size: 13px;
            color: #c7d8d8;
            font-weight: bold;
            padding: 5px 5px;
            white-space: nowrap;

            &.positive {
                color: #86e2e0 !important;
            }
            &.negative {
                color: #fd818a !important;
            }
            &.ratio {
                color: #e2cb6a;
            }
        }
    }

    &--table-header {

        &--input {
            height: 14px;
            padding: 1px 7px;
            margin-left: 8px;
            border-left: 1px solid #80a5a5;
            background: none;
            font-size: 13px;
            cursor: text;
            color: #c7d8d8;
            font-weight: bold;
            max-width: 120px;
        }

        th {
            text-align: left;
            position: sticky;
            top: 0px;
            background: #081a1b;
            font-size: 13px;
            padding: 5px 5px;
            cursor: pointer;
            white-space: nowrap;
            z-index: 2;
            color: #ddd;

            &:hover {
                color: white
            }

            &.sorted {
                >span {
                    color: #e2cb6a;
                }
            }
        }
    }

    &--group-header {
        th {
            font-size: 16px;
            background: #023c3c;
            padding: 5px 0px;
            position: -webkit-sticky;
            position: sticky;
            box-shadow: 0px 2px 0px #9ebdbd;
            padding-top: 23px;
            top: 9px;
            z-index: 1;
        }

        &.first-group {
            th {
                padding-top: 5px;
                position: sticky;
                top: 27px;
            }
        }

        &--name {
            margin-left: 7px;
            color: #eee;
            line-height: 21px;
            text-align: left;
            font-size: 17px;
        }

        &--stats {
            margin-top: 2px;
            margin-left: 7px;
        }
    }

    &--positions-stats {
        &--notional-sum, &--funding-amount-sum {
            color: #a3c4c4;
            font-weight: bold;
            display: flex;
            line-height: 19px;
            height: 21px;

            &--label {
                font-size: 13px;
                color: #a3c5c5;
            }

            &--estimated-usd-value {
                margin-left: 3px;
                &.positive {
                    color: #86e2e0;
                }
                &.negative {
                    color: #fd818a;
                }

                &--sign {
                    font-size: 13px;
                    color: #a3c5c5;
                }
                &--currency {
                    font-size: 13px;
                    color: #c7d8d8;
                    margin-right: 3px;
                }
            }

            &--item {
                margin: 0px 11px 0px 7px;

                &--unit {
                    font-size: 13px;
                    margin-right: 5px;
                    color: #c7d8d8;
                }

                &--value {
                    &.positive, &.profit {
                        color: #86e2e0;
                    }
                    &.negative, &.loss {
                        color: #fd818a;
                    }
                }

                &--detail {
                    display: inline-block;
                    font-size: 13px;
                    margin-left: 4px;
                    color: #708888;
                    
                    &--long, &--profit {
                        color: #86e2e0;
                        margin: 0px 2px;
                    }
                    &--short, &--loss {
                        color: #fd818a;
                        margin: 0px 2px;
                    }
                }
            }

            &--empty {
                font-size: 13px;
                margin-left: 7px;
                color: #c7d8d8;
            }
        }
    }

    &--notional-unit {
        font-size: 12px;
        color: #c7d8d8;
        margin-right: 3px;
        min-width: 29px;
    }

    &--funding-rate {
        position: relative;
        display: flex;
        line-height: 14px;
    }

    &--funding-rate-block {
        width: 50%;
        &:nth-child(1) {
            padding-right: 6px;
        }
        &:nth-child(2) {
            padding-left: 6px;
        }

        &--name {
            font-size: 12px;
            opacity: 0.8;
        }

        &--value {
            color: rgb(253, 129, 138);
            &.positive {
                color: rgb(134, 226, 224);
            }
        }
    }

    &--funding-rate-multiplier {
        margin-left: 3px;
        color: #c7d8d8;
    }
}

.single-limit {
    &--data-row {
        &--value {
            margin-left: 12px;
        }
    }
}

.fill-container {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    background: #072d2e;
    min-width: 380px;
    border-radius: 10px;
    box-shadow: 0px 0px 3px black;

    &--title {
        padding: 0px 11px;
        font-size: 16px;
        font-weight: bold;
        color: #74adad;
        position: sticky;
        top: 0px;
        z-index: 2;
        line-height: 29px;
        background: #072d2f;
        border-radius: 10px;

        &--profile-name {
            float: right;
            font-size: 12px;
            color: #ddd;
        }
    }

    &--header {
        padding-bottom: 7px;
        border-bottom: 1px solid #010f10;
        background: #052c2f;
        position: sticky;
        top: 29px;
        z-index: 2;

        &--search-input {
            display: block;
            width: calc(100% - 22px);
            font-size: 13px;
            padding: 3px 11px;
            background: #0e6160;
            cursor: text;
            color: #eee;
            font-weight: bold;
        }

        &--time-ranges {
            position: relative;
            display: flex;
            padding: 0px 11px;

            &--inputs {
                width: 100%;
                display: flex;
                position: relative;

                >span {
                    display: block;
                    margin: 0px 11px;
                    transform: translateY(20px);
                }
            }
        }

        &--time-range {
            width: 195px;

            &--side {
                text-align: left;
                font-size: 12px;
                font-weight: bold;
                color: #bbb;
                text-transform: capitalize;
            }

            input {
                display: block;
                width: 100%;
                font-size: 13px;
                background: none;
                color: white;
                font-weight: bold;
                border-bottom: 1px solid #407575;

                &::-webkit-clear-button {
                    display: none;
                }
                &::-ms-clear {
                    display: none;
                }
    
                &::-webkit-calendar-picker-indicator {
                    color: #368686;
                    cursor: pointer;
    
                    &:hover {
                        color: #ddd;
                    }
                }
            }
        }

        &--ok-button {
            height: 19px;
            padding: 0px 11px;
            margin-top: 22px;
            margin-left: 11px;
            font-size: 13px;
            font-weight: bold;
            background: #0b6160;
            color: #ddd;

            &:hover {
                color: white;
            }
        }
    }

    &--main {
        overflow: auto;
    }

    &--item {
        padding: 5px 11px;
        &:not(:last-child) {
            border-bottom: 1px solid #0b6160;
        }
    }

    &--empty-message {
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
    }

    &--fetching {
        position: sticky;
        bottom: 0px;
        line-height: 30px;
        text-align: center;
        background: #021f21d9;
        width: 100%;
        font-size: 14px;
        font-weight: bold;

        &--stop-button {
            float: right;
            right: 6px;
            background: #dc7479;
            color: #672225;
            font-weight: bold;
            top: 5px;
            position: absolute;
            padding: 3px 12px;

            &:hover {
                color: white;
            }
        }
    }

    &--aggregated-stats {
        position: relative;
        max-height: 100%;

        &--body {
            background: #082c2ded;
            box-shadow: 0px 0px 3px black;
            display: inline-flex;
            flex-direction: column;
            margin-right: 10px;
            overflow: auto;
            border-radius: 10px;
            max-height: 100%;
        }

        &--title {
            padding: 0px 9px;
            font-size: 12px;
            font-weight: bold;
            color: #74adad;
            line-height: 25px;  
            border-bottom: 1px solid;
        }

        &--item {
            padding: 6px  9px;
            &:first-child {
                padding-top: 0px;
            }
            &:not(:last-child) {
                border-bottom: 1px solid #0b6160;
            }
        }  

        &--group-by-selectors {
            text-align: center;
            padding: 8px 0px;
        }
        
        &--group-by-selector {
            min-width: 120px;
            height: 25px;
            padding: 0px 7px;
            font-size: 12px;
            background: none;
            border: 1px solid #74adac;
            color: #74adac;
            font-weight: bold;

            &:hover {
                color: white;
            }

            &.active {
                background: #74adac;
                color:#072d2f;
            }

            &:first-child {
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
            }

            &:last-child {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
            }
        }

        &--main {
            position: relative;
            overflow: auto;
        }
    }
}


.fill-item {
    position: relative;

    &--identity {
        font-size: 12px;
        line-height: 17px;
        color: #99b6b7;


        &--id {
            white-space: nowrap;
            max-width: 400px;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
                overflow: visible;
            }
        }
        
        span {
            font-weight: bold;
            user-select: all;
        }
    }

    &--tags {
        position: absolute;
        top: 0px;
        font-size: 12px;
        right: 0;
        text-align: right;
        font-weight: bold;

        &--tag-name, &--status {
            border-right: 2px solid;
            padding-right: 5px;
            line-height: 13px;
            margin: 2px 0px;
        }

        &--tag-name {
            &.QUOTE {
                color: #cec47c;
                border-color: #bfb461;
            }
            &.HEDGE {
                border-color: #3ba7a9;
                color: #5cbdbf;
            }
        }

        &--status {
            &--cog-cancel-count {
                color: #9dd2a6;
                margin-right: 7px;
                border-right: 2px solid;
                padding-right: 5px;
                line-height: 13px;
                display: block;
                float: left;
            }

            &.CANCELLED {
                color: #ce757c;
            }
        }
    }

    &--main {
        position: relative;
        margin-top: 9px;
        &--body {
            display: flex;
            font-size: 15px;
            font-weight: bold;
            line-height: 17px;
            color: white;
        }
    }

    &--side {
        &.BUY, &.BUY_OPEN, &.BUY_CLOSE {
            color: #8fe6e8;
        }

        &.SELL, &.SELL_OPEN, &.SELL_CLOSE {
            color: #fd818a;
        }
    }

    &--qty {
        font-weight: normal;
        padding: 0px 8px;

        >span {
            font-size: 12px;
            margin-left: 2px;
            font-weight: bold;
            color: #d8cc75;
            white-space: nowrap;
        }
    }

    &--account {
        font-size: 12px;
        margin-left: 7px;
        color: #ccc;

        >span {
            font-weight: bold;
            color: #94ccd0;
            opacity: 1.0;
            font-size: 13px;
            padding-left: 1px;
        }
    }

    &--prices {
        display: flex;
        line-height: 23px;
    }

    &--fill-price {
        font-size: 14px;
        >span {
            font-weight: bold;
        }
    }

    &--price-info {
        font-size: 13px;
        margin-left: 7px;
        color: #bbb;
        >b {
            color: #ddd;

            &.positive {
                color: #8fe6e8;
            } 
            &.negative {
                color: #fd818a;                
            }
        }
    }

    &--table-info {
        margin-top: 9px;
    }

    &--info-row {
        display: flex;
        font-size: 12px;
        &--name {
            white-space: nowrap;
            color: #99b6b7;
        }
        &--value {
            width: 100%;
            text-align: right;
            font-weight: bold;

            >span {
                color: #d8cc75;
                margin-right: 5px;
            }
        }
    }
}

.fill-stats-item {

    &--name {
        font-size: 13px;
        font-weight: bold;
        color: #92aeb0;
    }

    &--table {
        margin-top: 5px;
        width: 100%;
        border-collapse: collapse;
        thead {
            th {
                font-size: 12px;
                &:nth-child(1) {
                    text-align: left;
                    background:#0b393c;
                    padding-left: 5px;
                }
                &:nth-child(2) {
                    background: #268082;
                }
                &:nth-child(3) {
                    background: #8c5f66;
                }
            }
        }
        tbody {
            white-space: nowrap;
            th {
                text-align: left;
                font-size: 12px;
                font-weight: normal;
                padding: 0px 5px;
            }
            td {
                min-width: 70px;
                font-size: 13px;
                font-weight: bold;
                padding: 1px 5px;
                text-align: center;

                &:nth-child(2) {
                    background: #11484b;
                }
                &:nth-child(3) {
                    background: #393e43;
                }
            }

            tr {
                &:first-child td{
                    padding-top: 2px;
                }
                &:last-child td{
                    padding-bottom: 2px;
                }
            }
        }
    }
}

.exposure-window {
    
    &--header {
        position: relative;
        display: flex;
        width: 100%;
        height: 35px;
        min-height: 35px;
        line-height: 35px;
        background: #042f2e;
        font-weight: bold;
        z-index: 4;

        &--modes {
            display: inline-flex;
        }

        &--mode {
            font-size: 17px;
            font-weight: bold;
            height: 100%;
            padding: 0px 13px;
            float: left;
            background: #0a4343;
            color: #6b9494;

            &:hover {
                color: #ddd;
            }

            &.active {
                background: #105b5b;
                color: white;
            }
        }

        &--filters {
            margin-left: 17px;
            display: flex;
        }

        &--filter {
            display: flex;
            white-space: nowrap;
            margin-right: 15px;

            >span {
                opacity: 0.8;
                margin-right: 7px;
                font-size: 14px;
            }

            .search-select {
                &--current-option {
                    padding-right: 21px;
                }
                &--input-icon {
                    right: 0px;
                    opacity: 0.7;
                }
            }
        }

        &--comparison-toggle {
            display: flex;
            margin-left: 17px;
            >span {
                opacity: 0.8;
                margin-right: 7px;
                font-size: 14px;
            }
            .toggle {
                top: 50%;
                transform: translateY(-50%);
                height: 22px;
                &--button {
                    height: 18px;
                    width: 18px;
                }
                &--track {
                    width: 40px;
                }
                &--label {
                    line-height: 22px;
                    font-size: 14px;
                }
            }
        }

        &--right-section {
            position: absolute;
            right: 0;
            height: 100%;
            display: inline-flex;
        }

        &--last-update-time {
            font-size: 13px;
            color: #ddd;
            margin-right: 7px;
        }

        &--fetch-button {
            height: 100%;
            font-size: 14px;
            font-weight: bold;
            padding: 0px 11px;
            background: #0a4342;
            color: #8eb6b5;

            &:hover {
                background: #115b5a;
                color: white;
            }
            
        }

        &--sign-popup {
            background: #042e2e;
            margin: 3px 9px;
            box-shadow: 0px 0px 3px black;
            overflow: hidden;

            &--trigger {
                height: 100%;
                font-size: 14px;
                font-weight: bold;
                padding: 0px 11px;
                background: mediumpurple;
                color: #2e155f;

                &:hover {
                    background: #9060f0;
                    color: white;
                }

                >svg {
                    font-size: 17px;
                    margin-right: 7px;
                    transform: translateY(2px);
                }
            }

            &--header {
                background: #105b5b;
                font-size: 13px;
                font-weight: bold;
                color: white;
                padding: 5px 7px;
            }

            &--main {
                padding: 5px 7px 7px;
            }

            &--user, &--portfolio {
                white-space: nowrap;
                font-size: 16px;
                font-weight: bold;
                color: white;

                >label {
                    font-size: 14px;
                    opacity: 0.7;
                    margin-right: 5px;
                    display: block;
                }
            }

            &--portfolio {
                margin-top: 10px;

                &--items {
                    margin-top: 5px;
                }

                &--item {
                    position: relative;
                    display: inline-flex;
                    >label {
                        margin-right: 5px;
                    }
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }

            &--message {
                font-size: 15px;
                text-align: center;
                line-height: 24px;
                font-weight: bold;
                color: gold;
                margin-top: 5px;
            }

            &--confirm {
                display: block;
                width: 100%;
                margin-top: 9px;
                font-size: 15px;
                font-weight: bold;
                padding: 5px 0px;
                border-radius: 2px;
                background: steelblue;
                color: #0e3353;

                &:hover {
                    color: white;
                }

                &:disabled {
                    opacity: 0.8;
                }

                &.signing {
                    background: goldenrod;
                    color: #775707;
                }

                &.signed {
                    background: #105b5b;
                    color: white;
                }
            }
        }
    }

    &--body {
        position: relative;
        display: inline-flex;
        height: calc(100% - 35px);
        width: 100%;
    }
    
    &--main {
        position: relative;
        overflow: auto;
    }

    &--comparison-wrapper {
        position: relative;
        overflow: auto;
        width: 100%;

        .exposure-comparison-block {
            position: relative;
            height: 100%;
            float: left;

            &:nth-child(1) {
                width: 35%;
                border-right: 1px solid #4e9898;
            }

            &:last-child {
                width: calc(65% - 1px);
            }
        }

        &--draggable-line {
            position: absolute;
            width: 6px;
            height: 100%;
            left: 35%;
            transform: translateX(-3px);
            z-index: 2;
            background: transparent;
            cursor: ew-resize;
        }
    }

    &--snapshot-popup {
        background: #052f2e;
        box-shadow: 0px 0px 3px black;
        margin-bottom: 5px;
        &--trigger {
            display: inline-flex;
            margin-left: 11px;
            padding: 0px;
            background: none;
            font-size: 14px;
            font-weight: bold;
            color: white;

            >span {
                opacity: 0.7;
            }
            >svg {
                font-size: 16px;
                transform: translateY(1px);
                margin-left: 5px;
                opacity: 0.7;
            }

            >.portfolio-name {
                margin: 0px 5px;
                background: #eac773;
                padding: 0px 5px;
                color: black;
                border-radius: 3px;
                opacity: 1.0;

                &.all {
                    background: #6fa7a7;
                }
            }

            &:hover {
                >svg {
                    opacity: 1.0;
                }
            }   
        }
        &--list {
            position: relative;
            max-height: 400px;
            overflow: overlay;
            display: flex;
            flex-direction: column;
        }

        &--item {
            white-space: nowrap;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #5f7473;
            background: transparent;
            padding: 6px 9px;
            cursor: pointer;
            text-align: center;
            color: white;

            &.left-algin {
                text-align: left;
            }
            
            >.portfolio-name {
                margin: 0px 5px;
                background: #eac773;
                padding: 0px 5px;
                color: black;
                border-radius: 3px;

                &.all {
                    background: #6fa7a7;
                }
            }

            &:hover {
                background: #115b5a;
            }
        }

        &--type {
            position: relative;
            min-width: 70px;
            padding: 5px 0px;
            font-size: 13px;
            font-weight: bold;
            width: 50%;
            background: #0a4a49;
            color: #ddd;
            &:not(:last-child) {
                border-right: 1px solid #052f2e;
            }

            &:hover {
                color: white;
            }

            &.selected {
                background: #115b5a;
                color: white;
            }
        }
    }

    &--right-column {
        border-left: 1px solid #063838;
    }

    &--chart-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000a0ae3;
        z-index: 100;

        &--main {
            position: relative;
            width: 100%;
            max-width: 1200px;
            min-width: 800px;
            margin: 0 auto;
        }

        .exposure-chart {
            flex-direction: row;
            width: 100%;
            background: #032e2e;

            &--main {
                width: calc(100% - 350px);
            }
            &--tooltip {
                width: 350px;
                min-width: 350px;
            }

            &--header {
                &--title {
                    text-indent: 11px;
                }
                &--search-input {
                    padding: 0px 11px;
                }
    
                &--exposure-token, &--value-type {
                    margin: 0px 11px;
                }
            }

            &--wrapper {
                height: 100%;
            }
        }
    }
}

.exposure-comparison-block {

    ::-webkit-scrollbar {
        height: 10px;
    }

    &--table {
        position: absolute;
        width: 100%;
        top: 0px;
        bottom: 30px;
        overflow: auto;
    }

    &--footer {
        position: absolute;
        width: 100%;
        bottom: 0px;
        height: 30px;
        background: #053838;

        &--snapshots {
            display: inline-flex;
            >span {
                position: relative;
                font-size: 14px;
                font-weight: bold;
                margin-left: 11px;
                display: inline-flex;
            }
        }

        &--fetching-text {
            float: right;
            font-size: 13px;
            margin-right: 11px;
            font-weight: bold;
            color: #73bfbf;
        }
    }

    .exposure-window--header--sign-popup--trigger {
        position: relative;
        float: right;
        line-height: 30px;
    }
}

.exposure-table {
    position: relative;
    max-width: 100%;
    border-collapse: collapse;

    &--head {
        th {
            text-align: left;
            position: sticky;
            top: 0px;
            background: rgb(8, 26, 27);
            font-size: 13px;
            padding: 5px;
            white-space: nowrap;
            z-index: 3;
            color: #ddd;
            text-align: left;

            &.index {
                padding-right: 28px;
            }

            &.source-exposure, &.account-exposure, &.coin-exposure, &.initial-balance, &.net-exposure, &.net-exposure-in-usdt, &.coin-exposure-in-usdt, &.account-exposure {
                text-align: right;
            }

            &.level-1 {
                background: #0f2e31;
            }
            &.level-2 {
                background: #236269;
            }
            &.sortable {
                cursor: pointer;
                &:hover {
                    color: white;
                }
                &.sorted {
                    color: #e2cb6a;
                }
            }

            &.threshold {
                >button {
                    font-size: 12px;
                    font-weight: bold;
                    margin-left: 5px;
                    border: 1px solid;
                    background: none;
                    color: #649c9b;

                    &:hover {
                        color: #8bbdbc;
                    }
                }   
            }

            &.exposure-variables {
                >button {
                    font-size: 12px;
                    margin-top: 3px;
                    font-weight: bold;
                    width: 50px;
                    height: 21px;

                    &:not(:last-child) {
                        margin-right: 3px;
                    }

                    &.save {
                        background: steelblue;

                        &:hover {
                            color: white;
                        }
                    }
                    &.reset {
                        background: #ddd;

                        &:hover {
                            background: white;
                        }
                    }
                }
            }


        }

        &--row.has-stats{
            >th {
                top: 27px;
            }
        }

        &--nav-stats {
            background: #128282;
            padding: 0px;
            position: sticky;
            top: 0px;
            z-index: 1;

            >span {
                padding: 0px 5px 0px 11px;
                font-size: 14px;
                font-weight: bold;
                line-height: 27px;
                user-select: text;
            }
        }
    }

    &--csv-download-popup {
        &--trigger {
            position: absolute;
            margin-left: 5px;
            background: none;
            color: rgb(109, 154, 154);
            font-size: 18px;
            padding: 0px;

            &:hover {
                color: white;
            }
        }
    }

    &--file-download {
        button {
            font-size: 14px;
            font-weight: bold;
            background: #6aa5a4;
            color: #043131;
            padding: 4px 11px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;

            &:hover {
                color: white;
            }
        }
    }

    &--body {
        td {
            padding: 2px 5px;
            font-size: 15px;
            font-weight: bold;
            color: #c7d8d8;
            user-select: all;

            &:first-child {
                white-space: nowrap;
            }

            &.right-align {
                text-align: right;
            }
        }
    }

    &--exposure-data {
        text-align: right;

        &.positive {
            color: #6de2e2;
        }

        &.negative {
            color: #fd7b93;
        }

        &.highlight {
            color: #efbd62;
        }

        &--diff {
            margin-left: 3px;

            &.positive {
                color: #90f19d;
            }

            &.negative {
                color: #efbd62;
            }
        }
    }

    &--level-1-row {
        cursor: pointer;
        &.expanded {
            background: #073e3e;
            border-bottom: 2px solid #267b7b;
        }
        &:hover {
            background: #073e3e !important;
        }
        td {
            padding: 6px 6px;
        }
    }

    &--level-2-row {
        cursor: pointer;
        &.expanded {
            background: #073e3e;
            border-bottom: 1px solid #267b7b;
        }
        &:hover {
            background: #073e3e !important;
        }
        td {
            font-size: 15px;
            padding: 4px 6px;
        }
        &--count {
            margin-left: 5px;
            font-size: 13px;
            opacity: 0.8;
        }
    }

    &--exposure-item-row {
        background: #074444;
        &.even-row {
            background: #085656;
        }

        td {
            font-size: 13px;
            padding: 2px 11px;
        }
    }

    &--exposure-item-source {
        white-space: nowrap;
    }

    &--exposure-item-source-type {
        background: #9dbdbd;
        padding: 0px 4px;
        border-radius: 2px;
        color: #0a4a4a;
        margin-right: 5px;

        &.SPOT_BALANCE {
            background: #7badd8;
        }

        &.MARGIN_BALANCE {
            background: #d0c77b;
        }

        &.CROSS_MARGIN_BALANCE {
            background: #efc374;
        }

        &.FUTURE_BALANCE {
            background: #c57593;
        }

        &.SWAP_BALANCE {
            background: #95d6a1;
        }

        &.POSITION {
            background: floralwhite;
        }

        &.CROSS_BALANCE {
            background: #c79c7e;
        }

        &.MANUAL_ADJUSTMENT {
            background: #b386e2;
            color: #3f1969;
        }
    }

    &--exposure-item-detail {
        white-space: nowrap;
        &--block {
            display: flex;
        }
        &--name {
            font-weight: normal;
            margin-right: 5px;
        }
        &--value {
            text-align: right;
            float: right;
            width: 100%;
            user-select: all;
        }
    }

    &--variables {
        &--position-threshold {
            position: relative;
            line-height: 21px;
            height: 21px;
            >input {
                display: block;
                width: 100%;
                text-indent: 3px;
                font-size: 13px;
                font-weight: bold;
                height: 21px;
                color: white;
                background: #0c4a49;
                cursor: text;
            }
            >span {
                position: absolute;
                right: 5px;
                top: 0px;
            }
        }
    }

    &--threshold-min-value-input {
        background: #166766;
        cursor: text;
        border-bottom: 1px solid #0c4a49;

        >input {
            font-size: 13px;
            font-weight: bold;
            color: white;
            background: transparent;
            text-indent: 3px;
            cursor: text;
            max-width: 101px;
        }
    }
}

.order-editor {
    &--title {
        position: relative;
        border-bottom: 1px solid #99b3b3;
        color: #c4dcdc;
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
    }
    &--main {
        position: relative;
        margin-top: 5px;
    }
    &--row {
        display: table-row;
        width: 100%;

        &.multi-order-toggle {
            >div {
                border-top: 1px dashed #99b3b3;
            }
        }

        &--name, &--body {
            position: relative;
            display: table-cell;
            padding: 3px 0px;
            white-space: nowrap;
        }

        &--name {
            font-size: 13px;
            font-weight: bold;
            line-height: 25px;
            vertical-align: top;
            width: 72px;
            min-width: 72px;
        }

        &--body {
            width: 100%;
            padding-left: 9px;
            overflow: hidden;

            >input {
                font-size: 15px;
                font-weight: bold;
                width: 100%;
                display: block;
                cursor: text;
                background: #267f80;
                color: white;
                padding: 3px 6px;

                &:disabled {
                    cursor: not-allowed;
                    background: #0a4a4a;
                }
            }

            .toggle {
                height: 25px;

                &--label {
                    line-height: 25px;
                }

                &--button {
                    width: 21px;
                    height: 21px;
                }

                &--track {
                    width: 43px;
                }
            }
        }
    }

    &--unit {
        &--placeholder {
            position: absolute;
            line-height: 31px;
            font-size: 13px;
            top: 0px;
            margin-left: 6px;
            font-weight: bold;
            color: #acdcdc;
            cursor: text;
        }
    }

    &--sides {
        &--selector {
            width: 50%;
            font-size: 14px;
            font-weight: bold;
            background: none;

            &.BUY {
                border: 1px solid #8fe6e8;
                border-right: none;
                color: #8fe6e8;

                &.active {
                    background: #8fe6e8;
                    color: #005052;
                }
            }

            &.SELL {
                border: 1px solid #fd818a;
                border-left: none;
                color: #fd818a;

                &.active {
                    background: #fd818a;
                    color: #630d13;
                }
            }
        }

        &--type-selectors {
            position: relative;
            margin-top: 3px;
        }

        &--type-selector {
            width: 50%;
            font-size: 14px;
            font-weight: bold;
            background: none;
            border: 1px solid #608e8e;
            color: whitesmoke;

            &:first-child {
                border-right: none;
            }

            &.active {
                background: #608e8e;
            }
        }
    }

    &--account, &--symbol {
        .search-select {
            &--current-option {
                background: #267f80;
                font-size: 15px;
                font-weight: bold;
                padding: 3px 6px;
                
                &.not-clickable {
                    background: #1b6a6b;
                }

                &--name {
                    display: block;
                    overflow: hidden;
                }
            }
            &--search-input {
                width: 100%;
            }
            &--input-icon {
                right: 6px;
            }
            &--options-wrapper {
                position: fixed;
            }
        }
    }

    &--message, &--order-to-send-size {
        display: flex;
        align-items: flex-start;
        font-weight: bold;
        background: rgb(72, 111, 112);
        padding: 4px 8px;
        border-radius: 2px;
        text-align: center;
        margin-top: 7px;

        >span {
            width: 100%;
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            user-select: text;
            cursor: text;
        }

        &--close-button {
            cursor: pointer;
            background: none;
            color: #ddd;
            font-size: 18px;
            height: 20px;
            padding: 0;
            margin: 0;

            &:hover {
                color: white;
            }
        }

        &--discard-button {
            background: #ddd;
            color: #0a4a49;
            border-radius: 2px;
            font-size: 14px;
            font-weight: bold;
            margin: 0;

            &:hover {
                background: #e39898;
                color: #581918;
            }
        }
    }

    &--download-logs-button {
        padding: 0;
        background: none;
        margin: 0;
        margin-left: 5px;
        text-underline-offset: 1px;
        text-decoration: underline;
        font-size: 14px;
        color: #ddd;
        font-weight: bold;

        &:hover {
            color: white;
        }
    }

    &--order-to-send-size {
        &--text {
            width: 100%;
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            user-select: text;
            margin-right: 5px;
            cursor: text;

            .warning-yellow {
                color: #e5ba4d;
            }
            .warning-red {
                color: #ff8a92;
            }
        }
    }

    &--submit-button {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        border-radius: 2px;
        margin-top: 9px;
        padding: 7px 0px;
        background: #5d8cb5;
        color: #ddd;

        &:hover {
            color: white;
        }

        &:disabled {
            background: slategray;
            color: #ddd;
        }
    }

    &--multiple-accounts {
        position: relative;
        display: flex;
        flex-direction: column;
        background: #043131;
        padding: 0px 0px;
        box-shadow: 0px 0px 3px black;
        font-weight: bold;
        overflow: hidden;

        &--trigger {
            position: relative;
            width: 100%;
            height: 25px;
            padding: 0;
            margin-top: 6px;
            font-weight: bold;
            font-size: 14px;
            background: #1b6c6d;
            color: #eee;
            border: 1px solid #267f80;
            border-radius: 3px;

            &:hover {
                color: white;
                background: #267f80;
            }
        }

        &--header {
            background: #0a4848;
            padding: 4px 9px;
            font-size: 14px;
            line-height: 20px;
            height: 20px;
            min-height: 20px;
        }

        &--main {
            position: relative;
            padding: 10px 16px;
            height: 100%;
            overflow: auto;
        }

        &--config-row {
            line-height: 24px;
            margin: 5px 0px;

            >label {
                font-size: 16px;
                float: left;
                display: none;
            }

            &.accounts {
                display: inline-flex;
            }
        }

        &--variable-input {
            float: left;
            margin-left: 21px;
            background: #3f7575;
            color: white;
            width: 60px;
            font-weight: bold;
            font-size: 16px;
            height: 24px;
            padding: 0px 6px;
            cursor: text;
        }

        &--save-variable-button {
            float: right;
            height: 24px;
            margin-left: 7px;
            padding: 0px 9px;
            font-weight: bold;
            background: steelblue;
            color: #ddd;
            line-height: 25px;

            &:hover {
                color: white;
            }

            &:disabled {
                background: darkslategray;
                color: #ddd;
            }
        }

        &--toggle-all {
            margin-bottom: 7px;
            >button {
                margin-top: 2px;
                padding: 0px 11px;
                height: 24px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 3px;
                border: 1px solid #7eafaf;
                background: transparent;
                color: #7eafaf;

                &:hover {
                    background: #094242;
                }
            }
        }

        &--portfolio {
            position: relative;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            &--header {
                border-bottom: 1px solid #517070;
                padding: 5px 0px;
                line-height: 20px;
                font-size: 14px;

                >label {
                    font-size: 16px;
                    color: white;
                }

                >button {
                    padding: 0px 7px;
                    height: 24px;
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 3px;
                    border: 1px solid #7eafaf;
                    background: transparent;
                    color: #7eafaf;
                    margin-left: 10px;
    
                    &:hover {
                        background: #094242;
                    }
                }
            }

            &--main {
                position: relative;
                margin-top: 5px;
            }
        }

        &--items {
            position: relative;
            float: left;
            width: 500px;
            font-size: 14px;
            line-height: 23px;
        }

        &--item {
            position: relative;
            display: inline-flex;
            line-height: 24px;
            margin-right: 21px;

            >span {
                &.black-listed {
                    text-decoration: line-through;
                    text-decoration-color: #fd818a;
                    text-decoration-thickness: 2px;
                }
            }

            .checkbox {
                width: 20px;
                height: 20px;
                transform: translateY(2px);
                margin-left: 7px;
            }
        }
    }
}

.switch-controller {
    &--body {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: auto;
        max-width: 100%;
        min-width: 500px;
        height: 95%;
        color: white;
        background: #094a4a;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: 0px 0px 7px #021313;
        overflow: visible;
    }

    &--header {
        position: relative;
        padding: 9px 20px;
        border-bottom: 1px solid #90b3b3;    

        &--title {
            position: relative;
            float: left;
            font-size: 19px;
            font-weight: bold;
            color: #eee;
        }
    
        &--close-button {
            background: none;
            font-size: 18px;
            position: absolute;
            right: 13px;
            color: #ddd;
            top: 11px;
    
            &:hover {
                color: white;
            }
        }

        &--action-types {
            float: left;
            margin-left: 20px;
        }

        &--action-type {
            font-size: 14px;
            font-weight: bold;
            width: 110px;
            padding: 2px 0px;
            background: none;
            border: 1px solid #84b7b7;
            color: #84b7b7;

            &:hover {
                color: white;
            }

            &.selected {
                background: #84b7b7;
                color: #032323;
            }
        }
    }

    &--main {
        position: relative;
        overflow: hidden;
        height: 100%;
    }

    &--table {
        .ReactVirtualized__Table__headerRow {
            background: #032f2f;
            overflow: visible !important;
            &:hover {
                background: #032f2f;
            }
        }
        .ReactVirtualized__Table__row {
            align-items: flex-start;
        }

        &--no-content {
            position: relative;
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #a9cccb;
        }

        &--header {
            text-transform: none;
            display: flex;
            font-size: 13px;

            >span {
                color: #ddd;
                &.sortable {
                    cursor: pointer;
                    &.sorted {
                        color: #e2cb6a;
                    }
                }
            }

            >input {
                width: 100%;
                cursor: text;
                margin-left: 6px;
                padding: 0px 6px;
                background: none;
                border-left: 1px solid #90b3b2;
                font-size: 13px;
                color: white;
                font-weight: bold;
            }
        }

        &--row {
            &:nth-child(n+2) {
                border-top: 1px solid #032f30;
            }
            &:hover {
                background: #083e3d;
                .switch-controller--table--batch-operation {
                    opacity: 1.0;
                }
            }
        }

        &--account-name, &--symbol-name {
            line-height: 51px;
            font-size: 15px;
            font-weight: bold;
            color: white;
            &.has-open-close {
                line-height: 97px;
            }
        }

        &--profile {
            display: table-row;
            &:not(:last-child) >div {
                border-bottom: 1px dashed #022f2f;
            }
            &:first-child >div{
                padding-top: 4px;
            }
            &:last-child >div{
                padding-bottom: 4px;
            }
            &--name, &--sides {
                display: table-cell;
                vertical-align: middle;
            }
            &--name {
                font-size: 13px;
                padding-right: 11px;
                color: #cde8e7;
                width: 100%;
            }
            &--sides {
                padding: 1px 0px;
                font-weight: bold;
            }
        }

        &--batch-operation {
            padding: 4px 0px;
            opacity: 0;
        }
    }

    &--symbol-type-select {
        padding-left: 7px;
        border-left: 1px solid #90b3b2;
        margin-left: 7px;

        &.QUOTE .search-select--current-option--name {
            color: #87d0ec;
        }

        &.HEDGE .search-select--current-option--name {
            color: #87e287;
        }

        .search-select {
            &--current-option {
                padding-right: 19px;
            }
            &--input-icon {
                right: 0px;
                opacity: 0.8;
            }
            &--option {
                line-height: 25px;
            }
        }
    }

    &--profile-side-switch {
        display: flex;

        &--direction {
            font-size: 12px;
            width: 30px;
            line-height: 23px;
            &.has-open-close {
                line-height: 45px;
            }
            &.BUY {
                color: #99ddde;
            }
            &.SELL {
                color: #ce757c;
            }
        }
    }

    &--batch-switch {
        display: table-row;
        &--type, &--buttons {
            display: table-cell;
            white-space: nowrap;
            vertical-align: middle;
            padding: 0px 2px;
            font-weight: bold;

            >button {
                display: block;
                width: 100%;
                min-width: 50px;
                font-size: 13px;
                margin: 2px;
                font-weight: bold;
                height: 19px;
                padding: 0px 3px;
                line-height: 17px;
            }

            &.BUY {
                >button {
                    background: transparent;
                    border: 1px solid #68bbbd;
                    color: #8fe6e8;

                    &:hover {
                        background: #68bbbd;
                        color: #0a4a49;
                    }
                }
            }

            &.SELL {
                >button {
                    background: transparent;
                    border: 1px solid #da7880;
                    color: #cb737a;

                    &:hover {
                        background: #da7880;
                        color: #0a4a49;
                    }
                }
            }
        }
        &--type {
            font-size: 12px;
            &.ON {
                color: #81b0da;
            }
            &.OFF {
                color: #d29cd2;
            }
        }

        &:first-child {
            >div {
                border-bottom: 1px dashed #2f6b6b;
            }
        }
    }

    &--batch-operation-popup {
        background: #032f2fdb;
        border-radius: 2px;
        margin-top: 3px;
        box-shadow: 0px 0px 3px black;
        padding: 0px 7px 7px;

        &--title {
            line-height: 25px;
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #336363;
            margin-bottom: 5px;
        }

        &--trigger {
            color: #bbd6d6;
            background: transparent;
            border: 1px solid;
            border-radius: 2px;
            padding: 0px 7px;
            font-weight: bold;

            &:hover {
                background: #90b3b3;
                color: #032f2f;
                border-color: #90b3b3;
            }
        }
    }
}

.issue-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        &--tabs {
            height: 32px;
            width: 100%;;
        }
        &--tab {
            width: 50%;
            height: 100%;
            font-size: 13px;
            font-weight: bold;
            background: #0a4a4a;
            color: #eee;

            &:hover {
                color: white;
            }

            &.active {
                background: #0f6160;
                color: white;
            }
        }
        &--search-input {
            position: relative;
            display: block;
            width: calc(100% - 18px);
            padding: 0px 9px;
            background: #023534;
            height: 29px;
            font-size: 12px;
            font-weight: bold;
            color: #eee;
            cursor: text;
        }
    }

    &--body {
        position: absolute;
        top: 59px;
        bottom: 0px;
        width: 100%;
        overflow: auto;
    }

    &--item {
        position: relative;
        border-bottom: 1px solid #002928;
        padding: 7px 9px;

        &:hover {
            background: #064443;
            .issue-container--item--footer{
                visibility: visible;
            }
        }

        &--header {
            line-height: 17px;

            &--tag {
                float: left;
                font-size: 13px;
                font-weight: bold;
                padding: 1px 5px;
                border-radius: 1px;
                color: #4dbbbb;
                border: 1px solid;

                &.SWITCH_OFF {
                    color: #db7378;
                }
                &.PROFILE_CRASH {
                    color: orange;
                }
                &.QTY_CAPPED {
                    color: #F3D076;
                }
                &.SMART_ACCT_EXC {
                    color: #e8b0e8;
                }
            }
            &--timestamp {
                float: right;
                font-size: 12px;
                font-weight: bold;
                color: #9eb7b7;
                line-height: 21px;
            }
        }

        &--body {
            margin-top: 5px;
            font-size: 14px;
        }

        &--row {
            display: flex;
            font-size: 14px;
            line-height: 19px;

            &--name {
                opacity: 0.7;
            }

            &--component {
                width: 100%;
                text-align: right;
                color: #ddd;
            }
        }

        &--profile {
            color: white;
            font-weight: bold;
            cursor: pointer;
            max-width: 180px;
            overflow: hidden;
            float: right;
            text-overflow: ellipsis;
            
            &:hover {
                text-decoration: underline;
            }
        }

        &--side {
            font-weight: bold;
            &.BUY, &.BUY_OPEN, &.BUY_CLOSE {
                color: #99ddde;
            }

            &.SELL, &.SELL_OPEN, &.SELL_CLOSE {
                color: #fd818a;
            }
        }

        &--message {
            margin-top: 5px;
            border-top: 1px dashed #74a5a4;
            padding-top: 3px;
            color: #db7378;
            font-weight: bold;
        }

        &--footer {
            margin-top: 5px;
            visibility: hidden;

            &--button {
                float: right;
                margin-left: 5px;
                border: 1px solid;
                font-weight: bold;
                font-size: 13px;
                background: transparent;
                padding: 1px 6px;
                border-radius: 1px;

                &.solved {
                    color: #7ba6ca;
                    &:hover {
                        background: #517796;
                        color: white;
                        border-color: #7ba6ca;
                    }
                }

                &.ignore, &.cancel-ignore {
                    color: #bbb;

                    &:hover {
                        background: dimgray;
                        border-color: #bbb;
                        color: white;
                    }
                }
            }
        }
    }
}

.auto-transfer-popup {
    background: #125a5a;
    top: 45px;
    box-shadow: 0px 0px 3px black;
    border-radius: 2px;
    overflow: hidden;

    &--trigger {
        height: 29px;
        position: relative;
        background: none;
        color: #71a7a7;
        font-size: 19px;

        &:hover {
            color: #85b9b9;
        }
    }

    &--header {
        background: #0b4040;
    }

    &--title {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.8em;
        text-indent: 9px;
    }

    &--main {
        position: relative;
        width: 320px;
        height: 500px;
    }

    &--empty {
        position: relative;
        width: 320px;
        text-align: center;
        line-height: 2.0em;
        font-size: 14px;
        font-weight: bold;
    }

    &--item {
        cursor: pointer;
        &:hover {
            background: #0d6565;
        }
        &--main {
            padding: 5px 9px;
            border-bottom: 1px solid #0c4040;
        }
    }

    &--data-row {
        position: relative;
        width: 100%;
        display: flex;
        font-size: 14px;
        line-height: 1.5em;
        white-space: nowrap;

        &--component {
            width: 100%;
            text-align: right;
            font-weight: bold;
        }
    }

    &--transfer-account {
        &--main {
            >span {
                margin-left: 7px;
            }
        }

        &--market {
            padding: 0px 5px;
            font-size: 13px;
            border-radius: 2px;

            &.SPOT {
                background: #7badd8;
                color: #284d6d;
            }
            &.MARGIN {
                background: #d5ca7d;
                color: #564e17;
            }
            &.FUTURE {
                background: #c57693;
                color: #6b223b;
            }
            &.SWAP {
                background: #95d6a1;
                color: #224c2a;
            }
        }

        &--detail {
            border-top: 1px solid #598888;
            margin: 3px 0px 7px 15px;
            padding: 1px 0px;

            &--data {
                font-size: 13px;
            }
        }
    }

    &--fetching {
        position: absolute;
        bottom: 0px;
        width: 100%;
        font-size: 13px;
        text-align: center;
        background: #052828d6;
        font-weight: bold;
        line-height: 2em;
        box-shadow: 0px 0px 3px black;
    }
}

.trade-window {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;

    &--left, &--center {
        position: relative;
        min-width: 280px;
        width: 280px;
        height: 100%;
        border-right: 1px solid #043131;
        overflow-y: auto;
    }

    &--center {
        min-width: 310px;
        width: 310px;
    }

    &--right {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &--order-editor, &--order-book, &--account-balance, &--position {
        &--title {
            position: relative;
            font-size: 14px;
            font-weight: bold;
            background: #043131;
            line-height: 1.9em;
            text-indent: 9px;
        }

        &--main {
            padding-bottom: 11px;
        }
    }

    &--order-editor, &--order-book {
        &--main {
            padding: 0px 9px 11px;
        }

        &--title {
            &--connection {
                position: absolute;
                height: 100%;
                right: 0;
                top: 0;
            }

            .socket-connection {
                line-height: unset;
                margin-right: 11px;
            }
        }
    }

    &--account-balance, &--position {
        &--main {
            padding-bottom: 11px;
            position: relative;
            max-height: 100%;
            overflow: auto;
        }
    }

    &--account-balance {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 50%;
        .account-balance {
            &:not(:last-child) {
                position: relative;
                border-bottom: 1px solid #063131;
                padding-bottom: 7px;
            }

            &--empty-message {
                font-size: 15px;
                font-weight: bold;
                margin: 5px 0px;
            }
            &--spot-item, &--margin--item,&--cross-margin--item, &--cross--item, &--future--item, &--swap--item {
                margin-bottom: 0px;
            }
        }
        .account-asset-item {
            padding: 0px 9px;
        }
        .cross-margin-account-balance-item {
            &--lending {
                margin: 8px 5px 20px;
            }
            .search-select--search-input {
                width: 100%;
            }
            .token-lending-editor--inputs input {
                width: 100%;
            }
        }
    }

    &--position {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 50%;

        .position-item {
            &:not(:last-child) {
                position: relative;
                border-bottom: 1px solid #063131;
                padding-bottom: 7px;
            }
        }
    }

    &--empty-data {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        margin: 5px 0px;
    }
}

.manual-order-container {
    position: relative;
    width: 100%;
    height: 100%;

    &--header {
        position: relative;
        display: flex;
        height: 31px;
        font-weight: bold;

        &--filter {
            display: flex;
            height: 100%;
            line-height: 31px;
            white-space: nowrap;
            margin: 0px 5px 0px 9px;
            font-size: 14px;

            >span {
                opacity: 0.8;
            }

            .search-select {
                margin-left: 7px;

                &--current-option {
                    padding-right: 31px;
                }

                &--search-input {
                    width: 100%;
                }

                &--input-icon {
                    opacity: 0.7;
                }

                &--clear-button {
                    background: transparent;
                    >svg {
                        margin-top: 0px;
                    }
                }
            }
        }

        &--reset-button {
            position: relative;
            height: 21px;
            top: 50%;
            transform: translateY(-50%);
            padding: 0px 8px;
            font-weight: bold;
            border-radius: 2px;
            background: #6aa5a4;
            color: #063131;

            &:hover {
                color: white;
                background: #568e8d;
            }
        }
    }

    &--main {
        position: absolute;
        width: 100%;
        top: 31px;
        bottom: 0;
    }

    &--open-orders, &--draggable-line, &--transactions {
        position: absolute;
        width: 100%;
    }

    &--open-orders {
        top: 0;
        height: 50%;
    }

    &--draggable-line {
        height: 6px;
        top: 50%;
        transform: translateY(-3px);
        z-index: 2;
        background: transparent;
        cursor: ns-resize;
    }

    &--transactions {
        top: 50%;
        bottom: 0;
    }
}

.manual-order-table {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
        height: 11px;
    }

    &--header {
        position: relative;
        display: flex;
        background: #043131;
        white-space: nowrap;
        &--title {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.9em;
            text-indent: 9px;
        }

        &--order-stats {
            font-size: 14px;
            line-height: 1.9em;
            margin-left: 11px;
            overflow: hidden;
            color: #86bfbf;
            font-weight: bold;
            display: flex;

            &--section {
                &:not(:last-child) {
                    margin-right: 11px;
                }
                >span {
                    color: #ddd;
                }
            }
        }

        &--right-section {
            position: absolute;
            right: 9px;
            height: 100%;
        }
        
        &--cancel-all-button {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            font-size: 14px;
            font-weight: bold;
            padding: 0px 5px;
            background: #936798;
            color: #ddd;
            border-radius: 2px;

            &:hover {
                color: white;
            }
        }

        &--is-fetching {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.9em;
            margin-left: 11px;
            color: #86c7c7;
        }
    }

    &--main {
        position: relative;
        width: 100%;
        height: 100%;
        padding-right: 3px;
        overflow: auto hidden;
    }

    &--body {
        .ReactVirtualized__Table__headerRow {
            font-size: 13px;
            font-weight: bold;
            white-space: nowrap;
            background: #0f5f5f !important;
            color: #d1e4e4;
        }
        .ReactVirtualized__Table__rowColumn {
            font-size: 14px;
            font-weight: bold;
            color: #c0d6d6;
        }
        &--header {
            &.sortable {
                &:hover {
                    color: white;
                }
                &.sorted {
                    color: #e2cc6a;
                }
            }
        }
        &--row {
            @keyframes showNewOddRow {
                0% {
                    background: #457f81;
                }
                100% {
                    background: transparent;
                }
            }
            @keyframes showNewEvenRow {
                0% {
                    background: #457f81;
                }
                100% {
                    background: #0a4241;
                }
            }
            &.odd-row {
                &.new-item {
                    animation: 0.6s showNewOddRow;
                }
            }

            &.even-row {
                background: #0a4241;
                &.new-item {
                    animation: 0.6s showNewEvenRow;
                }
            }
            &:hover {
                background: #063535;
            }
        }
        &--id {
            font-size: 13px !important;
            user-select: text;
        }

        .right-align {
            text-align: right;
        }
    }

    &--order-checkbox, &--all-orders-checkbox {
        .checkbox {
            width: 19px;
            height: 19px;
            >svg {
                font-size: 13px;
            }
        }
    }
    &--all-orders-checkbox {
        .checkbox {
            border-radius: 0px;
            background: #0a4a4a;
        }
    }

    &--symbol, &--account {
        cursor: pointer;
        &:hover {
            color: white;
        }
    }

    &--price, &--qty {
        >span {
            color: #e2cb6a;
            display: block;
            line-height: 17px;
        }
    }

    &--filters {
        position: relative;
        display: flex;
    }

    &--fill-only-filter, &--this-week-only-filter  {
        display: flex;
        font-size: 14px;
        line-height: 1.9em;
        font-weight: bold;
        color: rgb(166, 195, 195);
        float: right;

        .checkbox {
            margin-left: 5px;
            width: 17px;
            height: 17px;
            transform: translateY(5px);
            position: relative;
        }
    }

    &--this-week-only-filter {
        margin-left: 12px;
    }

    &--features {
        position: relative;
        padding: 3px 0px;
    }

    &--tag {
        font-size: 13px;
        text-align: center;
        padding: 0px 4px;
        border-radius: 1px;
        border-radius: 2px;
        display: inline-block;

        &:not(:first-child) {
            margin-top: 2px;
        }

        &.post-only {
            background: #5d8cb5;
            color: #1b4463;
        }

        &.leverage {
            background: #936798;
        }

        &.reduce-only, &.cross-margin, &.margin-trade {
            background: #ecb145;
            color: #422b00;
        }

        &.margin-buy {
            background: #b9838c;
            color: #300e13;
        }
        &.auto-repay {
            background: #6eb3b3;
            color: #194646;
        }

        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    &--side {
        &.BUY, &.BUY_OPEN, &.BUY_CLOSE {
            color: #5dd4d2;
        }
        &.SELL, &.SELL_OPEN, &.SELL_CLOSE {
            color: #fd818a;
        }
    }

    &--reject-reason {
        color: #ecb145 !important;
        max-width: 250px !important;
        white-space: normal !important;
        line-height: 17px;
        padding: 3px 0px;
        word-break: break-word;
    }

    &--status {
        &.SENT_TO_PF, &.CANCELLED, &.PENDING_CANCEL {
            color: #e2cb6a;
        }
        &.CONFIRM {
            color: #87ce93;
        }
        &.FILL, &.PARTIAL_FILL {
            color: #a6cff3;
        }
        &.REJECTED, &.EXPIRED {
            color: #fd818a;
        }

        >span {
            color: #c0d5d6;
            display: block;
            font-size: 12px;
            line-height: 18px;
        }
    }

    &--cancel-button {
        border-radius: 2px;
        padding: 1px 6px;
        background: #936798;
        color: #ddd;
        font-weight: bold;

        &:hover {
            color: white;
        }
    }

    &--csv-download-popup {
        &--trigger {
            position: relative;
            margin-right: 11px;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            display: block;
            float: left;
            background: none;
            color: #6d9a9a;
            font-size: 18px;
            padding: 0; 

            &:hover {
                color: white;
            }
        }
    }

    &--file-download {
        button {
            font-size: 14px;
            font-weight: bold;
            background: #6aa5a4;
            color: #043131;
            padding: 4px 11px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;

            &:hover {
                color: white;
            }
        }
    }

    &--end-timestamp-popup {
        display: flex;
        flex-direction: column;
        padding: 9px;
        background: #043131;
        border-radius: 3px;
        box-shadow: 0px 0px 3px black;

        &--trigger {
            font-size: 14px;
            font-weight: bold;
            line-height: 26px;
            margin-left: 11px;
            margin-right: 5px;
            cursor: pointer;

            >label {
                opacity: 0.7;
                margin-right: 5px;
            }
            >span {
                text-decoration: underline dotted;
            }
        }

        &--input {
            margin: 9px 0px 5px;
            background: none;
            color: white;
            border-bottom: 1px solid #6aa5a4;
            padding: 5px 0px;
            font-weight: bold;
            font-size: 14px;
            width: 200px;
        }

        &--now-button {
            font-size: 14px;
            font-weight: bold;
            padding: 5px 0px;
            border-radius: 2px;
            background: #105f5f;
            color: #eee;

            &:hover {
                background: #1b7c7c;
                color: white;
            }
        }

        &--confirm-button {
            font-size: 14px;
            font-weight: bold;
            padding: 5px 0px;
            border-radius: 2px;
            background: #5d8cb5;
            color: #eee;

            &:hover {
                background: #6e9ec8;
                color: white;
            }
        }
    }
}

.position-table {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ::-webkit-scrollbar {
        height: 10px;
        background-color: #053333;
        border-radius: 5px;
        overflow: hidden;
    }

    .positive {
        color: #86e2e0;
    }
    .negative {
        color: #fd818a;
    }

    &--header {
        position: relative;
        display: flex;
        width: 100%;
        height: 35px;
        min-height: 35px;
        line-height: 35px;
        background: #042f2e;
        font-weight: bold;
        z-index: 2;

        &--title {
            font-size: 19px;
            margin-left: 13px;
        }

        &--criteria-groups {
            position: relative;
            margin-left: 17px;
            display: flex;
        }

        &--criteria-item {
            display: flex;
            white-space: nowrap;
            margin-right: 15px;
            >span {
                opacity: 0.8;
                margin-right: 7px;
                font-size: 14px;
            }
            .search-select {
                &--current-option {
                    padding-right: 21px;
                }
                &--input-icon {
                    right: 0px;
                    opacity: 0.7;
                }
            }
        }

        &--toggle-group-button, &--reset-button  {
            background: #073e3d;
            color: #ddd;
            font-weight: bold;
            padding: 0px 13px;
            font-size: 14px;
            white-space: nowrap;
            border-right: 1px solid #0a4a4a;
            >svg {
                display: inline-block;
                font-size: 15px;
                margin-left: 3px;
                transform: translateY(3px);
            }

            &:hover {
                background: #054e4d;
                color: white;
            }
        }

        &--toggle-group-button {
            background: #0f5755;
            width: 115px;
            min-width: 115px;
            padding: 0px;
        }

        &--search-input {
            display: block;
            width: 100%;
            background: transparent;
            text-indent: 11px;
            font-size: 14px;
            font-weight: bold;
            color: white;
            cursor: text;
        }
    }

    &--overall-stats {
        position: relative;
        margin-left: 13px;
        padding: 5px 0px 10px;
        overflow: auto;
    }

    &--position-stats {
        position: relative;
        font-weight: bold;
        display: flex;
        line-height: 24px;
        align-items: center;

        &--item {
            margin-right: 20px;
            white-space: nowrap;
            >label {
                font-size: 14px;
                opacity: 0.7;
                line-height: 20px;
            }
            >div {
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
            }
            .clickable {
                text-decoration: underline;
                text-underline-offset: 1px;
                cursor: pointer;
            }
        }

        &--greeks {
            display: flex;
            align-items: center;
        }

        &--popup {
            background: #052a29f5;
            padding: 0px 10px;
            box-shadow: 0px 0px 3px black;
            border-radius: 3px;

            &--main {
                display: flex;
                flex-direction: column;
            }

            &--item {
                display: flex;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                padding: 8px 0px;
                
                >label {
                    width: 100%;
                    padding-right: 10px;
                }
                >div {
                    text-align: right;
                }

                &:not(:first-child) {
                    border-top: 1px solid #021918;
                }

                &--price {
                    font-size: 12px;
                    line-height: 18px;
                    white-space: nowrap;
                    opacity: 0.7;
                    display: block;
                }
            }

            &--side-split {
                font-size: 13px;
                line-height: 18px;
                white-space: nowrap;

                >label {
                    padding-right: 10px;
                }

                .positive {
                    color: #84fbf9;
                }
    
                .negative {
                    color: #fd818a;
                }
            }

            &--funding-rate {
                color: #ddd;
                margin-left: 10px;
                text-decoration: none;
                cursor: normal;
            }
        }
    }

    &--main {
        position: relative;
        width: calc(100%-3px);
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        padding-right: 3px;
        
        .ReactVirtualized__Table__headerRow {
            background: #081a1b;
    
            &:hover {
                background: #081a1b !important;
            }
        }
    
        .ReactVirtualized__Table__rowColumn, .ReactVirtualized__Table__headerColumn {
            margin: 0px;

            &:first-child {
                margin-left: 13px !important;
            }
        }
    }

    &--table {
        &--header {
            font-size: 13px;
            text-transform: initial;
            display: flex;
            margin-right: 0px !important;
            color: white;
            cursor: default !important;
            line-height: 19px;

            >span {
                text-align: left;
            }

            &.sortable {
                cursor: pointer !important;
            }

            &.sorted {
                color: #e2cb6a;
            }
        }

        &--row {
            color: #ddd;
            &.group-row {
                border-bottom: 2px solid #6b9393;
                border-top: 1px solid #6b9393;
                background: #083c3c;
                cursor: pointer;
                z-index: 2;
                
                &:hover {
                    background: #005454;
                }
    
                >.ReactVirtualized__Table__rowColumn {
                    overflow: visible !important;
                }
            }
            &.position-row {
                font-weight: bold;
                font-size: 14px;
                &.even-row {
                    background: #084242;
                }
            }
        }

        &--funding-amount, &--notional {
            >.currency {
                margin-right: 5px;
            }
        }

        &--funding-amount {
            &--multiplier {
                margin-left: 3px;
            }
        }

        &--greek {
            display: inline-flex;
            flex-direction: column;

            &--item {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 16px;
                white-space: nowrap;

                >label {
                    width: 100%;
                    padding-right: 10px;
                }
            }
        }
    }

    &--position-group-row {
        position: relative;
        &--name {
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 31px;
            font-weight: bold;
            color: white;
            &--size {
                font-size: 16px;
                opacity: 0.5;
                margin-left: 7px;
            }
        }
    }

    &--funding-rate {
        position: relative;
        display: flex;

        &--section {
            &:nth-child(2) {
                margin-left: 5px;
            }
            &--name {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }

    &--no-content {
        position: relative;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #a9cccb;
    }
}

.exposure-adjustment-table {
    &--header {
        position: relative;
        line-height: 27px;
        background: #105b5b;
    }
    &--title {
        font-weight: bold;
        font-size: 14px;
        margin-left: 5px;
    }
    &--add-button {
        position: absolute;
        right: 0px;
        top: 0;
        height: 100%;
        font-size: 17px;
        background: transparent;
        color: #ddd;

        &:hover {
            color: white;
        }
    }
    table {
        border-collapse: collapse;
        thead {
            background: #081a1b;
            th {
                padding: 0px 5px;
                line-height: 27px;
                font-size: 12px;
                text-align: left;
                white-space: nowrap;
            }
        }
        tbody {
            vertical-align: top;
            tr {
                .exposure-adjustment-table--row--button {
                    &.edit, &.delete {
                        opacity: 0;
                        transition: opacity 0.2s;
                    }
                }
                &:hover {
                    .exposure-adjustment-table--row--button {
                        &.edit, &.delete {
                            opacity: 1;
                        }
                    }
                }
            }
            td {
                padding: 3px 5px;
                font-size: 14px;
                font-weight: bold;
                white-space: nowrap;
                color: #ddd;
                border-bottom: 1px dashed #063c3c;

                >input, >textarea {
                    background: rgb(22, 103, 102);
                    font-size: 14px;
                    font-weight: bold;
                    cursor: text;
                    color: #ddd;
                    padding: 3px 7px;
                    border-radius: 1px;

                    &:disabled {
                        background: transparent;
                    }
                }
                >input {
                    width: 66px;
                }
                >textarea {    
                    display: block;
                    max-width: 100%;
                    resize: none;
                }
                .search-select {
                    &--current-option {
                        background: rgb(22, 103, 102);
                        font-weight: bold;
                        border-radius: 1px;
                        padding: 3px 7px;
                        padding-right: 24px;
                    }
                    &--input-icon {
                        opacity: 0.8;
                        right: 5px;
                    }
                }
            }
        }
    }

    &--row {
        &--comment {
            white-space: normal !important;
            max-width: 110px;
        }
        &--buttons {
            white-space: nowrap;
        }
        &--button {
            font-size: 13px;
            font-weight: bold;
            padding: 1px 5px;
            &:not(:first-child) {
                margin-left: 3px;
            }
            &.delete {
                background: palevioletred;
            }
            &.edit, &.create {
                background: #197978;
            }
            &.update {
                background: #5096b3;
            }
            &.cancel {
                background: #8a8a8a;
            }
            color: #ddd;
            &:hover {
                color: white;
            }
        }
    }
}

.exposure-chart {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 100%;

    &--show-button {
        position: fixed;
        right: 0;
        top: 42px;
        font-size: 13px;
        font-weight: bold;
        padding: 7px 11px;
        padding-left: 15px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background: #1b6f6f;
        color: #ddd;
        box-shadow: 0px 0px 5px #042e2e;
        z-index: 4;

        &:hover {
            color: white;
        }
    }

    &--main {
        display: flex;
        flex-direction: column;
    }

    &--header {
        &--title {
            position: relative;
            line-height: 29px;
            font-size: 14px;
            font-weight: bold;
            background: #135b5a;
            text-indent: 5px;
            >span {
                color: #7abdbd;
                margin-left: 7px;
            }
        }
        &--reset-button, &--hide-button, &--expand-button, &--minimize-button {
            position: relative;
            float: right;
            font-weight: bold;
            padding: 0px 9px;
            background: #0b403e;
            height: 29px;
            color: #76afae;

            &:hover {
                color: white;
            }
        }

        &--hide-button {
            background: darkslategray;
            padding: 0px 11px;
            color: #466666;
            color: #ddd;

            &:hover {
                color: white;
            }
        }

        &--expand-button, &--minimize-button {
            background: #062f2d;
            >svg {
                transform: scale(1.3);
            }
        }

        &--minimize-button {
            background: #135b5a;
            color: #ddd;
        }

        &--search-row {
            position: relative;
            display: flex;
            line-height: 27px;
            height: 27px;
        }

        &--search-input {
            position: relative;
            display: block;
            width: 100%;
            cursor: text;
            background: #08403e;
            padding: 0px 5px;
            font-weight: bold;
            color: white;
        }

        &--threshold-input {
            background: #09403e;
            border-left: 2px solid #165b59;
            color: white;
            padding: 0px 5px;
            font-weight: bold;
            width: 85px;
            min-width: 85px;
            cursor: text;
        }

        &--shuffle-color-button {
            white-space: nowrap;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 11px;
            background: #062f2e;
            color: #ddd;
            &:hover {
                color: white;
            }
        }

        &--criteria-row {
            border-bottom: 1px dashed #033838;
            padding: 5px 0px;
        }

        &--exposure-token, &--value-type {
            float: left;
            font-size: 13px;
            font-weight: bold;
            display: flex;
            margin: 0px 5px 0px 5px;

            >label {
                margin-right: 5px;
            }

            &--selectors {
                >button {
                    background: transparent;
                    color: #609e9e;
                    border: 1px solid;
                    white-space: nowrap;
                    padding: 0px 9px;
                    font-weight: bold;
                    font-size: 13px;

                    &:not(:first-child) {
                        border-left: none;
                    }

                    &:hover {
                        background: #095251;
                    }
                    &.selected {
                        background: #609e9e;
                        border-color: #609e9e;
                        color: white;
                    }
                }
            }
        }
    }

    &--wrapper {
        position: relative;
        border-bottom: 1px dashed #082f2e;
    }

    &--tooltip {
        position: relative;
        font-size: 13px;
        padding: 5px 11px;
        height: 100%;
        overflow-y: auto;

        &--timestamp {
            font-size: 14px;
            font-weight: bold;
            text-align: center;
        }

        &--data {
            position: relative;
            margin-top: 5px;
        }

        &--item {
            position: relative;
            display: inline-block;
            margin-right: 11px;
            margin-bottom: 3px;

            &--dot {
                width: 9px;
                height: 9px;
                display: block;
                float: left;
                border-radius: 10px;
                margin-top: 4px;
                box-shadow: 0px 0px 3px #043333;
            }

            &--name {
                margin-left: 5px;
            }

            &--value {
                margin-left: 5px;
                font-weight: bold;
                color: white;
            }
        }
    }

    &--selected-snapshot {
        z-index: 100;
        transform: translate(-100%, 100%);
        background: #081b1cc7;
        padding: 5px 9px 9px;

        &--title {
            font-size: 13px;
            font-weight: bold;
            white-space: nowrap;
        }

        &--buttons {
            display: flex;
            flex-direction: column;

            >button {
                margin-top: 5px;
                font-size: 14px;
                font-weight: bold;
                padding: 4px 0px;
                background: #165b59;
                color: #ddd;

                &:hover {
                    background: #227370;
                    color: white;
                }
            }
        }
    }
}

.bulk-transfer-window {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &--header {
        position: relative;
        padding: 0px 11px;
        width: calc(100% - 22px);
        height: 35px;
        min-height: 35px;
        line-height: 35px;
        background: #042f2e;
        z-index: 2;
    }

    &--title {
        font-size: 16px;
        font-weight: bold;
    }

    &--main {
        height: calc(100% - 46px);
        padding-top: 11px;
    }

    .search-select {
        &--search-input {
            width: 100%;
        }
    }
}

.value-at-risk {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ::-webkit-scrollbar {
        height: 10px;
        background-color: #053333;
        border-radius: 5px;
        overflow: hidden;
    }

    .positive {
        color: #86e2e0;
    }
    .negative {
        color: #fd818a;
    }

    .right-align {
        text-align: right !important;
    }

    &--header {
        position: relative;
        display: flex;
        width: 100%;
        height: 35px;
        min-height: 35px;
        line-height: 35px;
        background: #042f2e;
        font-weight: bold;
        z-index: 2;

        &--title {
            font-size: 19px;
            margin-left: 13px;
            white-space: nowrap;
        }

        &--filters {
            display: flex;
            margin-left: 17px;
            width: 100%;

            >input {
                display: block;
                width: 100%;
                background: transparent;
                text-indent: 11px;
                font-size: 14px;
                font-weight: bold;
                color: white;
                cursor: text;
                border-left: 1px solid #0a4a4a;
            }
        }

        &--filter {
            display: flex;
            white-space: nowrap;
            margin-right: 15px;
            >label {
                opacity: 0.8;
                margin-right: 7px;
                font-size: 14px;
            }
            .search-select {
                &--current-option {
                    padding-right: 21px;
                }
                &--input-icon {
                    right: 0px;
                    opacity: 0.7;
                }
            }
        }
    }

    &--main {
        position: relative;
        height: 100%;
        overflow: auto hidden;
        padding-right: 3px;
        width: calc(100% - 3px);

        .ReactVirtualized__Table__headerRow {
            background: #081a1b !important;
    
            &:hover {
                background: #081a1b !important;
            }
        }
    
        .ReactVirtualized__Table__rowColumn, .ReactVirtualized__Table__headerColumn {
            margin: 0px;

            &:first-child {
                margin-left: 13px !important;
            }
        }
    }

    &--table {
        padding-right: 10px;
        &--header {
            font-size: 13px;
            text-transform: initial;
            display: flex;
            margin-right: 0px !important;
            color: white;
            cursor: default !important;
            line-height: 19px;

            >span {
                text-align: left;
            }

            &.right-align {
                >span {
                    text-align: right;
                    margin-left: auto;
                }
            }

            &.sortable {
                cursor: pointer !important;
            }

            &.sorted {
                color: #e2cb6a;
            }
        }

        &--row {
            color: #ddd;
            font-weight: bold;
            font-size: 14px;
            &.even-row {
                background: #084242;
            }
        }

        &--var-ratio-cell {
            padding-right: 10px;
        }

        &--no-content {
            position: relative;
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #a9cccb;
        }
    }

    &--balance-popup {
        background: #000808d1;
        border-radius: 3px;
        padding: 5px 10px;
        box-shadow: 0px 0px 3px black;
        overflow: auto;

        &--trigger {
            text-decoration: underline dotted;
            cursor: pointer;
            &:hover {
                color: white;
            }
        }

        &--timestamp {
            font-size: 14px;
            font-weight: bold;

            >label {
                opacity: 0.65;
            }
        }

        table {
            position: relative;
            margin-top: 5px;
            border-collapse: collapse;
            font-weight: bold;

            thead {
                td {
                    font-size: 13px;
                    border-bottom: 1px solid #6a8787;
                    line-height: 20px;
                    opacity: 0.8;

                    &:not(:last-child) {
                        padding-right: 15px;
                    }

                    &.right-align {
                        text-align: right;
                    }
                }
            }
            tbody {
                td {
                    font-size: 15px;
                    line-height: 24px;

                    &:not(:last-child) {
                        padding-right: 15px;
                    }

                    &.right-align {
                        text-align: right;
                    }
                }
            }

        }
    }
}