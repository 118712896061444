@import '../../styles/responsive.scss';

.footer {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #002727;

    &--title {
        font-family: 'Oswald', sans-serif;
        font-size: 14px;
        color: #eee;
        text-indent: 13px;
        margin-top: -2px;
    }

    &--username {
        position: absolute;
        left: 131px;
        font-size: 12px;
        font-weight: bold;
        color: #b1c5c5;
    }

    &--clock {
        position: absolute;
        right: 11px;
        height: 100%;
        top: 0;
        line-height: 28px;
        font-size: 14px;
        font-weight: bold;
        color: #bccfcf;
    }

    &--connections {
        position: absolute;
        top: 0;
        height: 100%;
        right: 61px;
        display: flex;

        &--offline-label {
            font-size: 12px;
            margin-right: 20px;
            color: #9db3b3;
            line-height: 28px;
        }

        &--disconnected-wrapper {
            position: fixed;
            bottom: 36px;
            z-index: 2;
            background: #012928eb;
            right: 9px;
            padding: 5px 38px 5px 10px;
            box-shadow: 0px 0px 3px #001d1c;
            border-radius: 2px;
            color: white;
            font-size: 14px;
            line-height: 21px;

            &--close-button {
                position: absolute;
                right: 1px;
                background: none;
                color: #ddd;
                top: 6px;
                font-size: 18px;
                opacity: 0.8;

                &:hover {
                    opacity: 1.0;
                }
            }
        }
    }

    &--node-websocket {
        margin-right: 21px;
    }
}

.menu {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: inline-flex;

    &--side-bar {
        position: relative;
        height: 100%;
        text-align: center;
        background: #012b2b;
        width: 49px;
        display: flex;
        flex-direction: column;

        &--button {
            position: relative;
            font-size: 25px;
            background: none;
            color: #92c9ca;
            opacity: 0.7;
            margin-top: 15px;
            padding: 0px;
            height: 30px;

            &:hover, &.active {
                opacity: 1.0;
            }

            &:first-child {
                margin-top: 19px;
            }

            &.trading {
                display: none;
                @include screen-size('super-large') {
                    display: block;
                }
            }

            &.pricing {
                margin-top: 24px;
            }

            &.fundingRate {
                margin-top: 12px;
            }

            &.defiLending {
                margin-top: 12px;
                font-size: 26px;
            }

            &.market {
                font-size: 24px;
            }

            &.tokenTransfer {
                font-size: 27px;
            }

            &.timer {
                font-size: 28px;
                margin-top: 15px;
            }

            &.profileStateSnapshots {
                font-size: 24px;
                margin-top: 17px;
            }

            &.tools {
                margin-top: 17px;
            }
            
            &.alertCall {
                position: absolute;
                bottom: 135px;
                width: 100%;
                font-size: 22px;

                >div {
                    position: absolute !important;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -60%);
                }
            }

            &.alertManagement {
                position: absolute;
                bottom: 93px;
                width: 100%;
                font-size: 22px;
            }

            &.setting {
                position: absolute;
                bottom: 50px;
                width: 100%;
                margin: 0;
                font-size: 24px;
            }

            &.logout {
                position: absolute;
                bottom: 9px;
                width: 100%;
                font-size: 24px;
                margin: 0;
            }
        }

        &--has-conflict-timer {
            position: absolute;
            width: 18px;
            top: -2px;
            right: 7px;
            color: white;
            background: #f34566;
            border-radius: 50%;
            height: 18px;
            opacity: 0.9;
        }
    }

    &--alert-call-mute-remaining-time {
        font-size: 12px;
        white-space: nowrap;
        font-weight: bold;

        >svg {
            position: absolute;
        }
    }

    &--contents {
        &.trading {
            display: none;
            @include screen-size('super-large') {
                display: block;
            }
        }
        position: relative;
        width: 250px;
        min-width: 250px;
        height: 100%;
    }

    &--content {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.menu-tools {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--title {
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
        background: #0a4a4a;
        font-size: 15px;
    }

    &--main {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--button {
        position: relative;
        display: block;
        padding: 5px 0px;
        font-size: 15px;
        text-align: left;
        font-weight: bold;
        background: none;
        color: #b3d0d0;
        line-height: 23px;
        width: calc(100% - 10px);
        margin: 5px 5px;
        border-radius: 3px;

        &:hover {
            color: white;
            background: #267f80;
            >span {
                color: white;
            }
        }

        >svg {
            position: absolute;
            width: 35px;
            font-size: 21px;
            margin-left: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
        >span {
            color: #ddd;
            margin-left: 40px;
        }
    }
}