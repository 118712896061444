.account-balance-window {
    position: fixed;
    width: 100%;
    height: 100%;
    min-width: 900px;

    &--body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background: #023b3b;
    }

    &--header {
        position: relative;
        width: 100%;
        height: 29px;
        min-height: 29px;
        line-height: 29px;
        background: #012323;

        &--tabs {
            position: relative;
            height: 100%;
            float: left;
        }

        &--tab {
            position: relative;
            height: 100%;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 15px;
            border-right: 1px solid #023b3b;
            background: #042b2b;
            color: #ddd;

            &.active {
                color: white;
                background: #065656;
            }

            &:hover {
                color: white;
            }
        }

        &--right-section {
            position: relative;
            float: right;
            display: flex;
            font-size: 13px;
            font-weight: bold;
        }

        &--connection-state {
            margin-right: 15px;
            &--bulb {
                display: inline-block;
                width: 9px;
                height: 9px;
                background: #c7767c;
                right: 0;
                border-radius: 50%;
                margin-right: 7px;
                box-shadow: 0px 0px 2px #001515;
    
                &.ready-state-0 {
                    background: #c7bd71;
                }
    
                &.ready-state-1 {
                    background: #67b7b7;
                }
            }
        }

        &--reconnect-time {
            position: relative;
            margin-right: 15px;
            opacity: 0.8;
        }

        &--last-update-timestamp {
            color: #c5dede;
            margin-right: 15px;
        }

        &--sync-button {
            font-weight: bold;
            background: #021b1b;
            color: #98cecd;
            border-left: 1px solid #023b3a;
            padding: 0px 9px;

            &:hover {
                background: #316e6f;
                color: white;
            }
        }
    }

    &--left-section {
        position: relative;
        height: 100%;
        width: 100%;
        border-right: 1px solid #0a4a4a;

        ::-webkit-scrollbar {
            height: 10px;
            background-color: #053333;
            border-radius: 5px;
            overflow: hidden;
        }

        &--main {
            position: absolute;
            top: 29px;
            bottom: 0;
            width: 100%;
        }
    }

    &--right-section {
        position: relative;
        width: 300px;
        min-width: 300px;
        display: inline-flex;
        flex-direction: column;
    }

    &--fund-transfer-editor {
        position: relative;
        height: auto;
        border-bottom: 1px solid #0a4a4a;
        z-index: 3;

        &--title {
            position: relative;
            text-align: center;
            font-weight: bold;
            min-height: 32px;
            line-height: 32px;
            background: #0a4a4a;
            font-size: 15px;
        }

        &--main {
            position: relative;
            padding: 7px 11px 14px;

            .token-transfer-editor {
                .search-select {
                    &--search-input {
                        width: auto;
                    }
                }
            }
        }
    }

    &--messages {
        position: relative;
        height: 100%;

        &--title {
            position: relative;
            text-align: center;
            font-weight: bold;
            min-height: 32px;
            line-height: 32px;
            background: #0a4a4a;
            font-size: 15px;
        }

        &--main {
            position: absolute;
            width: 100%;
            top:32px;
            bottom: 0px;
            overflow-y: auto;
        }
    }

    @keyframes showNewItem {
        0% {
            background: #457f81;
        }
        100% {
            background: transparent;
        }
    }

    &--notification {
        position: relative;
        padding: 7px 9px;
        animation: 1.0s showNewItem;
        border-bottom: 1px solid #001d1d;

        &--header {
            position: relative;
            line-height: 15px;

            &--left {
                position: relative;
                float: left;
            }
        }

        &--user {
            font-size: 13px;
            font-weight: bold;
            color: #96b9b8;
            margin-bottom: 5px;
        }

        &--tag {
            position: relative;
            display: inline-block;
            background: #3f7575;
            font-size: 13px;
            padding: 0px 7px;
            font-weight: bold;
            color: #012121;
            border-radius: 2px;
            line-height: 23px;

            &.MARGIN_RATIO_WARNING {
                background: #b9ae63;
            }
            &.UPDATE_VARIABLES_FAIL, &.TRANSFER_FAIL, &.BORROW_FAIL, &.REPAY_FAIL {
                background: #af6868;
                color: #562c2c;
            }
            &.UPDATE_SUCCESS, &.BALANCE_SUCCESS, &.BORROW_SUCCESS, &.REPAY_SUCCESS {
                background: #4ba7a7;
            }
            &.FUND_TRANSFER, &.BORROW_COIN {
                background: #d8aa55;
                color: #5f4008;
            }
            &.REPAY_COIN {
                background: #94d094;
                color: #0b2d0b;
            }
            &.API_ERROR, &.WS_CLOSED {
                background: #dc7479;
                color: #672225;
            }
        }

        &--timestamp {
            position: relative;
            float: right;
            font-size: 13px;
            color: #a7bbbb;
        }

        &--message {
            font-size: 13px;
            line-height: 19px;
            margin-top: 7px;
            color: #b8cccc;

            >b {
                color: white;
                font-size: 14px;
                font-weight: bold;
            }

            .red-margin-ratio {
                color: #cc6e6f;
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
}

.account-balance-monitor {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
        height: 42px;

        &--right-section {
            position: relative;
            float: right;
            display: flex;
            line-height: 42px;
        }
    }

    &--global-variables-popup {
        position: relative;
        height: 100%;
        margin-right: 7px;
        margin-top: 7px;

        &--trigger-button {
            height: 29px;
            position: relative;
            background: none;
            color: #71a7a7;
            font-size: 17px;

            &:hover {
                color: #85b9b9;
            }
        }

        &--body {
            position: absolute;
            z-index: 100;
            right: 0px;
            background: #125a5a;
            top: 31px;
            box-shadow: 0px 0px 3px black;
            border-radius: 2px;
        }

        &--header {
            border-bottom: 1px solid #51868c;
        }

        &--hide-group {
            position: relative;
            font-size: 12px;
            display: flex;
            margin: 7px 7px 5px;

            .checkbox {
                width: 21px;
                height: 21px;
                background: #86a7a7;
            }
    
            >span {
                white-space: nowrap;
                line-height: 21px;
                margin: 1px 6px;
                color: #afcccc;
                font-weight: bold;
            }
        }

        &--main {
            position: relative;
            padding: 5px 0px;
        }

        &--param {
            display: table-row;
            line-height: 21px;

            &--name, &--value {
                position: relative;
                display: table-cell;
                white-space: nowrap;
                padding: 3px 7px;
                padding-right: 15px;
            }

            &--name {
                font-size: 13px;
                font-weight: bold;
            }

            &--value {
                >span {
                    position: absolute;
                    top: 0px;
                    transform: translate(-14px, 3px);
                    font-size: 12px;
                    color: white;
                    font-weight: bold;
                }
            }

            &--input {
                font-size: 13px;
                font-weight: bold;
                width: 65px;
                padding: 1px 5px;
                background: #86a7a7;
                color: white;
                border-radius: 2px;
                padding-right: 17px;
                cursor: text;
            }
        }

        &--message {
            background: #0a4040;
            margin: 0px 7px 5px;
            padding: 4px 6px;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            line-height: 15px;
            border-radius: 2px;
        }

        &--footer {
            position: relative;
            padding: 3px 0px 7px;
            text-align: center;
        }

        &--button {
            position: relative;
            display: inline-block;
            width: 75px;
            font-size: 12px;
            height: 23px;
            font-weight: bold;
            margin: 0px 3px;
            border-radius: 2px;

            &.reset {
                color: #013b3b;
                &:hover {
                    color: #308484;
                }
            }
        }
    }

    &--auto-transfer-records {
        position: relative;
        height: 100%;
        margin-right: 4px;
        margin-top: 6px;
    }

    &--auto-transfer-toggle {
        position: relative;
        display: flex;
        height: 100%;

        >span {
            font-size: 13px;
            font-weight: bold;
            text-decoration: underline dotted;
        }

        &--description-popup {
            background: #2d6c6ccf;
            font-size: 13px;
            font-weight: bold;
            padding: 5px 11px;
            max-width: 280px !important;
            text-align: justify;
            box-shadow: 0px 0px 3px black;
            border-radius: 2px;
            transform: translateY(-9px);
        }

        .toggle {
            position: relative;
            margin-left: 7px;
            margin-top: 11px;
            height: 20px;
            width: 90px;
            white-space: nowrap;
            overflow: hidden;

            &--label {
                line-height: 20px;
                width: auto;
            }
            &--button {
                width: 16px;
                height: 16px;
            }
            &--track {
                width: 38px;
            }
            &.off >.toggle--track {
                background: #cb747a;
            }
        }
    }

    &--search-input {
        position: relative;
        float: left;
        height: 29px;
        padding: 0px 8px;
        text-align: left;
        width: 200px;
        font-size: 13px;
        font-weight: bold;
        color: white;
        cursor: text;
        margin-left: 7px;
        background: none;
        border-left: 2px solid #2d6c6c;

        &:hover {
            background: #094a4a;
        }
    }


    &--main {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    &--table {
        position: relative;
        width: 100%;
        border-collapse: collapse;

        &--header {
            &--title-row {
                th {
                    background: #081a1b;
                    position: sticky;
                    top: 0px;
                    font-size: 13px;
                    line-height: 27px;
                    padding: 0px 5px;
                    z-index: 10;
                    white-space: nowrap;
        
                    &.sortable {
                        cursor: pointer;
                    }
        
                    &.sorted {
                        color: rgb(226, 203, 106);
                    }

                    &.left-align {
                        text-align: left;
                    }
                    &.right-align {
                        text-align: right;
                    }
                    &:first-child {
                        padding-left: 11px;
                    }
                }

                &.shift-down th {
                    position: sticky;
                    top: 25px;
                }
            }
        }
    }

    &--group-info-header {
        th {
            position: sticky;
            top: 27px;
            box-shadow: 0px 2px 0px #2d6c6c;
            background: #013b3b;
            padding: 5px 11px 4px;
            z-index: 5;

            &.shift-down {
                top: 52px;
            }
        }
        &:nth-child(n+3) th {
            padding-top: 15px;
            top: 17px;

            &.shift-down {
                top: 42px;
            }
        }
    }

    &--group-info {
        &--account {
            float: left;
            font-weight: bold;
            font-size: 16px;
            line-height: 25px;
            color: white;
            white-space: nowrap;
            max-width: 800px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--currency {
            float: right;
            font-size: 16px;
            line-height: 25px;
            font-weight: bold;
            border-radius: 2px;
            margin-top: 1px;
        }
    }

    &--account-name {
        >span {
            text-decoration: underline dotted;
            cursor: pointer;
            &:hover {
                color: white;
            }
        }
    }

    &--account-popup {
        background: #012020;
        box-shadow: black 0px 0px 3px;
        border-radius: 3px;
        overflow: hidden;
        padding: 5px 7px 7px;
        width: 250px;
    }

    &--market-value {
        position: relative;

        &.SPOT {
            color: #7badd8;
        }
        &.MARGIN {
            color: #d6cb7d;
        }
        &.FUTURE {
            color: #c57593;
        }
        &.SWAP, &.BINANCE_USDT_FUTURES {
            color: #95d6a1;
        }

        &--pair {
            color: #ddd;
            margin-left: 7px;
        }
    }

    &--account-balance-row {
        td {
            white-space: nowrap;
            font-size: 14px;
            line-height: 24px;
            padding: 7px 3px;
            font-weight: bold;
            color: #ddd;
            text-align: center;

            &.left-align {
                text-align: left;
            }
            &.right-align {
                text-align: right;
            }

            &:first-child {
                padding-left: 11px;
            }
            &:last-child {
                padding-right: 5px;
            }
        }
        &:nth-child(odd) {
            background: #013333;
        }
        &:hover {
            background: #034646;
        }

        &.warning {
            background: #9a4f4f;
            border-bottom: 1px solid #522a2a;
            &:hover {
                background: #a95a5a;
            }
        }
        &.highlight {
            background: steelblue;
        }
    }

    &--variables-data {
        >span {
            margin: 0px 5px;
        }
    }

    &--variable-input-wrapper {
        position: relative;
        display: inline-block;
        >span {
            position: absolute;
            transform: translate(-15px, 1px);
            font-size: 13px;
            color: #ddd;
        }
        >input {
            text-align: right;
            font-size: 14px;
            width: 55px;
            padding-right: 19px;
            background: #3f7574;
            font-weight: bold;
            color: white;
            border-radius: 2px;
            height: 24px;
            cursor: text;
            &.editing {
                background: #ab9e46;
            }
            &:disabled {
                background: none;
            }
        }
    }

    &--net-balance-value, &--available-balance-value, &--transferable {
        >span {
            margin-left: 5px;
            opacity: 0.7;
        }
    }

    &--buttons {
        position: relative;
        width: 100%;
        height: 35px;
        background: rgb(7, 26, 27);
        z-index: 10;
        text-align: center;
    }

    &--button {
        display: inline-block;
        height: 23px;
        margin: 6px 4px;
        width: 76px;
        font-size: 13px;
        font-weight: bold;
        border-radius: 2px;

        &.reset {
            color: #013b3b;
            &:hover {
                color: #308484;
            }
        }
    }

    &--transfer-fund-advice {
        position: relative;
        width: 150px;
        white-space: normal;
        text-align: left;
        word-break: break-word;
        margin: -2px 0px 0px;
        line-height: 19px;

        >span {
            font-size: 12px;
            font-weight: normal;
            margin-right: 5px;
    
            >b {
                font-weight: bold;
                color: white;
            }
        }
        >button {
            position: relative;
            font-size: 12px;
            font-weight: bold;
            padding: 0px 6px;
            border-radius: 2px;
            color: #3f7575;
            background: #ddd;
            line-height: 15px;
            &:hover {
                background: white;
            }
        }
    }

    &--bulk-upload-button {
        height: 29px;
        background: none;
        color: #71a7a7;
        font-size: 19px;
        margin-top: 7px;
        margin-right: 11px;
        &:hover {
            color: #85b9b9;
        }
    }

    &--bulk-upload-table-row {

        td {
            position: sticky;
            top: 0px;
            z-index: 11;
            background: #3c7979;
        }

        &--variables {
            white-space: nowrap;
            >span {
                margin: 0px 5px;
            }
        }

        &--variable {
            display: inline-block;
            font-size: 13px;
            font-weight: bold;
            color: white;

            >input {
                text-align: right;
                width: 60px;
                padding: 0px 16px;
                height: 23px;
                background: #1e4a4a;
                font-weight: bold;
                color: white;
                border-radius: 2px;
                cursor: text;
            }
            >span {
                position: absolute;
                transform: translate(-13px, 4px);
                font-size: 12px;
                color: #ddd;
            }
        }

        &--filter-input {
            position: relative;
            width: 100%;
            background: rgb(30, 74, 74);
            color: white;
            text-indent: 2px;
            font-weight: bold;
            font-size: 13px;
            padding: 0px 3px;
            height: 23px;
            cursor: text;
            border-radius: 2px;
        }

        &--cancel-button {
            float: right;
            background: none;
            color: #ddd;
            font-size: 15px;
            margin-right: 2px;

            &:hover {
                color: white;
            }
        }
    }
}

.account-balancer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    &--header {
        position: relative;
        height: 61px;
        min-height: 61px;
        font-size: 14px;
        font-weight: bold;
        padding: 7px 7px;
        z-index: 99;
        min-width: 800px;

        &--left-section, &--right-section {
            display: flex;
            flex-direction: column;
        }

        &--left-section {
            float: left;
        }

        &--right-section {
            float: right;
        }

        &--search-input {
            position: relative;
            float: left;
            height: 29px;
            padding: 0px 8px;
            text-align: left;
            width: 220px;
            font-size: 13px;
            font-weight: bold;
            color: white;
            cursor: text;
            background: none;
            border-left: 2px solid #2d6c6c;
    
            &:hover {
                background: #094a4a;
            }
        }

        &--criteria {
            display: flex;
            line-height: 20px;
            margin-top: 9px;
        }

        &--group-filter, &--group-sort-by, &--hide-single-account-checkbox {
            display: flex;
            white-space: nowrap;
            margin-right: 21px;
        }

        &--group-filter, &--group-sort-by {
            >span {
                opacity: 0.8;
                margin-right: 9px;
            }
            .search-select {
                &--current-option {
                    padding-right: 21px;
                }
                &--input-icon {
                    opacity: 0.7;
                    right: 0px;
                }
                &--options-wrapper {
                    background: #012c2c;
                }
            }
        }

        &--last-update-wrapper, &--buttons {
            display: flex;
            white-space: nowrap;
            flex-flow: row-reverse;
        }

        &--last-update-timestamp, &--fetch-last-button {
            margin-left: 11px;
        }

        &--last-update-wrapper {
            line-height: 21px;
        }

        &--fetch-last-button {
            line-height: 21px;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 9px;
            border-radius: 2px;
            background: #488586;
            color: #ddd;

            &:hover {
                background: #5a999a;
                color: white;
            }
        }

        &--buttons {
            line-height: 29px;
            margin-top: 9px;
        }

        &--hide-single-account-checkbox {
            .checkbox {
                width: 20px;
                height: 20px;
            }
            >span {
                margin-left: 5px;
            }
        } 

        &--reset-button, &--save-button, &--edit-button {
            width: 81px;
            font-size: 14px;
            font-weight: bold;
            border-radius: 2px;
            margin-left: 10px;
        }

        &--edit-button {
            background: #8bc18b;
            &:hover {
                color: white;
            }
        }
    }

    &--auto-transfer-toggle {
        position: relative;
        display: flex;
        height: 100%;

        >span {
            font-size: 13px;
            font-weight: bold;
            text-decoration: underline dotted;
        }

        &--description-popup {
            background: #2d6c6ccf;
            font-size: 13px;
            font-weight: bold;
            padding: 5px 11px;
            max-width: 280px !important;
            text-align: justify;
            box-shadow: 0px 0px 3px black;
            border-radius: 2px;
            transform: translateY(-9px);
        }

        .toggle {
            position: relative;
            margin-left: 7px;
            margin-top: 5px;
            height: 20px;
            width: 76px;
            white-space: nowrap;
            overflow: hidden;

            &--label {
                line-height: 20px;
                width: auto;
            }
            &--button {
                width: 16px;
                height: 16px;
            }
            &--track {
                width: 38px;
            }
            &.off >.toggle--track {
                background: #cb747a;
            }
        }
    }

    &--main {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    &--table {
        position: relative;
        width: 100%;
        border-collapse: collapse;

        &--head {
            th {
                background: #081a1b;
                position: sticky;
                top: 0px;
                font-size: 13px;
                line-height: 27px;
                padding: 0px 5px;
                z-index: 10;
                white-space: nowrap;
                text-align: center;
                &.left-align {
                    text-align: left;
                }
                &.right-align {
                    text-align: right;
                }
                &:first-child {
                    padding-left: 11px;
                }
            }
        }

        tbody {
            td {
                font-size: 14px;
                font-weight: bold;
                padding: 7px 0px;
                text-align: center;
                color: #ddd;
                &.left-align {
                    text-align: left;
                }
                &.right-align {
                    text-align: right;
                }
                &:first-child{
                    padding-left: 11px;
                }
                &.currency-number >span {
                    margin-left: 5px;
                    opacity: 0.8;
                }
            }
            tr:nth-child(odd) td {
                background: #013333;
            }
        }
    }

    &--group-head {
        th {
            padding: 5px 11px 4px;
            position: sticky;
            top: 27px;
            background: #023b3a;
            z-index: 2;
            box-shadow: 0px 2px 0px #2d6c6c;
        }

        &:nth-child(n+3) th {
            padding-top: 15px;
            top: 17px;
        }
    }
    &--group-title {
        float: left;
        font-size: 16px;
        line-height: 25px;

        &--market {
            font-size: 13px;
            margin-left: 7px;
            color: white;
            padding: 0px 5px;
            border-radius: 2px;

            &.SPOT {
                background: #7badd8;
            }
            &.MARGIN {
                background: #d6cb7d;
            }
            &.FUTURE {
                background: #cd7999;
                color: #5a2035;
            }
            &.SWAP, &.BINANCE_USDT_FUTURES {
                background: #83c98f;
                color: #123d1a;
            }
        }

        &--pair, &--currency {
            margin-left: 7px;
        }
    }

    &--auto-transfer-state {
        position: relative;
        float: left;
        line-height: 25px;
        margin-left: 17px;
        font-size: 13px;

        &--dot {
            position: absolute;
            float: left;
            width: 11px;
            height: 11px;
            background: #ce757c;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: 0px 0px 3px black;
            border-radius: 50%;

            &.on {
                background: #4dbbbb;
            }
        }

        >span {
            margin-left: 19px;
        }
    }

    &--item {
        &--account-name {
            text-decoration: underline dotted;
            cursor: pointer;
            &:hover {
                color: white;
            }
        }
        &--account-popup {
            background: #012020;
            box-shadow: black 0px 0px 3px;
            border-radius: 3px;
            overflow: hidden;
            padding: 5px 7px 7px;
            width: 250px;
        }
        &--market-tag {
            &.SPOT {
                color: #7badd8;
            }
            &.MARGIN {
                color: #d6cb7d;
            }
            &.FUTURE {
                color: #c57593;
            }
            &.SWAP, &.BINANCE_USDT_FUTURES {
                color: #95d6a1;
            }
        }
        &--pair {
            margin-left: 5px;
        }
        &--check-box {
            .checkbox {
                width: 24px;
                height: 24px;

                &.disabled {
                    background: none;
                    cursor: default;
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
            &.modified .checkbox {
                background: #ab9d45;
            }
        }
    }

    &--group-auto-transfer-toggle {
        line-height: 25px;
        display: flex;
        float: right;
        >span {
            margin-right: 7px;
            font-size: 13px;
            opacity: 0.7;
        }
        .toggle {
            height: 23px;
            &--button {
                width: 19px;
                height: 19px;
            }
            &--track {
                width: 41px;
                margin-top: 1px;
            }
            &--label {
                line-height: 25px;
                font-size: 14px;
                font-weight: bold;
            }
            &.off {
                .toggle--track {
                    background: #ca747a;
                }
            }
        }
    }

    &--param-input-wrapper {
        position: relative;
        display: inline-block;
        >span {
            position: absolute;
            transform: translate(-15px, 4px);
            font-size: 13px;
            color: #ddd;
        }
        >input {
            text-align: right;
            font-size: 14px;
            width: 55px;
            padding-right: 19px;
            background: #3f7574;
            font-weight: bold;
            color: white;
            border-radius: 2px;
            height: 24px;
            cursor: text;
            &.modified {
                background: #ab9e46;
            }
            &:disabled {
                background: none;
            }
        }
    }

    &--transfers-preview {
        background: #012020;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        overflow: hidden;

        &--transfering-message, &--trigger-button {
            float: right;
            line-height: 25px;
            padding: 0px 9px;
            font-size: 13px;
            font-weight: bold;
        }

        &--transfering-message {
            color: #8bc18b;
        }

        &--trigger-button {
            border-radius: 2px;
            background: #5d8cb4;
            color: #1a3954;

            &:hover {
                background: #6999c2;
                color: white;
            }
        }

        &--header {
            background: #0a4a49;
            font-size: 14px;
            font-weight: bold;
            padding: 3px 9px;
        }

        &--main {
            position: relative;
            padding: 5px 9px 9px;
        }

        table {
            position: relative;
            border-collapse: collapse;
            font-weight: bold;

            thead {
                th {
                    font-size: 13px;
                    color: #ddd;
                }
            }
            tbody {
                td {
                    font-size: 14px;
                    border: 1px dotted #0a4a48;
                    padding: 2px 7px;
                }
            }
        }

        &--account-type, &--instrument-id {
            margin-left: 5px;
        }

        &--footer {
            position: relative;
            margin-top: 7px;
            line-height: 25px;

            &--message {
                font-size: 13px;
                font-weight: bold;

                &.transfering {
                    color: #ab9d45;
                    float: right;
                }
            }
        }

        &--confirm-button {
            float: right;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 11px;
            line-height: 25px;
            background: #8ac28b;

            &:hover {
                color: white;
            }
        }

        &--message {
            font-size: 15px;
            font-weight: bold;
            min-width: 140px;
        }
    }
}

.margin-lending-table {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--no-content {
        position: relative;
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #a9cccb;
    }

    &--header {
        position: relative;
        height: 42px;
        min-height: 42px;
        min-width: 800px;
        overflow: hidden;

        &--left-section, &--right-section {
            position: relative;
            height: 100%;
            line-height: 42px;
        }

        &--left-section {
            float: left;
        }

        &--right-section {
            float: right;
        }

        &--search-input {
            position: relative;
            float: left;
            height: 29px;
            padding: 0px 8px;
            text-align: left;
            width: 200px;
            font-size: 13px;
            font-weight: bold;
            color: white;
            cursor: text;
            margin-left: 7px;
            background: none;
            border-left: 2px solid #2d6c6c;
            margin-right: 11px;

            &:hover {
                background: #094a4a;
            }
        }

        &--auto-borrow-only-checkbox {
            display: flex;
            font-size: 14px;
            font-weight: bold;

            .checkbox {
                width: 20px;
                height: 20px;
                margin-top: 11px;
            }
            >span {
                margin-left: 5px;
            }
        } 

        &--fetch-latest-button {
            position: relative;
            margin-right: 11px;
            border-radius: 2px;
            font-weight: bold;
            padding: 3px 0px;
            width: 88px;
            border: 1px solid;
            background: transparent;
            color: #81c0c1;

            &:hover {
                background: #68a1a2;
                color: white;
                border-color: #68a1a2;
            }
        }

        &--button {
            position: relative;
            float: left;
            margin-right: 11px;
            border-radius: 2px;
            padding: 4px 0px;
            width: 70px;
            font-weight: bold;
        }

        &--auto-repay-toggle, &--auto-borrow-toggle {
            position: relative;
            display: flex;
            height: 100%;
            float: left;
    
            >span {
                font-size: 13px;
                font-weight: bold;
            }
    
            .toggle {
                position: relative;
                margin-left: 7px;
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                width: 76px;
                white-space: nowrap;
                overflow: hidden;
    
                &--label {
                    line-height: 20px;
                    width: auto;
                }
                &--button {
                    width: 16px;
                    height: 16px;
                }
                &--track {
                    width: 38px;
                }
                &.off >.toggle--track {
                    background: #cb747a;
                }
            }
        }
    }

    &--main {
        position: relative;
        width: calc(100%-3px);
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        padding-right: 3px;
    }

    .ReactVirtualized__Table__headerRow {
        background: #081a1b;

        &:hover {
            background: #081a1b !important;
        }
    }

    .ReactVirtualized__Table__rowColumn {
        margin: 0;

        &.center-align {
            text-align: center;
        }
    }

    &--table {
        &--header {
            font-size: 13px;
            text-transform: initial;
            display: flex;
            margin-right: 0px !important;
            color: white;
            cursor: default !important;
            line-height: 19px;

            &.center-align {
                text-align: center;
                >span {
                    margin: 0 auto;
                }
            }

            &.sortable {
                cursor: pointer !important;
            }

            &.sorted {
                color: #e2cb6a;
            }
        }
    }
    
    &--row {
        font-size: 14px;
        font-weight: bold;
        color: #ddd;

        &.even-row {
            background: #013232;
        }

        &:hover {
            background: #034646;

            .margin-lending-table--action-button {
                visibility: visible;
            }
        }
    }

    &--id {
        line-height: 23px;

        &--pair {
            >span {
                margin-left: 5px;
            }
        }
    }

    &--account-name {
        margin-left: 10px !important;
    }

    &--prices {
        text-align: center;
        line-height: 25px;
        padding: 0px 5px;
        &--liquidation {
            border-bottom: 1px dashed #055655;
        }
    }

    &--ratio-cell {
        >span {
            line-height: 25px;
        }
    }

    &--column-direction-flex {
        line-height: 29px;

        &--top {
            border-bottom: 1px dashed #0a1b1c;
        }
        &--top, &--bottom {
            padding: 0px 3px;
        }

        &.action-button, &.submit-button {
            .margin-lending-table--column-direction-flex--top {
                border-color: transparent;
            }
        }
    }

    &--action-button, &--button {
        width: 55px;
        margin: 4px 0px;
        line-height: 22px;
        padding: 0px 0px;
        border-radius: 2px;
        font-weight: bold;
        font-size: 13px;
        border: 1px solid;
    }

    &--action-button {
        visibility: hidden;
        background: none;

        &.always-shown {
            visibility: visible !important;
        }

        &.BORROW {
            color: #e4c26e;

            &:hover {
                background: #e4c26e0f;
            }

            &.active {
                background: #e4c26e;
                color: #3c2e0a;
                border-color: #e4c26e;
            }
        }
        &.REPAY {
            color: #94d094;

            &:hover {
                background: #94d0940f;
            }

            &.active {
                border-color: #94d094;
                background: #94d094;
                color: #0b2d0b;
            }
        }
    }

    &--button {
        &.cancel {
            background: #ddd;
            border-color: #ddd;
            margin-right: 6px;
            color: #033c3b;

            &:hover {
                background: white;
            }
        }
        &.confirm {
            background: #5d8cb4;
            border-color: #5d8cb4;
            color: #ddd;

            &:not(:disabled) {
                &:hover {
                    background: #5e99cb;
                    color: white;
                }
            }
        }
    }

    &--param-input-wrapper {
        position: relative;
        >span {
            position: absolute;
            transform: translate(-15px, 4px);
            font-size: 13px;
            color: #ddd;
        }
        >input {
            text-align: right;
            font-size: 14px;
            width: 55px;
            padding-right: 19px;
            background: #3f7574;
            font-weight: bold;
            color: white;
            border-radius: 2px;
            height: 24px;
            cursor: text;
            &.modified {
                background: #ab9e46;
            }
            &:disabled {
                background: none;
            }
        }
    }

    &--repay-limit {
        .margin-lending-table--param-input {
            margin: 4px 0px;
            margin: 4px 0px;
            padding: 0px 5px;
            text-align: left;
        }
    }

    &--amount-input {
        width: 65px;
        margin: 4px 0px;
        line-height: 24px;
        padding: 0px 5px;
        background: #3f7574;
        font-size: 14px;
        font-weight: bold;
        color: white;
        border-radius: 2px;
        cursor: text;
    }

    &--reset-action-button {
        font-size: 13px;
        font-weight: bold;
        padding: 0px 5px;
        border: 1px solid;
        border-radius: 2px;
        background: transparent;
        white-space: nowrap;
    }

    &--reset-action-button {
        color: #ddd;

        &:hover {
            background: #ddd;
            color: #033232;
            border-color: #ddd;
        }
    }

    &--payback-all-popup {
        background: #012424;
        padding: 7px 11px;
        border-radius: 2px;
        box-shadow: 0px 0px 3px #091b1c;
        margin-top: 3px;

        &--trigger {
            font-size: 13px;
            font-weight: bold;
            padding: 0px 5px;
            border: 1px solid;
            border-radius: 2px;
            white-space: nowrap;
            color: #94cf93;
            background: transparent;

            &:hover {
                background: #94cf93;
                border-color: #94cf93;
                color: #081a1b;
            }
        }

        &--list {
            display: flex;
            flex-direction: column;
        }

        &--item {
            font-size: 14px;
            font-weight: bold;
            margin: 3px 0px;
            line-height: 28px;
            padding: 0px 9px;
            text-align: left;
            border-radius: 2px;
            background: transparent;
            color: #ddd;
            border: 1px solid;

            &:hover {
                background: #94cf93;
                color: #081a1b;
            }
        }
    }

    &--auto-borrow-lock {
        position: relative;
        white-space: nowrap;
        border-radius: 2px;
        padding: 0px 3px;
        margin-left: 5px;
        border: 1px solid;
        background: transparent;
        color: #ddd;
        font-weight: bold;
        >svg {
            transform: translateY(2px);
            margin-right: 2px;
        }

        &:hover {
            background: #ddd;
            color: #033232;
            border-color: #ddd;
        }
    }

    &--auto-borrow {
        .toggle {
            margin: 4px 0px;
            height: 24px;

            &--button {
                height: 20px;
                width: 20px;
            }

            &--label {
                line-height: 24px;
            }

            &--track {
                width: 42px;
            }
        }
    }
}