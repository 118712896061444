.funding-tags {
    font-size: 14px;
    font-weight: bold;

    &--current {
        display: flex;
        cursor: pointer;

        >label {
            margin-right: 9px;
            opacity: 0.7;
        }
        >span {
            margin-right: 5px;
            white-space: nowrap;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        >svg {
            opacity: 0.7;
            transform: translateY(7px);
            font-size: 16px;
        }
        &:hover {
            >svg {
                opacity: 1.0;
            }
        }
    }

    &--main {
        position: absolute;
        box-shadow: 0px 0px 3px black;
        font-size: 15px;
        line-height: 23px;
        color: #ddd;
        border-radius: 2px;
        overflow: hidden;
        background: #055454e8;

        &--all, &--add-tag-button {
            padding: 3px 11px;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
                color: white;
            }
        }

        &--all {
            background: #055454e8;
            &:hover {
                background: #267f80;
            }
        }

        &--add-tag-button {
            background: #5f8e8e;

            >svg {
                font-size: 19px;
                transform: translateY(4px);
                margin-right: 5px;
            }
        }

        &--add-tag-popup {
            background: #055454e8;
            box-shadow: 0px 0px 2px black;
            border-radius: 2px;
            padding: 7px 11px;
            width: 240px;
        }
    }
}

.funding-tag-item {
    position: relative;
    z-index: 100;
    color: #ddd;
    display: flex;
    line-height: 23px;
    background: #055454e8;

    &:hover {
        background: #267f80;
        color: white;

        .funding-tag-item--trigger {
            opacity: 0.7;

            &:hover {
                opacity: 1.0;
            }
        }
    }

    &:not(:last-child) {
        border-bottom: 1px solid #002a2a;
    }

    &--trigger {
        position: relative;
        width: 28px;
        opacity: 0;
        cursor: pointer;



        >svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &--button {
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        white-space: nowrap;
        background: transparent;
        color: inherit;
        text-align: left;
        padding: 3px 11px;
        cursor: pointer;
    }

    &--popup {
        background: #055454e8;
        box-shadow: 0px 0px 2px black;
        border-radius: 2px;
        padding: 7px 11px;
        width: 240px;
    }
}

.funding-tag-editor {
    &--section {
        font-weight: bold;
        margin-bottom: 11px;

        >label {
            display: block;
            width: 100%;
            font-size: 13px;
            opacity: 0.8;
        }
    }

    &--name-input {
        background: none;
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #437f80;
        width: 100%;
        padding: 2px 0px;
        color: white;
        margin-top: 1px;
        cursor: text;
    }

    &--coins {
        margin-top: 5px;
    }

    &--coin {
        float: left;
        margin-right: 5px;
        margin-bottom: 5px;
        border: 1px solid #437f80;
        padding: 2px 5px 2px 7px;
        font-size: 14px;
        font-weight: bold;

        &:hover {
            background: #166a6a;
        }

        &--remove-button {
            padding: 0px;
            height: 100%;
            background: transparent;
            margin-left: 5px;
            color: #ddd;
            &:hover {
                color: white;
            }

            >svg {
                transform: translateY(1.5px);
            }
        }
    }

    &--coins-editor {
        .search-select {
            width: auto;
            margin-right: 5px;

            &--current-option {
                line-height: 21px;
                text-align: center;
                padding: 1px 6px;
                font-size: 14px;
                border: 1px solid;
                color: #ddd;

                &:hover {
                    color: white;
                }
            }

            &--placeholder {
                opacity: 1.0;
            }
            
            &--input-icon {
                display: none;
            }
        }
    }

    &--add-coin-button, &--clear-coin-button {
        float: left;
    }

    &--clear-coin-button {
        line-height: 21px;
        text-align: center;
        padding: 1px 6px;
        font-size: 14px;
        border: 1px solid;
        font-weight: bold;
        background: transparent;
        color: #e27e85;

        &:hover {
            background: #e27e85;
            border-color: #e27e85;
            color: white;
        }
    }

    &--buttons {
        text-align: center;
        padding: 3px 0px 5px;
        >button {
            display: inline-block;
            padding: 5px 0px;
            font-weight: bold;
            width: 89px;
            margin: 0px 3px;
        }
    }

    &--button {
        &.delete {
            background: #ddd;
            color: darkslategray;
            &:hover {
                background: white;
            }
        }
    }
}