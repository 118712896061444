.option-window {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
        width: 100%;
        height: 39px;
        min-height: 39px;
        line-height: 39px;
        font-weight: bold;
        border-bottom: 1px solid #5ba0a0;

        &--title {
            float: left;
            font-size: 18px;
            margin-left: 29px;
        }

        &--tabs {
            display: flex;
            margin-left: 13px;
            float: left;
            font-size: 15px;
        }

        &--tab {
            padding: 0px 8px;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
                opacity: 1.0;
            }

            &.active {
                opacity: 1.0;
                text-decoration: underline;
            }
        }
    }

    &--body {
        position: relative;
        height: 100%;
    }
}

.option-delta-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &--section {
        position: relative;
        width: 100%;
        padding-bottom: 11px;

        &--title {
            font-size: 14px;
            line-height: 24px;
            background: #022f2f;
            font-weight: bold;
            text-indent: 11px;
        }
    }

    &--symbol-delta-table {
        position: relative;
        width: 100%;
        border-collapse: collapse;
        font-weight: bold;

        thead {
            background: #075d5d;
            font-size: 13px;
            th {
                padding: 3px 11px;
                text-align: left;
                color: #e4f3f3;

                &.align-right {
                    text-align: right;
                }
            }
        }

        tbody {
            td {
                font-size: 15px;
                padding: 3px 11px;
                text-align: left;
                &.align-right {
                    text-align: right;
                }
            }
        }
    }

    &--coin-delta {
        position: relative;
        ::-webkit-scrollbar{
            height: 9px;
        }

        &--item {
            position: relative;
            margin: 7px 0px 14px;
            font-weight: bold;

            &--header {
                position: relative;
                padding: 0px 11px;
                line-height: 27px;

                &--coin, &--net-pos {
                    float: left;
                }

                &--coin {
                    font-size: 17px;
                }

                &--net-pos {
                    margin-left: 11px;
                    font-size: 15px;
                    >span {
                        opacity: 0.8;
                    }
                }

                &--timestamp {
                    float: right;
                    font-size: 13px;
                    opacity: 0.8;
                }
            }

            &--main {
                overflow: auto;
            }

            &--table {
                position: relative;
                border-collapse: collapse;
                min-width: 100%;

                thead {
                    background: #075d5d;
                    font-size: 13px;
                    line-height: 15px;
                    th {
                        padding: 3px 5px;
                        color: #e4f3f3;
                        &:first-child {
                            position: sticky;
                            left: 0px;
                            z-index: 3;
                            background: #075d5d;
                        }
                        &:not(:last-child) {
                            border-right: 1px solid #022f2f;
                        }
                    }
                }
                tbody {
                    th {
                        position: sticky;
                        left: 0px;
                        z-index: 3;
                        background: #0a4a4a;
                        border-right: 1px solid #022f2f;
                    }

                    td {
                        font-size: 14px;
                        font-weight: bold;
                        padding: 7px;
                        text-align: center;
                        line-height: 17px;

                        &:not(:last-child) {
                            border-right: 1px solid #022f2f;
                        }
                    }

                    tr {
                        &:not(:last-child) {
                            th, td {
                                border-bottom: 1px solid #022f2f;
                            }
                        }
                    }
                }

                &--data {
                    &--price {
                        opacity: 0.7;
                    }
                    &--price, &--hedge {
                        display: block;
                    }
                }

                &--row-header {
                    font-size: 17px;
                    font-weight: bold;

                    &.positive {
                        background: #117979;
                    }
                    &.negative {
                        background: #ef727b;
                    }
                }
            }
        }
    }
}

.option-implied-volatility-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &--header {
        position: relative;
        display: flex;
        width: 100%;
        height: 24px;
        line-height: 24px;
        background: #022f2f;

        &--coin-select {
            margin-left: 11px;
            display: flex;
            font-size: 14px;
            font-weight: bold;

            >span {
                margin-right: 9px;
                color: #a1c5c5;
            }

            .search-select {
                width: auto;
                &--current-option {
                    padding-right: 21px;
                }
                &--input-icon {
                    right: 0;
                    opacity: 0.8;
                }
                &--search-input {
                    width: 100%;
                }
                &--options-wrapper {
                    margin: 0px;
                }
            }
        }
    }

    &--body {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;

        &--left, &--right {
            position: absolute;
            top: 0;
            bottom: 0;
        }

        &--left {
            left: 0;
            right: 50%;
        }

        &--right {
            left: 50%;
            right: 0;
            border-left: 1px solid #217777;
        }
    }
}

.option-implied-volatility-matrix {
    position: relative;
    height: 100%;

    &--header {
        border-bottom: 1px solid #207776;
        position: relative;
        width: 100%;
        height: 31px;

        &--group-by-select {
            margin-left: 11px;
            display: inline-flex;
            white-space: nowrap;
            line-height: 31px;
            font-weight: bold;
            font-size: 14px;

            >span {
                color: #a0c5c5;
                margin-right: 9px;
            }

            .search-select {
                &--current-option {
                    padding-right: 19px;
                }
                &--input-icon {
                    opacity: 0.7;
                    right: 0px;
                }
                &--search-input {
                    width: 100%;
                }
            }
        }
    }

    &--main {
        position: absolute;
        width: 100%;
        top: 32px;
        bottom: 0px;
        overflow: auto;
    }

    &--group-matrix {
        position: relative;
        display: table;
        width: 100%;

        &--header {
            display: table-row;
            line-height: 25px;

            &--data {
                display: table-cell;
                text-align: center;
                font-size: 13px;
                font-weight: bold;
                line-height: 19px;
                padding-top: 6px;

                &.BID {
                    color: #75dedb;
                }

                &.ASK {
                    color: #f37884;
                }
            }
        }

        &--row {
            display: table-row;

            &:not(:last-child) {
                .option-implied-volatility-matrix--group-matrix--row--data {
                    border-bottom: 1px solid #043131;
                }
            }

            &--data {
                display: table-cell;
                padding: 3px;
                &.name {
                    width: 1%;
                    vertical-align: middle;
                    padding-left: 11px;
                    font-size: 13px;
                    font-weight: bold;

                    &.CALL {
                        color: #75dedb;
                    }

                    &.PUT {
                        color: #f37884;
                    }
                }
            }
        }
    }

    &--group {
        position: relative;
        &--name {
            background: #196563;
            text-indent: 11px;
            line-height: 21px;
            font-size: 13px;
            font-weight: bold;
            &.CALL {
                color: #75dedb;   
            }
            &.PUT {
                color: #f1828c;
            }
        }
    }

    &--table {
        position: relative;
        width: 100%;
        tbody {
            th {
                font-size: 12px;
                color: #90b9b9;
                width: 1%;
                padding-right: 3px;
            }
            td {
                text-align: center;
                padding: 3px 0px;
                font-size: 14px;
                font-weight: bold;
            }
        }
        tfoot {
            td {
                text-align: center;
                font-size: 12px;
                font-weight: bold;
                color: #90b9b9;
            }
        }
    }
}

.option-implied-volatility-surface {
    position: relative;
    height: 100%;

    &--header {
        position: absolute;
        width: 100%;
        height: 31px;
        border-bottom: 1px solid #217776;
        display: flex;
        overflow-x: auto;

        &--select {
            position: relative;
            display: flex;
            line-height: 31px;
            margin-left: 11px;
            font-size: 14px;
            font-weight: bold;

            &--name {
                color: #a0c5c5;
                margin-right: 9px;
                white-space: nowrap;
                font-size: 13px;
            }

            &--buttons {
                position: relative;
                white-space: nowrap;
            }

            &--button {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                font-size: 12px;
                font-weight: bold;
                padding: 0px;
                height: 22px;
                background: none;
                border: 1px solid #7bd2cf;
                width: 55px;
                color: #7bd2cf;

                &:hover {
                    background: #105a5a;
                    color: white;
                }

                &:not(:first-child) {
                    border-left: none;
                }

                &:not(:last-child) {
                    border-right: 1px solid #7bd2cf;

                    &.active {
                        border-right: 1px solid #0a4a4a;
                    }
                }

                &:first-child {
                    border-top-left-radius: 2px;
                    border-bottom-left-radius: 2px;
                }

                &:last-child {
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                }

                &.active {
                    color: #0a4a4a;
                    background: #7bd2cf;
                }
            }
        }
    }

    &--body {
        position: absolute;
        width: 100%;
        top: 32px;
        bottom: 0px;
    }
}