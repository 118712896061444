.pricing-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar{
        height: 0px;
    }

    &--header {
        position: relative;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
    }

    &--title {
        text-align: center;
        font-weight: bold;
        background: #0a4a4a;
        font-size: 15px;
    }

    &--search {
        height: 29px;
        min-height: 29px;

        &--input {
            position: relative;
            display: block;
            width: calc(100% - 18px);
            padding: 0px 9px;
            background: #023534;
            height: 100%;
            font-size: 12px;
            font-weight: bold;
            color: #eee;
            cursor: text;
        }

        &--result {
            position: absolute;
            width: calc(100% - 10px);
            max-height: 300px;
            overflow: auto;
            background: #094e4e;
            z-index: 1;
            margin: 5px 5px;
            box-shadow: 0px 0px 3px #001515;
            border-radius: 2px;
        }

        &--result-item {
            position: relative;
            border-bottom: 1px solid #042b2b;
            font-size: 13px;
            padding: 7px 7px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                background: #115d5d;
            }
        }
    }

    &--body {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--item {
        position: relative;
        border-bottom: 1px solid #002928;
        padding: 7px 9px;
        background: #023b3b;

        &--symbol-name {
            font-size: 14px;
            font-weight: bold;
            color: #eee;
            line-height: 19px;
        }

        &--timestamp {
            font-size: 12px;
        }

        &--price-wrapper {
            position: relative;
            display: flex;
            margin-top: 3px;
            line-height: 19px;
        }

        &--last-price {
            font-size: 15px;
            font-weight: bold;
            color: #ddd;
            margin-top: 1px;
            width: 33.333%;
            font-weight: bold;
            font-size: 13px;
            line-height: 17px;
            
            &--label {
                display: block;
                font-size: 12px;
                font-weight: normal;
            }
        }

        &--bid-ask-section {
            position: relative;
            width: 33.3333%;
            line-height: 17px;

            &.BID {
                color: #90eaea;
            }
            &.ASK {
                color: #fd818a;
            }
            &--label {
                font-size: 12px;
            }
            &--pricing {
                font-weight: bold;
                font-size: 13px;
            }
        }

        &--remove-button {
            position: absolute;
            right: 7px;
            font-size: 20px;
            padding: 0;
            width: 18px;
            background: none;
            color: #ddd;
            top: 5px;
            display: none;

            &:hover {
                color: white;
            }
        }

        &:hover {
            .pricing-container--item--remove-button {
                display: block;
            }
        }
    }
}

.symbol-popup {
    background: #022627eb;
    overflow: auto;
    height: auto;
    box-shadow: 0px 0px 3px #011918;

    &--title {
        position: sticky;
        z-index: 2;
        top: 0px;
        background: #2d6565;
        font-size: 13px;
        font-weight: bold;
        color: #a6bdbd;
        padding: 5px 9px;
        >span {
            color: white;
        }
    }

    &--trigger {
        cursor: pointer;
        &:hover {
            color: white;
        }
    }

    &--header {
        position: relative;
        padding: 0px 9px;
        font-weight: bold;
    }

    &--name {
        font-size: 14px;
        color: #96d0cf;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &--pricing {
        &--timestamp {
            font-size: 12px;
            color: #ddd;
            margin-top: 1px;
        }

        &--body {
            position: relative;
            display: flex;
            margin-top: 5px;
        }

        &--section {
            position: relative;
            width: 33.3333%;

            &.bid {
                color: #8feaea;
            }

            &.ask {
                color: #fd8089;
            }

            &.last-price {
                color: white;
            }
        }

        &--name {
            font-size: 12px;
            font-weight: normal;
        }

        &--value {
            font-size: 13px;
        }
    }

    &--toggle-detail-button {
        position: absolute;
        right: 9px;
        top: -1px;
        background: none;
        border: 1px solid #ddd;
        font-size: 12px;
        color: #ddd;
        font-weight: bold;
        padding: 2px 5px;
        width: 77px;

        &:hover {
            background: #ddd;
            color: #0a393c;
        }
    }

    &--tabs {
        position: relative;
        display: flex;
        margin: 13px 9px 0px;
        border: 1px solid #82b7b9;
        border-radius: 2px;
    }

    &--tab-button {
        width: 50%;
        font-size: 12px;
        background: none;
        color: #ddd;
        font-weight: bold;
        height: 23px;
        padding: 0px;
        border-right: 1px solid #82b7b9;

        &:last-child {
            border-right: none;
        }

        &.active {
            background: #82b7b9;
            color: #042729;
        }
    }

    &--body {
        position: relative;
        overflow: auto;
        padding: 9px 0px;
    }

    &--account-balance, &--positions {
        &--header {
            position: relative;
            border-bottom: 1px solid #99b3b3;
            color: #c4dcdc;
        }
        &--title {
            font-size: 17px;
            font-weight: bold;
            line-height: 25px;
        }
        &--toggle-detail-button {
            position: absolute;
            right: 0px;
            bottom: 3px;
            font-size: 12px;
            font-weight: bold;
            padding: 1px 6px;
            background: transparent;
            color: #7d9e9e;
            border: 1px solid;
            min-width: 73px;
            white-space: nowrap;

            &:hover {
                color: #aebfbf;
            }
        }
    }

    &--account-balance {
        position: relative;

        &--body {
            position: relative;
            max-height: 490px;
            overflow-y: auto;
        }

        .account-balance {
            &--name {
                display: none;
            }
    
            &--spot, &--margin, &--cross-margin, &--cross, &--future, &--swap {
                &--title {
                    display: none;
                }
                &--item {
                    position: relative;
                    margin: 2px 0px 16px;
                }
            }

            .margin-account-balance-item--pair-table thead {
                background: #1a6667;
            }

            &--empty-message {
                margin-top: 7px;
            }
        }

        .account-asset-item {
            margin: 2px 0px 16px;
        }
    }

    &--positions {
        &--notional-sum {
            padding: 5px;
            line-height: 15px;
            border-bottom: 1px dashed #567979;

            &--name {
                float: left;
                font-size: 13px;
                color: #d0e6e6;
            }
            &--value {
                float: right;
                font-size: 15px;
                font-weight: bold;
                >span {
                    &.positive {
                        color: #84fbf9;
                    }
                    &.negative {
                        color: #fd818a;
                    }
                }
            }
        }

        &--sub-title {
            font-size: 13px;
            font-weight: bold;
            margin-top: 5px;
            border-bottom: 1px dashed #567979;
            color: #bbd1d1;
        }

        &--portfolios {
            position: relative;
            margin-top: 5px;
            border-bottom: 1px solid #99b3b3;

            &--item {
                float: left;
                display: inline-flex;
                font-weight: bold;
                margin-right: 10px;
                margin-bottom: 5px;

                >label {
                    margin-right: 5px;
                    line-height: 25px;
                }
            }
        }

        &--list {
            position: relative;
            max-height: 400px;
            overflow-y: auto;
        }

        &--item {
            position: relative;
            margin: 2px 0px 16px;

            .position-item {
                &--header {
                    padding: 1px 5px 4px;
                    &--info {
                        margin-bottom: 0px;
                    }
                }
                &--direction-table, &--data {
                    margin: 7px 5px 0px;
                }
                &--pricing-wrapper {
                    margin: 0px 5px;
                }
                &--direction-table, &--data, &--pricing-wrapper {
                    width: calc(100% -10px);
                }
            }
        }

        &--empty-message {
            text-align: center;
            font-size: 13px;
            font-weight: bold;
            margin-top: 7px;
        }
    }

    &--funding-rates {
        &--title {
            position: relative;
            border-bottom: 1px solid #99b3b3;
            color: #c4dcdc;
            font-size: 17px;
            font-weight: bold;
            line-height: 25px;

            &--last-update-time {
                font-size: 12px;
                float: right;
                color: #b9caca;
            }
        }
        .symbol-funding-rates {
            padding-top: 5px;
            &--header {
                padding: 0px 9px;
                font-weight: bold;
            }
            &--quote-selectors {
                margin: 0px;
            }
            &--table {
                width: 100%;

                thead {
                    background: #175154;
                }

                tbody {
                    td {
                        border-color: #175154;
                    }
                }
            }
        }
    }

    &--row-1 {
        display: flex;
        padding: 0px 13px;
    }

    &--account-balance, &--positions, &--funding-rates {
        width: 260px;
    }

    &--positions, &--funding-rates {
        margin-left: 15px;
    }
}

.symbol-funding-rates {
    &--header {
        position: relative;
        line-height: 23px;
        padding: 9px 7px 0px;
    }

    &--title {
        font-size: 15px;
        font-weight: bold;
        float: left;
    }

    &--last-update-time {
        font-size: 12px;
        float: right;
    }

    &--quote-selectors {
        position: relative;
        display: flex;
        margin: 0px 9px;
        height: 21px;
        border: 1px solid #82b7b9;
        border-radius: 2px;

        &--item {
            width: 50%;
            height: 100%;
            font-weight: bold;
            font-size: 12px;
            background: none;
            color: #eee;
            &:not(:last-child) {
                border-right: 1px solid #82b7b9;
            }
            &.selected {
                background: #82b7b9;
                color: #02282a;
                border-color: #02282a;
            }
        }
    }

    &--table {
        position: relative;
        width: 100%;
        font-size: 13px;
        margin-top: 5px;
        font-weight: bold;
        border-collapse: collapse;

        &--symbol-name {
            font-size: 12px;
            word-break: break-word;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                word-break: break-all;
                white-space: initial;
            }
        }

        thead {
            background: #002929;
            th {
                text-align: left;
                font-size: 12px;
                padding: 4px 5px;
                cursor: pointer;

                &.active {
                    color: #e2cb6a;
                }
            }
        }

        tbody {
            td {
                border-bottom: 1px solid #002929;
                border-right: 1px dashed #002929;
                padding: 4px 5px;

                &:first-child {
                    max-width: 105px;
                }

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    &--header {
        position: relative;
    }

    &--data {
        &--time {
            font-size: 12px;
            opacity: 0.8;
        }

        &--value {
            &.positive {
                color: #90eaea;
            }
            &.negative {
                color: #fd818a;
            }
        }
    }
}

.order-book {
    position: relative;
    width: 100%;

    &--title {
        position: relative;
        border-bottom: 1px solid #99b3b3;
        color: #c4dcdc;
        font-size: 17px;
        font-weight: bold;
        line-height: 25px;
    }

    &--timestamp {
        font-size: 12px;
        float: right;
        opacity: 0.8;
    }

    &--body {
        position: relative;
        min-width: 150px;
        margin-top: 3px;

        &--header {
            &--text {
                font-size: 13px;
                font-weight: bold;
                color: #88acad;
                &.price {
                    float: left;
                }
                &.amount {
                    float: right;
                }
            }
        }

        &--main {
            margin-top: 2px;
        }

        &--footer {
            position: relative;
            margin-top: 9px;
            &--timestamp {
                float: left;
                font-size: 13px;
                font-weight: bold;
                color: #ddd;
            }
            &--resubscribe-button {
                float: right;
                font-weight: bold;
                font-size: 13px;
                border-radius: 2px;
                background: #5e8cb5;
                color: #ddd;
                &:hover {
                    color: white;
                }
            }
        }
    }

    &--block {
        &--item {
            position: relative;
            font-size: 14px;
            font-weight: bold;
            padding: 1px 5px;
            line-height: 21px;

            &--price {
                float: left;
                cursor: pointer;
                position: relative;

                &.buy {
                    color: #8fe6e8;
                }
                &.sell {
                    color: #fd818a;
                }
                &:hover {
                    font-size: 15px;
                }
            }
            &--amount {
                position: relative;
                float: right;
                cursor: pointer;

                &:hover {
                    font-size: 15px;
                }
            }
            &--cumulative-amount-bar {
                position: absolute;
                height: 100%;
                opacity: 0.2;
                left: 0;
                top: 0px;
                &.buy {
                    background: #8fe6e8;
                }
                &.sell {
                    background: #fd818a;
                }
            }

            &:nth-child(even) {
                .order-book--block--item--cumulative-amount-bar {
                    opacity: 0.1;
                }
            }
        }
    }
}

.symbol-funding-rate-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--title {
        position: relative;
        display: block;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: 700;
        background: #0a4a4a;
        font-size: 15px;
    }

    &--search {
        position: relative;
        display: block;
        width: calc(100% - 18px);
        height: 29px;
        padding: 0px 9px;
        background: #023534;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }  

    &--menu {
        position: relative;
        height: 29px;
        line-height: 29px;

        &--tags {
            position: relative;
            z-index: 2;
            float: left;
            margin-left: 8px;
            max-width: 160px;
        }
    }

    &--info {
        position: relative;
        height: 21px;
        line-height: 21px;
        margin-top: 3px;
    }

    &--last-update-time {
        font-size: 12px;
        font-weight: bold;
        margin-left: 8px;
        color: #c8dada;
    }

    &--toggle-unkown-symbol-button {
        position: absolute;
        right: 38px;
        background: no-repeat;
        color: #a9cccc;
        font-size: 19px;
        padding: 0px;
        transform: translateY(5px);

        &:hover {
            color: white;
        }

        &--popup {
            background: #1e7273d6;
            font-size: 13px;
            font-weight: bold;
            padding: 4px 8px;
            border-radius: 2px;
            box-shadow: 0px 0px 3px #000a0a;
        }
    }

    &--body {
        position: relative;
        overflow: auto;
        .symbol-funding-rates {
            &--table {
                margin-top: 0px;
                thead {
                    th {
                        z-index: 1;
                        position: sticky;
                        top: 0px;
                        background: #002a2a;
                    }
                }
            }
        }
    }
}

.cumulative-funding-rate-popup {
    background: #022323f2;
    width: 680px;
    box-shadow: 0px 0px 3px black;
    border-radius: 5px;
    overflow: hidden;

    &--header {
        line-height: 25px;
        background: #0f6060;

        &--title {
            font-size: 13px;
            font-weight: bold;
            margin-left: 11px;
        }
    }

    &--trigger-button {
        position: absolute;
        right: 0px;
        padding: 0px;
        width: 21px;
        height: 21px;
        margin: 4px;
        background: #267f80;
        color: #eee;
        font-size: 17px;
        border-radius: 2px;
        opacity: 0.8;
        margin-right: 6px;

        &:hover {
            opacity: 1.0;
        }

        >svg {
            transform: translate(1px, 3px);
        }
    }
}

.symbol-funding-rate-history {
    position: relative;

    &--header {
        position: relative;
        padding: 7px 11px 1px;
        border-bottom: 1px solid #1e5454;

        &--block {
            display: table-row;

            &--name, &--body {
                display: table-cell;
                white-space: nowrap;
                padding: 5px 0px;
            }
            &--name {
                font-size: 13px;
                font-weight: bold;
                line-height: 25px;
                vertical-align: top;
            }
            &--body {
                width: 100%;
                padding-left: 15px;
            }
        }
    }

    &--time-ranges {
        display: inline-flex;
    }

    &--time-range {
        font-size: 14px;
        font-weight: bold;
        width: 85px;
        padding: 3px 0px;
        background: transparent;
        border: 1px solid #267f80;
        color: #cce2e2;

        &:not(:last-child) {
            border-right: none;
        }

        &:hover {
            color: white;
        }

        &.active {
            background: #267f80;
            color: white;
        }
    }

    &--symbols {
        &--item {
            display: flex;
            float: left;
            line-height: 23px;
            border: 1px solid #267f80;
            margin-right: 8px;
            margin-bottom: 6px;

            >span {
                font-size: 13px;
                padding: 0px 7px;
                font-weight: bold;
            }

            &--remove-button {
                height: 23px;
                width: 23px;
                background: #267f80;
                color: #ddd;
                font-size: 15px;

                &:hover {
                    color: white;
                }

                >svg {
                    transform: translate(-1px, 2px);
                }
            }

            &.has-nil-history {
                border-color: #e2cb6a;

                .symbol-funding-rate-history--symbols--item--remove-button {
                    background: #e2cb6a;
                    color: #483c0a;

                    &:hover {
                        color: white;
                    }
                }
            }
        }

        &--symbol-select, &--pair-select{
            float: left;
            width: auto;
            margin-right: 6px;
            margin-bottom: 6px;

            .search-select {
                &--current-option {
                    font-size: 13px;
                    font-weight: bold;
                    padding: 0px 9px;
                    background: transparent;
                    color: #9dbcbd;
                    border-radius: 1px;
                    line-height: 23px;
                    border: 1px solid #7da6a7;

                    &:hover {
                        background: #4f8586;
                        color: white;
                    }
                }
                &--placeholder {
                    opacity: 1.0;
                }
                &--input-icon {
                    display: none;
                }
                &--empty-message {
                    white-space: nowrap;
                }
                &--options-wrapper {
                    position: fixed;
                }
            }
        }

        &--clear-button {
            float: left;
            width: auto;
            margin-right: 6px;
            margin-bottom: 6px;
            font-size: 13px;
            font-weight: bold;
            padding: 0px 9px;
            background: transparent;
            color: #e0878f;
            border-radius: 1px;
            line-height: 23px;
            border: 1px solid #e0878f;

            &:hover {
                background: #a2545b;
                color: white;
            }
        }
    }

    &--body {
        position: relative;
        width: 100%;
        height: 420px;
        z-index: 0;
    }

    &--fetching-tag {
        position: absolute;
        right: 11px;
        font-size: 12px;
        font-weight: bold;
        color: #f7d98f;
        top: 6px;
    }

    &--chart-canvas-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &--tooltip {
        position: absolute;
        left: 60px;
        top: 12px;
        padding: 0px 2px;
        border-radius: 2px;

        &--row {
            display: table-row;

            &--marker-wrapper, &--name, &--value {
                display: table-cell;
            }

            &--marker {
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 10px;
                box-shadow: 0px 0px 2px #032525;
            }

            &--name {
                padding: 0px 7px;
                font-size: 13px;
                font-weight: bold;
                opacity: 0.8;
            }

            &--value {
                font-size: 13px;
                font-weight: bold;
            }
        }
    }
}

.defi-lending-info-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--header {
        position: relative;
    }

    &--title {
        height: 32px;
        min-height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: bold;
        background: #0a4a4a;
        font-size: 15px;
    }
    &--search-input {
        display: block;
        width: calc(100% - 18px);
        padding: 0px 9px;
        height: 29px;
        min-height: 29px;
        background: #023534;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--body {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.defi-lending-item {
    position: relative;
    border-bottom: 1px solid #002928;
    padding: 9px 9px;

    &--header {
        line-height: 17px;
        &--symbol {
            font-size: 17px;
            font-weight: bold;
            line-height: 19px;
        }

        &--platform {
            float: right;
            font-size: 13px;
            border: 1px solid;
            padding: 0px 5px;
            border-radius: 1px;
            font-weight: bold;
            &.COMPOUND {
                color: #05d395;
            }
            &.DYDX {
                color: #ddd;
            }
        }
    }

    &--rows {
        display: table;
        width: 100%;
        margin-top: 5px;
    }
    &--row {
        display: table-row;
        color: #c7d8d8;
        &--name, &--value {
            display: table-cell;
            white-space: nowrap;
            font-size: 14px;
        }
        &--name {
            opacity: 0.8;
            font-size: 13px;
        }
        &--value {
            width: 100%;
            font-weight: bold;
            text-align: right;
        }
    }
    table {
        width: 100%;
        white-space: nowrap;
        margin-top: 7px;
        border-collapse: collapse;
        th {
            font-size: 12px;
            background: #012e2e;
            padding: 2px 2px;
            color: #abc5c5;
        }

        td {
            font-size: 14px;
            text-align: center;
            font-weight: bold;
            padding: 2px 0px;
        }
    }
}

.symbol-multiple-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #043131;
    padding: 0px 0px;
    box-shadow: 0px 0px 3px black;
    font-weight: bold;
    overflow: hidden;
    max-width: 900px !important;
    z-index: 101 !important;

    &--trigger {
        position: relative;
        height: 25px;
        line-height: 23px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        background: transparent;
        border: 1px solid #488686;
        border-radius: 3px;
        color: white;

        &:hover {
            background: #488686;
        }
    }

    &--header {
        background: #0a4848;
        padding: 4px 9px;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        min-height: 20px;
    }

    &--main {
        position: relative;
        padding: 11px 9px;
        height: 100%;
        overflow: auto;
    }

    &--config-row {
        padding: 8px 15px;
        border-bottom: 1px solid #0c5656;
    }

    &--toggle-all-button {
        margin-top: 2px;
        padding: 0px 11px;
        height: 32px;
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
        border-radius: 3px;
        border: 1px solid #7eafaf;
        background: transparent;
        color: #7eafaf;

        &:hover {
            background: #094242;
        }
    }

    &--search-string {
        position: relative;
        height: 32px;
        margin-left: 10px;
        width: 300px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        cursor: text;
        border-radius: 3px;
        background: #022020;
        color: white;
    }

    &--empty-result {
        position: relative;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    &--portfolios {
        position: relative;
        padding: 5px 20px 10px;
        overflow: auto;
    }

    &--item {
        position: relative;
        display: inline-flex;
        line-height: 24px;
        margin-right: 21px;
        font-size: 14px;

        >span {
            &.not-exist-warning {
                text-decoration: line-through;
                color: #fd818a;
            }
        }

        .checkbox {
            width: 20px;
            height: 20px;
            transform: translateY(2px);
            margin-left: 7px;

            >svg {
                position: absolute;
            }
        }
    }

    &--portfolio {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &--header {
            border-bottom: 1px solid #517070;
            padding: 5px 0px;
            line-height: 20px;
            font-size: 14px;

            >label {
                font-size: 16px;
                color: white;
            }

            >button {
                padding: 0px 7px;
                height: 24px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 3px;
                border: 1px solid #7eafaf;
                background: transparent;
                color: #7eafaf;
                margin-left: 10px;

                &:hover {
                    background: #094242;
                }
            }
        }

        &--main {
            position: relative;
            margin-top: 5px;
        }

        &--item {
            position: relative;
            display: inline-flex;
            line-height: 24px;
            margin-right: 21px;
            font-size: 14px;

            >span {
                &.not-exist-warning {
                    text-decoration: line-through;
                    color: #fd818a;
                }
            }

            .checkbox {
                width: 20px;
                height: 20px;
                transform: translateY(2px);
                margin-left: 7px;

                >svg {
                    position: absolute;
                }
            }
        }
    }
}