.account-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar{
        height: 0px;
    }

    &--header {
        position: relative;
        height: 32px;
        min-height: 32px;
        line-height: 32px;
    }

    &--title {
        text-align: left;
        font-weight: bold;
        background: #0a4a4a;
        font-size: 15px;
        position: relative;
        min-height: 32px;
        line-height: 32px;
        text-indent: 9px;
    }

    &--toggle-detail-button {
        position: absolute;
        background: none;
        font-size: 12px;
        color: #a3d7d8;
        right: 40px;
        border: 1px solid;
        border-radius: 1px;
        font-weight: bold;
        width: 81px;
        white-space: nowrap;

        &:hover {
            background: #a3d7d8;
            border-color: #a3d7d8;
            color: #031f1f;
        }
    }

    &--search-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0;
        width: 33px;
        font-size: 13px;
        background: none;
        color: #82b3b4;
        border-left: 1px solid #063a3a;

        &:hover, &.active {
            color: #97c9ca;
        }

        >svg {
            margin-top: 5px;
            margin-right: 1px;
        }
    }

    &--search-input {
        position: relative;
        display: block;
        width: calc(100% - 18px);
        padding: 0px 9px;
        background: #053a39;
        height: 29px;
        min-height: 29px;
        font-size: 12px;
        font-weight: bold;
        color: #eee;
        cursor: text;
    }

    &--tabs {
        display: flex;
        overflow-y: hidden;
        overflow-x: auto;
        position: relative;
        height: 29px;
        min-height: 29px;

        &--button {
            height: 100%;
            white-space: nowrap;
            background: #012e2e;
            font-size: 12px;
            color: #eee;
            font-weight: bold;
            border-left: 1px solid #267f80;
            padding: 0px 11px;
            width: 50%;

            &:hover {
                color: white;
            }

            &.active {
                background: #267f80;
                color: white;
            }

            &:first-child {
                border-left: none;
            }
        }
    }

    &--body {
        position: relative;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.account-balance-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--switches {
        position: relative;
        border-top: 1px solid #001213;
        display: flex;
    }

    &--switch {
        width: 25%;
        border-right: 1px solid #001213;
        font-size: 12px;
        background: #022d2e;
        border-bottom: 1px solid #001213;
        color: #a3cbcc;
        font-weight: bold;
        padding: 5px 0px;
        line-height: 15px;

        &:last-child {
            border-right: none;
        }

        &:hover {
            background: #0c4d4e;
        }

        &.active {
            background: #257f80;
            color: white;

            &.spot {
                background: #5d8cb5;
                color: #193b58;
            }

            &.margin {
                background: #bfb462;
                color: #4a451e;
            }

            &.future {
                background: #c57593;
                color: #5f2138;
            }

            &.swap {
                background: #76aa7f;
                color: #1e3322;
            }
        }
    }

    &--items {
        position: absolute;
        top: 27px;
        bottom: 0px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--item {
        position: relative;
        padding: 0px 0px 9px;
        border-bottom: 1px solid #002928;
    }

    &--content-index {
        position: fixed;
        left: 308px;
        z-index: 2;
        top: 60px;
        max-height: 60%;
        overflow-y: auto;
    }
}

.account-balance {
    position: relative;

    &--name {
        font-size: 14px;
        font-weight: bold;
        text-indent: 9px;
        color: #0e2829;
        background: #82b7b9;
        line-height: 21px;
        position: sticky;
        top: 0px;
        z-index: 3;
    }

    &--spot, &--margin, &--cross-margin, &--cross, &--future, &--swap {
        &--title {
            font-size: 12px;
            font-weight: bold;
            position: sticky;
            top: 21px;
            background: #022d2e;
            line-height: 21px;
            border-top: 1px solid #011617;
            text-indent: 9px;
            z-index: 2;
            box-shadow: 0px 2px 2px #022a2b;
        }

        &--item {
            position: relative;
            margin: 3px 5px 11px;
        }
    }

    &--spot--title {
        background: #3d6d87;
        color: #051523;
    }

    &--margin--title {
        background: #92955a;
        color: #2f2c10;
    }

    &--cross-margin--title {
        background: #dda561;
        color: #261602;
    }

    &--cross--title {
        background: #c79c7e;
        color: #24150b;
    }

    &--future--title {
        background: #92657b;
        color: #401424;
    }

    &--swap--title {
        background: #649773;
        color: #0c1d12;
    }

    &--empty-message {
        font-weight: bold;
        font-size: 13px;
        text-align: center;
    }
}

.spot-account-balance-item {
    &--header {
        position: relative;
        line-height: 19px;
        border-bottom: 1px solid #277e80;
        padding: 3px 5px;

        &--info-bar {
            display: inline-flex;
            font-weight: bold;
            line-height: 17px;
            padding-bottom: 2px;
            width: 100%;

            &--account-type {
                font-size: 12px;
                background: #5d8cb5;
                color: #051523;
                padding: 0px 6px;
                border-radius: 1px;
            }

            &--timestamp {
                font-size: 13px;
                margin-left: 5px;
                color: #95b0b1;
            }
        }
    }

    &--coin {
        float: left;
        font-size: 14px;
        font-weight: bold;
        color: white;
    }

    &--timestamp {
        float: right;
        font-size: 12px;
    }

    &--rows {
        display: table;
        position: relative;
        margin: 2px 0px 0px;
        font-size: 13px;
        width: calc(100% - 10px);
        padding: 1px 5px 5px;
    }

    &--row {
        display: table-row;
        font-size: 13px;

        &--name, &--value {
            display: table-cell;
        }

        &--name {
            text-align: left;
            color: #c7d8d7;
        }

        &--value {
            text-align: right;
            font-weight: bold;
            padding-top: 1px;
            
            &.positive {
                color: #90eaea;
            }

            &.negative {
                color: #fd818a;
            }
        }
    }
}

.wallet-account-balance-item {
    &--header {
        position: relative;
        line-height: 19px;
        border-bottom: 1px solid #277e80;
        padding: 3px 5px;

        &--info-bar {
            display: inline-flex;
            font-weight: bold;
            line-height: 17px;
            padding-bottom: 2px;
            width: 100%;

            &--account-type {
                font-size: 12px;
                background: lightblue;
                color: #051523;
                padding: 0px 6px;
                border-radius: 1px;
            }

            &--timestamp {
                font-size: 13px;
                margin-left: 5px;
                color: #95b0b1;
            }
        }
    }

    &--coin {
        float: left;
        font-size: 14px;
        font-weight: bold;
        color: white;
    }

    &--timestamp {
        float: right;
        font-size: 12px;
    }

    &--rows {
        display: table;
        position: relative;
        margin: 2px 0px 0px;
        font-size: 13px;
        width: calc(100% - 10px);
        padding: 1px 5px 5px;
    }

    &--row {
        display: table-row;
        font-size: 13px;

        &--name, &--value {
            display: table-cell;
        }

        &--name {
            text-align: left;
            color: #c7d8d7;
        }

        &--value {
            text-align: right;
            font-weight: bold;
            padding-top: 1px;
            
            &.positive {
                color: #90eaea;
            }

            &.negative {
                color: #fd818a;
            }
        }
    }
}

.margin-account-balance-item {

    &:hover {
        .margin-account-balance-item--token-transfer-popup--trigger-button {
            opacity: 1.0 !important;
        }
    }

    &--header {
        position: relative;
        line-height: 19px;
        border-bottom: 1px solid #277e80;
        padding: 3px 5px;

        &--info-bar {
            display: inline-flex;
            font-weight: bold;
            line-height: 17px;
            padding-bottom: 2px;
            width: 100%;

            &--account-type {
                font-size: 12px;
                background: #bfb462;
                color: #4a451e;
                padding: 0px 6px;
                border-radius: 1px;
            }

            &--account-leverage {
                font-size: 12px;
                margin-left: 4px;
                background: #936798;
                color: #ddd;
                padding: 0px 5px;
                border-radius: 1px;
            }

            &--timestamp {
                font-size: 13px;
                margin-left: 5px;
                color: #95b0b1;
            }
        }
    }

    &--timestamp {
        float: right;
        font-size: 12px;
    }

    &--pair {
        float: left;
        font-size: 14px;
        font-weight: bold;
        color: white;

        &--account-name {
            &.clickable {
                text-decoration: underline dotted;
                &:hover {
                    text-decoration: underline solid;
                }
            }
        }
    }

    &--pair-table {
        position: relative;
        width: auto;
        border-collapse: collapse;
        text-align: center;
        margin: 5px 5px 0px;
        font-size: 12px;
        border-bottom: 1px dashed #025050;

        thead {
            background: #012e2e;
            th {
                padding: 2px 0px;
            }
        }

        tbody {
            th {
                font-weight: normal;
                color: #d0e6e6;
                text-align: left;
                white-space: nowrap;
                padding: 1px 0px;
            }
            td {
                font-size: 13px;
                font-weight: bold;
                padding: 1px 0px;
                padding-bottom: 1px;
                width: 40%;
            }
            tr {
                &:first-child {
                    th, td {
                        padding-top: 5px;
                    }
                }

                &:last-child {
                    th, td {
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }

    &--pricing-wrapper {
        position: relative;
        margin: 0px 5px;
        display: flex;
    }

    &--inline-data {
        position: relative;
        width: 25%;

        &--name {
            font-size: 12px;
            color: #afcece;
            white-space: nowrap;
        }

        &--value {
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            color: #c7dada;

            &.highlight {
                color: #e2cb6a;
            }
        }

        &.ratio {
            .margin-account-balance-item--inline-data--name {
                border-bottom: 1px dashed;
            }
        }
    }

    &--ratio-popup {
        max-width: 308px;
        box-shadow: 0px 0px 3px #012b2b;
        background: #87a2a2ed;
        color: #072a2b;
        border-radius: 2px;
        font-size: 13px;
        padding: 5px 9px;
    }

    &--token-transfer-popup {
        
        &--trigger-button {
            position: absolute;
            right: 5px;
            background: none;
            color: #7eddde;
            font-size: 15px;
            opacity: 0;

            &:hover {
                color: white;
            }
        }

        &--main {
            margin-left: 5px;
            position: relative;
            padding: 8px 14px 15px;
            max-width: 270px;
            background: #032d2c;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
        }
    }

    &--actions {
        position: relative;
        margin: 3px 0px;
    }

    &--buttons {
        position: relative;
        margin: 3px 0px 0px;
    }

    &--action-button, &--button {
        float: left;
        width: 50%;
        font-size: 13px;
        font-weight: bold;
        padding: 0px;
        line-height: 21px;
        background: none;
        border: 1px solid;

        &.BORROW {
            color: #e4c26e;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;

            &:hover {
                background: #e4c26e0f;
            }

            &.active {
                background: #e4c26e;
                color: #3c2e0a;
                border-color: #e4c26e;
            }
        }
        &.REPAY {
            color: #94d094;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;

            &:hover {
                background: #94d0940f;
            }

            &.active {
                border-color: #94d094;
                background: #94d094;
                color: #0b2d0b;
            }
        }
    }

    &--button {
        float: left;
        width: calc(50% - 3px);
        font-size: 13px;
        font-weight: bold;
        padding: 0px;
        line-height: 27px;
        background: none;
        border: none;
        border-radius: 2px;
        
        &.cancel {
            margin-right: 6px;
            background: #ddd;
            color: #033c3b;

            &:hover {
                background: white;
            }
        }
        &.confirm {
            background: #5d8cb4;
            color: #ddd;

            &:not(:disabled) {
                &:hover {
                    background: #5e99cb;
                    color: white;
                }
            }
        }
    }

    &--amount-input {
        width: calc(100% - 2px);
        padding: 0px;
        cursor: text;
        background: #0f6160;
        font-size: 13px;
        line-height: 23px;
        text-indent: 5px;
        color: white;
        font-weight: bold;
        border-radius: 2px;
    }

    &--lending-message {
        background: #0a5a59;
        padding: 3px 3px;
        font-size: 13px;
        border-radius: 2px;
        margin: 3px 0px;
        text-align: center;
    }
}

.future-account-balance-item {
    &--header {
        position: relative;
        line-height: 19px;
        border-bottom: 1px solid #277e80;
        padding: 3px 5px;

        &--info-bar {
            display: inline-flex;
            font-weight: bold;
            line-height: 17px;
            padding-bottom: 2px;
            width: 100%;

            &--account-type {
                font-size: 12px;
                background: #c47593;
                color: #401424;
                padding: 0px 6px;
                border-radius: 1px;
            }

            &--timestamp {
                font-size: 13px;
                margin-left: 5px;
                color: #95b0b1;
            }
        }
    }

    &--timestamp {
        float: right;
        font-size: 12px;
    }

    &--coin {
        float: left;
        font-size: 14px;
        font-weight: bold;
        color: white;
    }

    &--rows {
        display: table;
        position: relative;
        margin: 2px 0px 0px;
        font-size: 13px;
        width: calc(100% - 10px);
        border-bottom: 1px dashed #025050;
        padding: 1px 5px 5px;
    }

    &--row {
        display: table-row;
        font-size: 13px;

        &--name, &--value {
            display: table-cell;
        }

        &--name {
            text-align: left;
            color: #c7d8d7;
        }

        &--value {
            text-align: right;
            font-weight: bold;
            padding-top: 1px;
            
            &.positive {
                color: #90eaea;
            }

            &.negative {
                color: #fd818a;
            }
        }
    }

    &--summary {
        position: relative;
        margin: 0px 5px;
        display: flex;
    }

    &--inline-data {
        position: relative;
        width: 33.333%;

        &--name {
            font-size: 12px;
            color: #afcece;
            white-space: nowrap;
        }

        &--value {
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            color: #c7dada;

            &.highlight {
                color: #e2cb6a;
            }
        }
    }
}

.swap-account-balance-item, .cross-margin-account-balance-item, .cross-account-balance-item {
    &--header {
        position: relative;
        line-height: 19px;
        border-bottom: 1px solid #277e80;
        padding: 3px 5px;

        &--info-bar {
            display: inline-flex;
            font-weight: bold;
            line-height: 17px;
            padding-bottom: 2px;
            width: 100%;

            &--account-type {
                font-size: 12px;
                background: #95d6a1;
                color: #1e4425;
                padding: 0px 6px;
                border-radius: 1px;
            }

            &--timestamp {
                font-size: 13px;
                margin-left: 5px;
                color: #95b0b1;
            }
        }
    }

    &--timestamp {
        float: right;
        font-size: 12px;
    }

    &--coin {
        float: left;
        font-size: 14px;
        font-weight: bold;
        color: white;
    }

    &--rows {
        display: table;
        position: relative;
        margin: 2px 0px 0px;
        font-size: 13px;
        width: calc(100% - 10px);
        border-bottom: 1px dashed #025050;
        padding: 1px 5px 5px;
    }

    &--row {
        display: table-row;
        font-size: 13px;

        &--name, &--value {
            display: table-cell;
        }

        &--name {
            text-align: left;
            color: #c7d8d7;
        }

        &--value {
            text-align: right;
            font-weight: bold;
            padding-top: 1px;
            
            &.positive {
                color: #90eaea;
            }

            &.negative {
                color: #fd818a;
            }
        }
    }

    &--summary {
        position: relative;
        margin: 0px 5px;
        display: flex;
    }

    &--inline-data {
        position: relative;
        width: 33.333%;

        &--name {
            font-size: 12px;
            color: #afcece;
            white-space: nowrap;
        }

        &--value {
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            color: #c7dada;

            &.highlight {
                color: #e2cb6a;
            }
        }
    }
}

.cross-margin-account-balance-item {
    position: relative;

    &:hover {
        .cross-margin-account-balance-item--token-transfer-popup--trigger-button {
            opacity: 1.0 !important;
        }
    }
    
    &--header--info-bar--account-type {
        background: #dda561;
        color: #261602;
    }

    &--token-transfer-popup {
        
        &--trigger-button {
            position: absolute;
            right: 0px;
            background: none;
            color: #7eddde;
            font-size: 15px;
            opacity: 0;

            &:hover {
                color: white;
            }
        }

        &--main {
            margin-left: 5px;
            position: relative;
            padding: 8px 14px 15px;
            max-width: 270px;
            background: #032d2c;
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;
        }
    }

    &--lending {
        margin-top: 8px;

        &--title {
            font-size: 14px;
            font-weight: bold;
            border-bottom: 1px solid #689799;
            line-height: 20px;
        }

        .search-select {
            &--options {
                max-height: 93px;
            }
        }
    }
}

.cross-account-balance-item {
    position: relative;

    &--header--info-bar--account-type {
        background: #c79c7e;
        color: #24150b;
    }
}

.account-asset-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--body {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--item {
        position: relative;
        margin: 3px 0px 15px;

        &--header {
            position: sticky;
            top: 0px;
            line-height: 19px;
            border-bottom: 1px solid #277e80;
            padding: 3px 9px;
            background: #013c3c;
            z-index: 1;
        }

        &--account-name {
            float: left;
            font-size: 14px;
            font-weight: bold;
            color: white;
        }

        &--timestamp {
            float: right;
            font-size: 12px;
        }

        &--asset-values {
            position: relative;
            padding: 0px 9px;
        }

        &--asset {
            display: table-row;
            font-size: 13px;
        }

        &--asset-name, &--asset-value {
            display: table-cell;
            padding: 2px 0px;
            color: #c7d8d8;
        }

        &--asset-value {
            color: #ddd;
            width: 100%;
            text-align: right;
            font-weight: bold;
        }
    }

    &--content-index {
        position: fixed;
        left: 308px;
        z-index: 2;
        top: 60px;
        max-height: 60%;
        overflow-y: auto;
    }
}

.account-popup {
    width: 280px;
    background: #022627eb;
    overflow: hidden;
    margin: 5px 0px;
    padding: 7px 0px;
    height: auto;
    box-shadow: 0px 0px 3px #011918;

    &--trigger-name {
        cursor: pointer;
        &:hover {
            color: white;
        }
    }

    &--header {
        position: relative;
        padding: 0px 9px;
        font-weight: bold;
    }

    &--name {
        font-size: 16px;
        color: #96d0cf;
    }

    &--host-list {
        font-size: 12px;
        color: #8babad;
        line-height: 13px;
    }

    &--toggle-detail-button {
        position: absolute;
        right: 9px;
        top: 2px;
        background: none;
        border: 1px solid #ddd;
        font-size: 12px;
        color: #ddd;
        font-weight: bold;
        padding: 2px 5px;
        width: 77px;
        white-space: nowrap;

        &:hover {
            background: #ddd;
            color: #0a393c;
        }
    }

    &--tabs {
        position: relative;
        display: flex;
        margin: 9px 9px 0px;
        border: 1px solid #82b7b9;
        border-radius: 2px;
    }

    &--tab-button {
        width: 33.3333%;
        font-size: 12px;
        background: none;
        color: #ddd;
        font-weight: bold;
        height: 23px;
        padding: 0px;
        border-right: 1px solid #82b7b9;

        &:last-child {
            border-right: none;
        }

        &.active {
            background: #82b7b9;
            color: #042729;
        }
    }

    &--body {
        position: relative;
        height: 300px;
        overflow: auto;
        margin-top: 9px;
    }

    &--positions {
        &--item {
            position: relative;
            margin-bottom: 9px;

            .position-item {
                &--header--info {
                    margin-bottom: 0px;
                }
            }
        }

        &--empty-message {
            text-align: center;
            font-size: 13px;
            font-weight: bold;
        }
    }

    .account-balance {
        &--name {
            display: none;
        }

        &--spot, &--margin, &--cross-margin, &--cross, &--future, &--swap {
            &--title {
                top: 0px;
            }
        }
    }

    .account-asset-item {
        &--header {
            padding: 4px 9px;
        }

        &--asset-name, &--asset-value {
            padding: 1px 9px;
        }
    }

    &--profiles {
        position: relative;

        &--item {
            position: relative;
            line-height: 19px;
            border-bottom: 1px solid #2c4d4e;
            padding: 3px 9px;
            font-size: 13px;
            font-weight: bold;
            color: #bfd1d2;
            cursor: pointer;

            &:hover {
                color: #e5f5f7;
            }

            &:last-child {
                border-bottom: none;
            }

            &--hostname {
                float: right;
                opacity: 0.8;
                font-size: 12px;
                font-weight: normal;
            }
        }


        &--empty-message {
            text-align: center;
            font-size: 13px;
            font-weight: bold;
        }
    }
}

.account-fund-transfer-editor {
    &--body {
        position: relative;
        width: 360px;
        color: white;
        background: #074040;
        box-shadow: 0px 0px 7px #021313;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    &--header {
        position: relative;
        padding: 9px 20px;
        border-bottom: 1px solid #90b3b3;    
    }

    &--title {
        position: relative;
        float: left;
        font-size: 19px;
        font-weight: bold;
        color: #eee;
    }

    &--close-button {
        background: none;
        font-size: 18px;
        position: absolute;
        right: 13px;
        color: #ddd;
        top: 11px;

        &:hover {
            color: white;
        }
    }

    &--main {
        position: relative;
        padding: 11px 23px 20px 20px;    
    }

    &--main-body {
        position: relative;
        display: table;
        border-collapse: collapse;
        width: 100%;
    }

    &--row {
        position: relative;
        display: table-row;

        &--label, &--main {
            display: table-cell;
            padding: 7px 0px;
        }

        &--label {
            font-size: 15px;
            color: #ddd;
            font-weight: bold;
        }

        &--main {
            font-weight: bold;
            width: 100%;
            padding-left: 15px;
        }

        .search-select {
            position: relative;
            background: #488686;

            &--current-option {
                line-height: 29px;
                padding: 0px 8px;
                font-size: 15px;
                border-radius: 2px;

                &--name {
                    padding-right: 7px;
                }

                &.not-clickable {
                    background: #567979;
                }
            }

            &--search-input {
                font-weight: bold;
            }

            &--empty-message {
                font-size: 12px;
                white-space: nowrap;
            }
        }
    }

    &--account {
        .search-select {
            min-width: 170px;
        }
    }

    &--type {
        position: relative;
        width: 100%;
        display: flex;
        border: 1px solid #74a0a0;
        border-radius: 2px;

        &--item {
            position: relative;
            width: 50%;
            padding: 4px 0px;
            background: transparent;
            color: #eee;
            font-size: 13px;
            font-weight: bold;

            &.active {
                background: #74a0a0;
                color: #043838;
            }
        }
    }

    &--direction {
        position: relative;

        &--row-2 {
            display: flex;
            margin-top: 7px;
        }

        &--pair-name {
            position: relative;
            margin-left: 7px;
        }

        &--account-info {
            font-size: 12px;
            margin-top: 5px;
            color: #ddd;
        }

        &--account-available {
            float: left;

            >span {
                color: white;
                font-size: 13px;
            }
        }

        &--account-updated-time {
            float: right;
        }
    }

    &--swap-direction-button {
        position: absolute;
        font-size: 16px;
        background: none;
        color: #98b5b5;
        transform: rotate(90deg) translate(-40px, 10px);
        opacity: 0.7;

        &:hover {
            opacity: 1.0;
        }
    }

    &--amount {

        &--input {
            position: relative;
            width: 100%;
            line-height: 29px;
            background: #488686;
            padding: 0px;
            color: white;
            font-size: 15px;
            font-weight: bold;
            text-indent: 8px;
            cursor: text;
        }
    }

    &--message {
        font-size: 13px;
        margin-top: 11px;
        background: #032b2b;
        padding: 5px 10px;
        text-align: center;
        font-weight: bold;
        color: #a9bbbb;
        border-radius: 2px;
        line-height: 17px;
        margin-bottom: -7px;

        >svg {
            transform: scale(1.3) translateY(2px);
            margin-right: 6px;
            color: #9fcece;
        }

        &.alert-message {
            >svg {
                color: #dc7479;
                transform: scale(1.2) translateY(1px);
                margin-right: 6px;
            }   
        }
    }

    &--buttons {
        margin: 7px auto 0px;
        text-align: center;
    }

    &--reset-button, &--save-button {
        display: inline-block;
        font-size: 13px;
        margin: 0px 5px;
        padding: 4px 0px;
        width: 80px;
        font-weight: bold;
    }

    &--reset-button {
        color: #013b3b;
        &:hover {
            color: #478685;
        }
    }
}

.account-asset-item {
    &--header {
        padding: 4px 5px;
        border-bottom: 1px solid #277e80;
        line-height: 17px;
    }
    &--account-name {
        font-size: 14px;
        font-weight: bold;
        color: white;
        float: left;
    }
    &--timestamp {
        font-size: 13px;
        font-weight: bold;
        color: #95b0b1;
        float: right;
    }
    &--asset {
        position: relative;
        display: table-row;
        
        &:first-child {
            .account-asset-item--asset-name, .account-asset-item--asset-value {
                padding-top: 3px;
            }
        }
    }
    &--asset-name, &--asset-value {
        display: table-cell;
        padding: 1px 5px;
        font-size: 13px;
        font-weight: bold;
    }
    &--asset-name {
        color: #b0c9ca;
    }
    &--asset-value {
        width: 100%;
        text-align: right;
    }
}

.token-lending-editor {
    position: relative;
    padding: 5px 0px 0px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &--selector {
        background: #488686;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        font-weight: bold;
        color: white;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    .search-select {
        &--current-option {
            text-indent: 7px;
        }
    }

    &--account-detail {
        margin-top: 7px;
        >div {
            font-size: 15px;
            font-weight: bold;

            >span {
                float: right;
            }
        }
    }

    &--inputs {
        margin-top: 7px;
        >input {
            width: calc(100% - 20px);
            height: 30px;
            line-height: 30px;
            background: #022626;
            color: white;
            font-weight: bold;
            border: none;
            border-radius: 3px;
            cursor: text;
            padding: 0px 10px;
            font-size: 16px;
        }
    }

    &--buttons {
        position: relative;
        margin-top: 8px;
        display: inline-flex;
        width: 100%;
    }

    &--button {
        width: 50%;
        margin: 0px 5px;
        height: 30px;
        padding: 0;
        border-radius: 3px;
        font-size: 14px;
        font-weight: bold;

        &:disabled {
            opacity: 0.6;
        }

        &:first-child {
            margin-left: 0px;
        }

        &:last-child {
            margin-right: 0px;
        }

        &.borrow {
            background: #ef7e86;
            color: #220b0c;
        }

        &.repay {
            background: #99ddde;
            color: #0e2c2c;
        }
    }

    &--account-coin-info {
        position: relative;
        margin-top: 10px;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &--header {
            display: inline-flex;
            width: 100%;
            font-size: 14px;
            font-weight: bold;
            line-height: 25px;
            white-space: nowrap;
        }

        &--title {
            width: 100%;
        }

        &--checkbox {
            float: right;
            display: flex;

            .checkbox {
                margin-left: 5px;
                >svg {
                    position: absolute;
                }
            }
        }

        &--table-wrapper {
            margin-top: 5px;
            height: 100%;
            overflow: auto;

            table {
                width: 100%;
                border-collapse: collapse;
                th, td {
                    font-size: 14px;
                    font-weight: bold;
                    text-align: left;
                    line-height: 20px;

                    &.right-align {
                        text-align: right;
                    }
                }

                th {
                    border-bottom: 1px solid #849f9f;

                }

                tbody {
                    tr {
                        td {
                            color: #abcbcb;
                        }

                        &:hover {
                            td {
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        &--balance-popup {
            background: #022626d6;
            padding: 7px 10px;
        }
    }

    &--message {
        position: relative;
        margin-top: 7px;
        background: #076060;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        padding: 4px 7px;
        border-radius: 3px;
    }
}

.account-available-balance-table {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ::-webkit-scrollbar {
        height: 10px;
        background-color: #053333;
        border-radius: 5px;
        overflow: hidden;
    }

    &--header {
        position: relative;
        display: flex;
        width: 100%;
        height: 35px;
        min-height: 35px;
        line-height: 35px;
        background: #042f2e;
        font-weight: bold;
        z-index: 2;
        white-space: nowrap;

        &--title {
            font-size: 19px;
            margin-left: 13px;
        }

        &--criterias {
            position: relative;
            margin-left: 17px;
            display: flex;
        }

        &--criteria-item {
            display: flex;
            white-space: nowrap;
            margin-right: 15px;
            >span {
                opacity: 0.8;
                margin-right: 7px;
                font-size: 14px;
            }
            .search-select {
                &--options-wrapper {
                    background: #044444;
                    box-shadow: black 0px 0px 5px;
                }
                &--current-option {
                    padding-right: 21px;
                }
                &--input-icon {
                    right: 0px;
                    opacity: 0.7;
                }
            }
        }

        &--toggle-group-button, &--reset-button  {
            background: #073e3d;
            color: #ddd;
            font-weight: bold;
            padding: 0px 13px;
            font-size: 14px;
            white-space: nowrap;
            border-right: 1px solid #0a4a4a;
            >svg {
                display: inline-block;
                font-size: 15px;
                margin-left: 3px;
                transform: translateY(3px);
            }

            &:hover {
                background: #054e4d;
                color: white;
            }
        }

        &--search-input {
            display: block;
            width: 100%;
            background: transparent;
            text-indent: 11px;
            font-size: 14px;
            font-weight: bold;
            color: white;
            cursor: text;
        }
    }

    &--main {
        position: relative;
        width: calc(100%-3px);
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        padding-right: 3px;
        
        .ReactVirtualized__Table__headerRow {
            background: #081a1b;
    
            &:hover {
                background: #081a1b !important;
            }
        }
    
        .ReactVirtualized__Table__rowColumn, .ReactVirtualized__Table__headerColumn {
            margin: 0px;

            &:first-child {
                margin-left: 13px !important;
            }
        }
    }

    &--table {

        &--no-content {
            position: relative;
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #a9cccb;
        }

        &--header {
            font-size: 13px;
            text-transform: initial;
            display: flex;
            margin-right: 0px !important;
            color: white;
            cursor: default !important;
            line-height: 19px;

            >span {
                text-align: left;
            }

            &.sortable {
                cursor: pointer !important;
            }

            &.sorted {
                color: #e2cb6a;
            }
        }

        &--row {
            color: #ddd;
            &.group-stats-row {
                border-bottom: 2px solid #6b9393;
                border-top: 1px solid #6b9393;
                background: #053434;
                cursor: pointer;
                z-index: 2;
                
                &:hover {
                    background: #083c3c;
                }
    
                >.ReactVirtualized__Table__rowColumn {
                    overflow: visible !important;
                }
            }
            
            &.item-row {
                font-weight: bold;
                font-size: 14px;
                &.even-row {
                    background: #084242;
                }
            }

            .right-align {
                text-align: right;
                flex-direction: row-reverse;
                padding-right: 20px;
            }
        }
    }

    &--group-stats-row {
        display: flex;
        align-items: center;
        font-weight: bold;

        &--identity {
            font-size: 18px;
            margin-right: 20px;
            min-width: 180px;

            >span {
                font-size: 16px;
                opacity: 0.5;
            }
        }
    }

    &--stats {
        display: flex;
        align-items: center;
        font-weight: bold;

        &--item {
            &:not(:first-child) {
                margin-left: 30px;
            }
            
            >label {
                opacity: 0.7;
                cursor: inherit;
                display: block;
                font-size: 13px;
                line-height: 16px;
            }

            >span {
                display: block;
                font-size: 16px;
                line-height: 20px;
            }
        }

        &--coin-popup {
            background: #022c2c;
            box-shadow: 0px 0px 3px black;
            border-radius: 5px;
            padding: 5px 12px;
            overflow: auto;

            &--item {
                font-size: 14px;
                display: flex;
                font-weight: bold;
                line-height: 24px;

                >label {
                    width: 100%;
                    padding-right: 30px;
                }
            }

            &--trigger {
                text-decoration: underline;
                text-underline-offset: 1px;
                cursor: pointer;
                &:hover {
                    color: white;
                }
            }
        }   
    }

    &--accumulated-stats {
        position: relative;
        padding: 10px 11px;
    }
}