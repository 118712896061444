@import '../styles/responsive.scss';

.home-page {
    @keyframes showPage {
        0% {
            opacity: 0.6;
        }
        100% {
            opacity: 1;
        }
    }
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    animation: showPage ease-out 0.3s;

    &--main {
        position: absolute;
        display: inline-flex;
        width: 100%;
        top: 0;
        bottom: 28px;
    }

    &--footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 28px;
    }

    &--left {
        position: relative;
        height: 100%;
        width: auto;
        background: #023c3c;
    }

    &--center {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &--right {
        position: relative;
        height: 100%;
        width: 250px;
        min-width: 250px;
        background: #023c3c;

        @include screen-size('super-large') {
            display: none;
        }
    }
}

