.token-transfer-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &--header {
        &--main {
            position: relative;
            text-align: center;
            font-weight: bold;
            background: #0a4a4a;
            font-size: 15px;
            height: 32px;
            min-height: 32px;
            line-height: 32px;
        }

        &--tabs {
            position: relative;
            width: 100%;
        }

        &--tab {
            width: 50%;
            height: 32px;
            min-height: 32px;
            padding: 0;
            background: #012e2e;
            font-size: 12px;
            font-weight: bold;
            color: #eee;
            text-transform: capitalize;

            &.active {
                background: #1c7070;
                color: white;
            }

            &:hover {
                color: white;
            }

            &:not(:first-child) {
                border-left: 1px solid #1c7070;
            }
        }

        &--bulk-transfer-popup {
            background: #012b2bf0;
            margin-top: 5px;
            padding: 0px;
            height: calc(100% - 50px);
            border-radius: 3px;
            box-shadow: 0px 0px 3px black;

            &--trigger {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                padding: 0;
                width: 33px;
                font-size: 15px;
                background: none;
                color: #82b3b4;

                &:hover {
                    color: #97c9ca;
                }

                >svg {
                    transform: translateY(2px);
                }
            }

            &--title {
                font-size: 16px;
                line-height: 33px;
                height: 33px;
                min-height: 33px;
                text-indent: 11px;
                font-weight: bold;
                color: white;
            }

            &--body {
                position: relative;
                width: 100%;
                height: calc(100% - 33px);
            }
        }
    }

    &--body {
        padding: 5px 7px;
        height: 100%;
        overflow: hidden;
    }
}

.token-transfer-editor {
    position: relative;
    height: 100%;

    .search-select {
        position: relative;
        background: #488686;
        font-size: 16px;

        &--current-option {
            line-height: 25px;
            text-indent: 7px;
            font-size: 15px;
            color: white;

            &.not-clickable {
                background: #567979;
            }
        }

        &--input-icon {
            right: 7px;
        }
    }

    &--single-transfer {
        position: relative;

        &--main {
            position: relative;
            display: table;
        }

        &--message {
            line-height: 1.3em;
            background: #002121;
            font-size: 13px;
            margin-top: 7px;
            padding: 5px 9px;
            font-weight: bold;
            border-radius: 2px;
            text-align: center;
            word-break: break-word;
        }

        &--buttons {
            width: 100%;
            margin-top: 11px;
        }

        &--reset-button, &--confirm-button {
            width: calc(50% - 5px);
            display: inline-block;
            padding: 5px 0px;
            border-radius: 2px;
            font-weight: bold;
        }

        &--reset-button {
            margin-right: 5px;
            background: #ddd;
            color: #023d3c;

            &:hover {
                background: white;
                color: #488686;
            }
        }

        &--confirm-button {

            margin-left: 5px;
            color: #eee;
    
            &.TRANSFER {
                background: #5d8cb5;
            }
            &.WITHDRAW {
                background: #936798;
            }
    
            &:hover {
                color: white;
            }
        }

        .token-transfer-editor--transfer-account {
            &--type, &--pair-name {
                margin-top: 5px;
            }
        }

        .token-transfer-editor--transfer-account-swap {
            position: absolute;
            transform: rotate(-90deg) translate(25px, -7px);
        }

        .search-select {
            &--current-option {
                &--name {
                    display: block;
                    max-width: 165px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &--row {
        position: relative;
        display: table-row;

        &--label, &--component {
            position: relative;
            display: table-cell;
            font-weight: bold;
            padding: 5px 0px;
        }

        &--label {
            font-size: 13px;
            padding-right: 7px;
            vertical-align: top;
            line-height: 25px;
        }

        &--component {
            width: 100%;
        }
    }

    &--transfer-account {
        position: relative;
        width: 100%;
        font-weight: bold;

        &--available {    
            position: relative;
            width: 100%;
            font-size: 14px;
            line-height: 18px;
            margin-top: 5px;

            &--item {
                position: relative;
                display: flex;
                width: 100%;
                white-space: nowrap;
                >label {
                    width: 100%;
                }
                >span {
                    &.clickable {
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    &--transfer-account-swap {
        background: transparent;
        color: #7ebdbd;
        font-size: 19px;

        &:hover {
            color: white;
        }
    }

    &--account-balance-popup {
        background: #123a3af2;
        box-shadow: 0px 0px 3px black;
        border-radius: 3px;
        padding: 3px 5px 5px;
        z-index: 100 !important;

        .margin-account-balance-item {
            max-width: 280px;
        }

        .cross-account-balance-item {
            min-width: 280px;
        }

        .account-asset-item {
            max-width: 250px;
        }
    }

    &--amount {
        position: relative;
        input {
            height: 25px;
            padding: 0px;
            display: block;
            cursor: text;
            background: #488686;
            text-indent: 7px;
            font-size: 15px;
            color: white;
            font-weight: bold;


            &:disabled {
                cursor: not-allowed;
                background: #567979;
            }
        }

        &--value-input, &--percent {
            position: relative;
            float: left;
            width: calc(50% - 2px);
        }

        &--value-input {
            margin-right: 2px;
        }

        &--percent {
            margin-left: 2px;
            >span {
                position: absolute;
                right: 3px;
                top: 0px;
                line-height: 25px;
            }
        }

        &--percent-input {
            width: 100%;
        }
    }

    &--bulk-transfer {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &--tabs {
            position: relative;
            border-bottom: 1px solid #267f80;
            height: 26px;
        }
    
        &--tab {
            width: 70px;
            font-size: 14px;
            font-weight: bold;
            background: none;
            border: 1px solid #267f80;
            color: #eee;
            border-bottom: none;
            height: 100%;
    
            &:not(:last-child) {
                border-right: none;
            }
    
            &:hover {
                color: white;
            }
    
            &.active {
                background: #267f80;
                color: white
            }
        }

        &--header {
            margin: 0px 11px;
            z-index: 100;

            .token-transfer-editor--token {
                width: 230px;
            }
    
            .token-transfer-editor--transfer-account {
                &--main {
                    display: flex;
                }
    
                &--name {
                    width: 230px;
                    min-width: 230px;
                }
    
                &--type {
                    width: 230px;
                    min-width: 230px;
                }
    
                &--pair-name {
                    width: 120px;
                    min-width: 120px;
                }
    
                &--type, &--pair-name {
                    margin-left: 10px;
                }
            }
        }

        &--config, &--unified-control {
            margin-top: 3px;

            .token-transfer-editor--row {
                &--label {
                    min-width: 82px;
                }
                &--component {
                    width: auto;
                }
            }
        }

        &--unified-control {
            display: flex;

            .token-transfer-editor--row {
                &:not(:last-child) {
                    margin-right: 15px;
                }
            }
        }

        &--body {
            position: relative;
            height: 100%;
            overflow: auto;
            padding: 0px 11px 100px;
            margin-top: 5px;
            font-weight: bold;

            .token-transfer-editor--transfer-account {        
                &--name, &--type, &--pair-name {
                    min-width: 185px;
                    font-weight: bold;
                }
    
                &--type, &--pair-name {
                    margin-top: 5px;
                }
            }

            table {
                position: relative;
                width: 100%;
                border-collapse: collapse;
                font-weight: bold;

                thead {
                    th {
                        position: sticky;
                        top: 0px;
                        padding: 1px 5px;
                        z-index: 2;
                        background: #001515;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
                tbody {
                    tr {
                        &:hover {
                            .token-transfer-editor--bulk-transfer--remove {
                                opacity: 1.0;
                            }
                        }
                    }
                    td {
                        padding: 11px 5px;
                        vertical-align: top;
                        border-bottom: 1px dashed #3c7575;
                    }
                }
            }
        }

        &--summary {
            display: flex;
            line-height: 29px;
            padding: 0px 11px;
            font-weight: bold;
            background: #033636;

            &--title {
                margin-right: 11px;
                font-size: 15px;
                opacity: 0.7;
            }

            &--main {
                width: 100%;
                font-size: 14px;
                >span {
                    margin-right: 11px;

                    &.warning-yellow {
                        color: #edc67e;
                    }
                    &.warning-red {
                        color: #e3858d;
                    }
                }
            }

            &--close-button {
                padding: 0px;
                background: none;
                font-size: 20px;
                color: #759191;

                &:hover {
                    color: white;
                }
            }
        }

        &--footer {
            background: #012b2b;
            padding: 7px 11px;
            border-top: 1px solid #267f80;

            &--button {
                font-size: 14px;
                font-weight: bold;
                width: 70px;
                height: 23px;
                color: #ddd;

                &:hover {
                    color: white;
                }

                &.add {
                    float: left;
                    background: #488686;

                    &:hover {
                        background: #4c9a9a;
                    }
                }

                &.reset, &.submit, &.discard {
                    float: right;
                    margin-left: 7px;
                }

                &.reset {
                    background: lightgray;
                    color: #488686;

                    &:hover {
                        color: #4c9a9a;
                    }
                }

                &.submit {
                    background: #5c8cb5;
                }

                &.discard {
                    background: #c76169;
                }
            }

            &--message {
                font-size: 16px;
                font-weight: bold;
                color: #e07981;
                float: right;
                margin-right: 5px;
            }
        }

        &--amount {
            width: 200px;
        }

        &--state {
            font-size: 13px;
            min-width: 80px;
            text-align: center;
            font-weight: bold;

            &.WAITING {
                color: lightblue;
            }

            &.TRANSFERING {
                color: gold;
            }

            &.TRANSFERRED {
                color: #4dbbbb;
            }

            &.FAILED {
                color: #ce757c;
            }
        }

        &--message {
            width: 150px;
            max-width: 150px;
            line-height: 1.3em;
            font-size: 13px;
            text-align: center;
        }

        &--remove {
            background: none;
            color: white;
            font-size: 16px;
            opacity: 0;

            &:hover {
                color: #f58d97;
            }
        }

        .token-transfer-editor--transfer-account-swap {
            position: relative;
            left: 50%;
            transform: translate(-50%, 8px);
            height: 25px;
            padding: 0px;
        }

        &--account-selection-popup {
            position: relative;
            display: flex;
            flex-direction: column;
            background: #043131;
            padding: 0px 0px;
            box-shadow: 0px 0px 3px black;
            font-weight: bold;
            overflow: hidden;
            z-index: 101 !important;

            &--trigger {
                position: relative;
                height: 25px;
                line-height: 23px;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                background: transparent;
                border: 1px solid #488686;
                border-radius: 3px;
                color: white;

                &:hover {
                    background: #488686;
                }
            }

            &--header {
                background: #0a4848;
                padding: 4px 9px;
                font-size: 14px;
                line-height: 20px;
                height: 20px;
                min-height: 20px;
            }
    
            &--main {
                position: relative;
                padding: 11px 9px;
                height: 100%;
                overflow: auto;
            }
    
            &--config-row {
                padding: 8px 15px;
                border-bottom: 1px solid #0c5656;
            }

            &--toggle-all-button {
                margin-top: 2px;
                padding: 0px 11px;
                height: 32px;
                font-size: 14px;
                line-height: 30px;
                font-weight: bold;
                border-radius: 3px;
                border: 1px solid #7eafaf;
                background: transparent;
                color: #7eafaf;

                &:hover {
                    background: #094242;
                }
            }

            &--search-string {
                position: relative;
                height: 32px;
                margin-left: 10px;
                width: 300px;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                cursor: text;
                border-radius: 3px;
                background: #022020;
                color: white;
            }

            &--empty-result {
                position: relative;
                text-align: center;
                padding: 10px;
                font-size: 16px;
                font-weight: bold;
            }

            &--portfolios {
                position: relative;
                padding: 5px 20px 10px;
                overflow: auto;
            }

            &--portfolio {
                position: relative;
    
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
    
                &--header {
                    border-bottom: 1px solid #517070;
                    padding: 5px 0px;
                    line-height: 20px;
                    font-size: 14px;
    
                    >label {
                        font-size: 16px;
                        color: white;
                    }
    
                    >button {
                        padding: 0px 7px;
                        height: 24px;
                        font-size: 14px;
                        font-weight: bold;
                        border-radius: 3px;
                        border: 1px solid #7eafaf;
                        background: transparent;
                        color: #7eafaf;
                        margin-left: 10px;
        
                        &:hover {
                            background: #094242;
                        }
                    }
                }
    
                &--main {
                    position: relative;
                    margin-top: 5px;
                }
        
                &--item {
                    position: relative;
                    display: inline-flex;
                    line-height: 24px;
                    margin-right: 21px;
                    font-size: 14px;
        
                    >span {
                        &.black-listed {
                            text-decoration: line-through;
                            text-decoration-color: #fd818a;
                            text-decoration-thickness: 2px;
                        }
                    }
        
                    .checkbox {
                        width: 20px;
                        height: 20px;
                        transform: translateY(2px);
                        margin-left: 7px;
                    }
                }
            }
        }

        &--bulk-account-type {
            position: relative;
            display: flex;

            &--type-selector, &--pair-selector {
                width: 230px;
            }

            &--pair-selector {
                margin-left: 10px;
            }
        }

        &--bulk-transfer-amount-of-each-input, &--bulk-transfer-total-amount-input {
            height: 25px;
            line-height: 25px;
            padding: 0px;
            text-indent: 7px;
            background: #488686;
            font-size: 16px;
            font-weight: bold;
            color: white;
            width: 120px;
            cursor: text;
        }
        
        &--bulk-transfer-total-amount {
            >button {
                height: 25px;
                line-height: 25px;
                vertical-align: top;
                margin-left: 10px;
                padding: 0px 10px;
                font-size: 14px;
                font-weight: bold;
                background: #7b92b5;
                color: white;

                &:hover {
                    background: #9aadcb;
                    color: #0a182e;
                }
            }

            &--max-available {
                font-size: 13px;
                margin-top: 3px;
            }
        }

        &--position-filter-selector {
            position: relative;
            max-width: 210px;
            width: 210px;
            text-align: left;

            .search-select {
                &--current-option {
                    font-size: 13px;
                    padding-right: 27px;
                }
                &--clear-button {
                    background: #001515;
                    >svg {
                        margin-top: 1px;
                    }
                }
            }
        }

        &--position-info {
            position: relative;
            font-size: 14px;
            font-weight: bold;
            max-width: 210px;

            &--row {
                display: inline-flex;
                width: 100%;

                &:not(:first-child) {
                    margin-top: 2px;
                }

                >label {
                    opacity: 0.6;
                }
                >span {
                    width: 100%;
                    text-align: right;
                }

                .positive {
                    color: #75e5e4;
                }

                .negative {
                    color: #dc7479;
                }

                .highlight {
                    color: #e2cb6a;
                }
            }

            &--empty-message {
                opacity: 0.6;
            }
        }
    }
}

.portfolio-account-selector {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #043131;
    padding: 0px 0px;
    box-shadow: 0px 0px 3px black;
    font-weight: bold;
    overflow: hidden;
    max-width: 900px !important;
    z-index: 101 !important;

    &--trigger {
        position: relative;
        height: 25px;
        line-height: 23px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        background: transparent;
        border: 1px solid #488686;
        border-radius: 3px;
        color: white;

        &:hover {
            background: #488686;
        }
    }

    &--header {
        background: #0a4848;
        padding: 4px 9px;
        font-size: 14px;
        line-height: 20px;
        height: 20px;
        min-height: 20px;
    }

    &--main {
        position: relative;
        padding: 11px 9px;
        height: 100%;
        overflow: auto;
    }

    &--config-row {
        padding: 8px 15px;
        border-bottom: 1px solid #0c5656;
    }

    &--toggle-all-button {
        margin-top: 2px;
        padding: 0px 11px;
        height: 32px;
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
        border-radius: 3px;
        border: 1px solid #7eafaf;
        background: transparent;
        color: #7eafaf;

        &:hover {
            background: #094242;
        }
    }

    &--search-string {
        position: relative;
        height: 32px;
        margin-left: 10px;
        width: 300px;
        padding: 0px 10px;
        font-size: 14px;
        font-weight: bold;
        cursor: text;
        border-radius: 3px;
        background: #022020;
        color: white;
    }

    &--empty-result {
        position: relative;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        font-weight: bold;
    }

    &--portfolios {
        position: relative;
        padding: 5px 20px 10px;
        overflow: auto;
    }

    &--portfolio {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &--header {
            border-bottom: 1px solid #517070;
            padding: 5px 0px;
            line-height: 20px;
            font-size: 14px;

            >label {
                font-size: 16px;
                color: white;
            }

            >button {
                padding: 0px 7px;
                height: 24px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 3px;
                border: 1px solid #7eafaf;
                background: transparent;
                color: #7eafaf;
                margin-left: 10px;

                &:hover {
                    background: #094242;
                }
            }
        }

        &--main {
            position: relative;
            margin-top: 5px;
        }

        &--item {
            position: relative;
            display: inline-flex;
            line-height: 24px;
            margin-right: 21px;
            font-size: 14px;

            >span {
                &.not-exist-warning {
                    text-decoration: line-through;
                    color: #fd818a;
                }
            }

            .checkbox {
                width: 20px;
                height: 20px;
                transform: translateY(2px);
                margin-left: 7px;

                >svg {
                    position: absolute;
                }
            }
        }
    }
}