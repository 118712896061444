.leg-editor {
    position: relative;
    
    &--parameter {
        position: relative;
        display: table-row;

        &--name {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            font-size: 14px;
            font-weight: bold;

            >span {
                &.has-description {
                    border-bottom: 1px dashed #90b3b3;
                }
            }
        }

        &--name-popup {
            max-width: 240px !important;
            font-size: 12px;
            background: #87a2a2ed;
            color: #072a2b;
            box-shadow: 0px 0px 2px #032525;
            border-radius: 2px;
            padding: 5px 7px;
            line-height: 17px;
        }

        &--value {
            position: relative;
            display: table-cell;
            vertical-align: middle;

            input {
                cursor: text;
                height: 25px;
                padding: 0px 6px;
                font-size: 14px;
                color: #eee;
                background: #368686;
                font-weight: bold;
                border-radius: 1px;
                width: 69px;

                &:disabled {
                    background: none !important;
                    cursor: default !important;
                    padding: 0px !important;
                }
            }

            .toggle {
                height: 25px;
                &--label {
                    line-height: 25px;
                }

                &--button {
                    height: 21px;
                    width: 21px;
                }

                &--track {
                    width: 43px;
                }
            }
        }

        &--array {
            position: relative;
            display: flex;
        }

        &--array-section {
            position: relative;
            width: 99px;

            label {
                float: left;
                font-size: 12px;
                margin-right: 5px;
                line-height: 21px;
                font-weight: 700;
            }
            input {
                float: left;
            }

            &.BUY {
                >label {
                    color:#91e8e8;
                }
            }
    
            &.SELL {
                >label {
                    color:#e27597;
                }
            }

            &.min-max {
                label {
                    line-height: 9px;
                    padding-bottom: 7px;
                    width: 100%;
                }
            }
        }

        &.SYMBOL {
            display: block;
            .leg-editor--parameter--value {
                display: block;
            }
            .search-select {
                &--current-option {
                    font-size: 13px;
                    font-weight: bold;
                    color: #ddd;
                    line-height: 21px;
                }

                &--search-input {
                    width: 100%;
                    background: #035050;
                    color: white;
                    border-bottom: 1px solid #0d6363;
                    height: 27px;
                }
    
                &--input-icon {
                    right: 0px;
                }
    
                &--clear-button {
                    right: 0px;
                    background: #368686;
                }
            }
        }

    }

    &--leg-params {
        .leg-editor {
            &--parameter--name {
                padding: 8px 0px;
                padding-right: 25px;
            }
        }
    }

    &--add-symbol-button {
        position: relative;
        background: #368686;
        color: #ddd;
        font-weight: bold;
        padding: 7px 10px;
        font-size: 12px;
        margin-top: 11px;

        &:hover {
            color: white;
        }
    }

    ::-webkit-scrollbar {
        height: 10px;
        background-color: #053333;
        border-radius: 5px;
        overflow: hidden;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #368686;
    }

    &--symbols-table-wrapper {
        position: relative;
        overflow-x: auto;
        margin-top: 11px;
        padding-bottom: 7px;
    }

    &--symbols-table {
        position: relative;
        border-collapse: collapse;
        width: 1%;
        text-align: left;

        tr {
            border-bottom: 1px solid #053838;
            &:last-child {
                border-bottom: none;
            }
        }

        th {
            font-size: 12px;
            font-weight: bold;
            padding: 6px 9px;
            position: sticky;
            left: 0;
            background: #0e5d5d;
            border-right: none;
            white-space: nowrap;
            width: 5%;
            z-index: 1;
            border-bottom: 1px solid #0d4a4a;
        }

        td {
            padding: 6px 9px;
            white-space: nowrap;
            position: relative;
            border-right: 1px solid #053838;
            vertical-align: top;

            &:last-child {
                border-right: none;
            }
        }
    }

    &--symbol-row {
        th {
            background: #053c3c;
        }
    }

    &--symbol-row-data {
        background: #053c3c;
        padding: 6px 9px;

        &.resizable {
            cursor: ew-resize;
        }

        .single-limit {
            position: relative;
            margin-top: 4px;
            border-top: 1px dashed #458686;
            padding-top: 2px;
    
            &--data-row {
                font-size: 12px;
                &--name {
                    float: left;
                    opacity: 0.7;
                }
                &--value {
                    float: right;
                }
            }
        }
    }

    &--symbol-selector {
        min-width: 150px;
        font-size: 13px;
        padding-right: 1px;
        font-weight: bold;

        &.warning {
            .search-select--current-option--name {
                color: #e27597; 
            }
        }

        .search-select {
            &--options-wrapper {
                width: auto;
                margin: 5px 0px;
            }
            &--input-icon {
                right: 13px;
            }
            &--options {
                max-height: 216px;
            }
            &--option {
                text-align: left;
            }
        }

        &--disabled-popup {
            max-width: 250px !important;
            box-shadow: 0px 0px 3px #012b2b;
            background: #87a2a2ed;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
            color: #072a2b;
            line-height: 15px;
        }
    }

    &--remove-symbol-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 29px;
        background: none;
        color: #ddd;
        font-size: 13px;
        padding: 0px 7px;
        &:hover {
            color: white;
        }

        >svg {
            transform: translateY(2px);
        }
    }

    &--ref-symbols-row {
        &--data {
            vertical-align: top;
        }

        &--add-symbol-button {
            background: #6a9493;
            color: #eee;
            font-size: 13px;
            padding: 2px 17px;
            font-weight: bold;
            border-radius: 2px;

            &.is-disabled {
                cursor: not-allowed;
            }

            &:hover {
                color: white;
            }
        }

        &--disable-edit-popup, &--disable-add-popup {
            max-width: 250px !important;
            box-shadow: 0px 0px 3px #012b2b;
            background: #87a2a2ed;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
            color: #072a2b;
            line-height: 15px;
        }

        &--remove-symbol-button {
            position: relative;
            background: none;
            color: #ddd;
            font-size: 15px;
            padding: 0;

            >svg {
                transform: translateY(3px);
            }

            &:hover {
                color: white;
            }

            &:disabled {
                display: none;
            }
        }

        &--item {
            padding: 0px 0px 8px;
            &:not(:first-child) {
                margin-top: 3px;
            }
            &:not(:last-child) {
                border-bottom: 1px dashed #053838;
            }
        }

        &--selector {
            display: flex;

            .search-select {
                &--current-option {
                    font-size: 13px;
                    font-weight: bold;
                    color: #ddd;
                    border-bottom: 1px solid #a0c7c7;
                    margin-right: 10px;
                    line-height: 21px;
                }

                &--input-icon {
                    right: 0px;
                }
            }
        }

        &--parameter-item {
            position: relative;
            margin: 3px 0px 2px;

            &--name {
                font-size: 12px;
                color: #d5eced;
            }
            .profile-param {
                position: relative;
                margin-top: 3px;

                &--number-input {
                    height: 21px;
                    padding: 0 7px;
                    font-size: 13px;
                    font-weight: bold;
                    background: #368686;
                    color: #eee;
                    border-radius: 1px;
                    width: 63px;
                    cursor: text;
                }

                &--array {
                    display: flex;

                    &--section {
                        display: flex;
                        line-height: 21px;
                        width: 99px;

                        >span {
                            margin-right: 5px;
                            font-size: 13px;
                            font-weight: bold;
                        }

                        &.first-section {
                            >span {
                                color: #8de3e3;
                            }
                        }

                        &.second-section {
                            >span {
                                color: #e27597;
                            }
                        }
                    }
                }

                .toggle {
                    height: 21px;
                    &--track {
                        width: 39px;
                    }
                    &--button {
                        width: 17px;
                        height: 17px;
                    }
                    &--label {
                        line-height: 21px;
                        margin-left: 7px;
                    }
                }
            }
        }
    }

    &--symbol-param-row {
        &--disabled-popup {
            max-width: 200px !important;
            box-shadow: 0px 0px 3px #012b2b;
            background: #87a2a2ed;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
            color: #072a2b;
        }

        .leg-editor--parameter {
            &--value {
                input {
                    height: 21px;
                    padding: 0 7px;
                    font-size: 13px;
                    font-weight: bold;
                    background: #368686;
                    color: #eee;
                    border-radius: 1px;
                    width: 63px;
                }
                .toggle {
                    height: 21px;

                    &--track {
                        width: 39px;
                    }

                    &--button {
                        width: 17px;
                        height: 17px;
                    }

                    &--label {
                        line-height: 21px;
                    }
                }
            }

            &--search-select {
                font-size: 13px;
                padding-right: 29px;
                font-weight: bold;

                &:hover {
                    .search-select--input-icon {
                        opacity: 1.0 !important;
                    }
                }
    
                .search-select {

                    &--input-icon {
                        opacity: 0.7;
                    }

                    &--options-wrapper {
                        background: #196969;
                    }

                    &--option {
                        line-height: 25px;
                        padding: 0px 11px;

                        &:hover {
                            background: #288282;
                        }

                        &.focused-option {
                            background: #278e8e;
                        }
                    }
                }
            }
        }
    }

    &--symbol-param-name {
        >span.has-description {
            border-bottom: 1px dashed #90b3b3;
        }
    }

    &--symbol-param-name-popup {
        max-width: 240px !important;
        font-size: 12px;
        background: #87a2a2ed;
        color: #072a2b;
        box-shadow: 0px 0px 2px #032525;
        border-radius: 2px;
        padding: 5px 7px;
        line-height: 17px;
    }

    &--strategy-shared-params {
        position: relative;
        margin-top: 5px;
        
        .leg-editor--parameter {
            &--name {
                font-size: 13px;
                color: #eee;
                padding: 8px 0px;
                padding-right: 17px;
                width: 100%;
            }
        }
    }

    &--strategy-type-row, &--symbol-param-row.is-strategy-param {
        th {
            background: #0f5454;
            border-bottom: 1px solid #0a4a4a;
            color: #d2ecec;
        }

        .leg-editor--parameter {
            &--value {
                input {
                    background: #6b9494;
                }
            }
        }
        .profile-param {
            input {
                background: #6b9494;
            }
        }
    }

    &--strategy-type-row {
        th {
            vertical-align: top;
            padding-top: 8px;
        }

        &--changing-strategy-message {
            font-size: 13px;
            font-weight: bold;
        }
    }

    &--strategy-type-selector {
        color: #a0c7c7;
        font-weight: bold;
        font-size: 14px;
        width: 110px;
        border-bottom: 1px solid;
        .search-select {

            &--options-wrapper {
                background: #196969;
            }

            &--current-option {
                text-indent: 5px;
            }

            &--input-icon {
                right: 5px;
            }

            &--option {
                line-height: 25px;
                padding: 0px 11px;

                &:hover {
                    background: #288282;
                }

                &.focused-option {
                    background: #278e8e;
                }
            }
        }
    }

    &--strategy-type-name {
        background: #267f80;
        padding: 0px 7px;
        border-radius: 1px;
        box-shadow: 0px 0px 1px #012524;
        color: white;
        font-size: 14px;
        font-weight: bold;

        &.DIME {
            background: #5d8cb5;
            color: #214869;
        }

        &.RDIME {
            background: #a384e4;
            color: #432979;
        }

        &.MULTILEVEL {
            background: #bfb461;
            color: #635a13;
        }

        &.HIT {
            background: #c57593;
            color: #502334;
        }

        &.COG {
            background: #76aa7f;
            color: #1e3322;
        }

        &.DSWAP {
            background: #96e5d3;
            color: #163c34;
        }

        &.MAKE {
            background: #b58f5d;
            color: #503612;
        }

        &--disabled-popup {
            max-width: 208px !important;
            box-shadow: 0px 0px 3px #012b2b;
            background: #87a2a2ed;
            color: #072a2b;
            border-radius: 2px;
            font-size: 13px;
            padding: 5px 9px;
        }

    }
}

.leg-popup {
    max-width: 70%;
    max-height: 70%;
    width: auto;
    background: #052525f2;
    box-shadow: 0px 0px 7px #011010;
    padding: 11px 11px 19px;
    overflow: auto;
    border-radius: 3px;

    &.bottom {
        margin-bottom: 40px;
    }

    &.left {
        margin-right: 40px;
    }

    &--trigger {
        cursor: pointer;

        &:hover {
            .leg-popup--trigger--title {
                color: white;
            }
        }

        &--title {
            border-bottom: 1px dotted;
        }
    }

    &--strategy-type {
        background: #267f80;
        padding: 0px 4px;
        border-radius: 1px;
        box-shadow: 0px 0px 1px #012524;
        color: white;
        margin-left: 5px;

        &.DIME {
            background: #5d8cb5;
            color: #214869;
        }

        &.RDIME {
            background: #a384e4;
            color: #432979;
        }

        &.MULTILEVEL {
            background: #bfb461;
            color: #635a13;
        }

        &.HIT {
            background: #c57593;
            color: #502334;
        }

        &.COG {
            background: #76aa7f;
            color: #1e3322;
        }

        &.DSWAP {
            background: #96e5d3;
            color: #163c34;
        }

        &.MAKE {
            background: #b58f5d;
            color: #503612;
        }
    }

    &--title {
        font-size: 16px;
        font-weight: bold;
        color: #82b7b9;
    }

    &--editor {
        position: relative;
        margin-top: 11px;
    }

    .leg-editor {
        &--symbols-table {
            width: 1px;

            tr {
                border-bottom: 1px solid #031010;
            }

            td {
                border-right: 1px solid #031010;

                &:last-child {
                    border-right: none;
                }
            }
        }

        &--symbol-row {
            th, td {
                background: #0a5050;
            }

            td {
                border-right: 1px solid #022120;
                &:last-child {
                    border-right: none;
                }
            }
        }

        &--leg-params {
            .leg-editor--parameter {
                &--name {
                    font-size: 13px;
                }
            }
        }
    }

    &--message {
        font-size: 13px;
        max-width: 80%;
        text-align: center;
        margin: 17px auto 0px;
        background: #125858;
        padding: 5px 15px;
        word-break: break-word;
        width: auto;
        display: inline-block;
    }

    &--save-button {
        position: relative;
        margin-top: 21px;
        font-size: 12px;
        padding: 6px 0px;
        border-radius: 1px;
        width: 100px;
    }
}

.profile-param {

    &--number-input {
        &:disabled {
            cursor: not-allowed !important;
        }
    }
    &--multiple-length-array {
        &--items {
            padding-bottom: 7px;
        }

        &--item {
            position: relative;
            display: flex;

            &:not(:first-child) {
                padding-top: 4px;
            }
        
            &:not(:last-child) {
                padding-bottom: 4px;
                border-bottom: 1px dashed #053838;
            }
            
            &--remove-button {
                position: relative;
                width: 21px;
                height: 21px;
                padding: 0px;
                font-size: 13px;
                background: none;
                margin-left: 5px;
                color: #ddd;

                &:hover {
                    color: white;
                }

                >svg {
                    margin-top: 3px;
                }
            }
        }

        &--inner-item {
            display: table-row;

            >span, >input {
                display: table-cell;
                font-weight: bold;
            }

            &:not(:last-child) {
                >span, >input {
                    margin-bottom: 3px;
                }
            }

            >span {
                font-size: 12px;
                padding-right: 7px;
                color: #d2ecec;
                white-space: nowrap;
            }

            >input {
                height: 21px;
                padding: 0 7px;
                font-size: 13px;
                font-weight: bold;
                background: #368686;
                color: #eee;
                border-radius: 1px;
                width: 63px;
                cursor: text;
            }
        }

        &--add-element-button {
            position: relative;
            font-size: 12px;
            font-weight: bold;
            background: #368686;
            color: #ddd;
            border-radius: 1px;
            padding: 2px 11px;

            &:hover {
                color: white;
            }
        }
    }
}